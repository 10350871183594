// theme constant
// store.js
import { createStore, combineReducers, applyMiddleware } from 'redux';
import authReducer from '../pages/Login/reducer';
import thunkMiddleware from 'redux-thunk';
import userReducer from '../pages/Users/reducer';
import clientReducer from '../pages/Clients/reducer'
import templateReducer from '../pages/EmailTemplate/reducer'
import sendMailReducer from '../pages/SendEmail/reducer'
import emailLogReducer from '../pages/EmailLog/reducer'
import dashboardReducer from '../pages/Dashboard/reducer';
import forgotPasswordReducer from '../pages/ForgotPassword/reducer';
import schedulMailReducer from '../pages/ScheduleEmail/reducer'
import technologiesReducer from '../pages/Technology/reducer';
import activityReportReducer from '../pages/Activity/reducer';
import followUpLogReducer from '../pages/FollowUpLog/reducer';

const rootReducer = combineReducers({

  auth: authReducer,
  users: userReducer,
  clients: clientReducer,
  templates: templateReducer,
  sendmail: sendMailReducer,
  emailLog: emailLogReducer,
  followUpLog: followUpLogReducer,
  dashboard: dashboardReducer,
  forgotpassword: forgotPasswordReducer,
  schedule: schedulMailReducer,
  technologies: technologiesReducer,
  activityRep: activityReportReducer
});
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
