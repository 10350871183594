// reducer.js

import { FETCH_EMAIL_LOG_SUCCESS, FETCH_EMAIL_LOG_BY_CLIENT_SUCCESS, FETCH_EMAIL_LOG_BY_USER_SUCCESS, FETCH_VIEW_EMAIL_LOG_SUCCESS, DELETE_EMAIL_LOG_SUCCESS, EMAIL_LOG_FILTER_CHANGE, UPDATE_EMAIL_TYPE_SUCCESS } from './actionTypes';

const initialState = {
  emailLogRows: [],
  viewEmailLog: '',
  filterValues: ''
};

const emailLog = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_LOG_SUCCESS:
      return {
        ...state,
        emailLogRows: action.payload,
      };
    case FETCH_VIEW_EMAIL_LOG_SUCCESS:
      return {
        ...state,
        viewEmailLog: action.payload,
      };
    case EMAIL_LOG_FILTER_CHANGE:
      return {
        ...state,
        filterValues: action.payload,
      };
    case DELETE_EMAIL_LOG_SUCCESS:
      return {
        ...state,
        emailLogRows: state.emailLogRows.filter(emailLog => {
          // Convert sendToEmail to string for comparison
          const sendToEmail = Array.isArray(emailLog.sendToEmail) ? emailLog.sendToEmail[0] : emailLog.sendToEmail;
          return emailLog._id !== action.payload.id || sendToEmail !== action.payload.email;
        })
      };
      case UPDATE_EMAIL_TYPE_SUCCESS:
      return{
        ...state,
        emailLogRows: state.emailLogRows.map((log)=>{
          if(log._id === action.payload.id){
            return {...log, emailType: action.payload.value}
          }
          return log
        })
      };
    case FETCH_EMAIL_LOG_BY_CLIENT_SUCCESS:
      return {
        ...state,
        emailLogRows: action.payload,
      };
    case FETCH_EMAIL_LOG_BY_USER_SUCCESS:
      return {
        ...state,
        emailLogRows: action.payload,
      };
    default:
      return state;
  }
};

export default emailLog;
