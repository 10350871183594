import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from '../../assets/images/Mail_(iOS).svg.png';
import Button from '@mui/material/Button';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    Stack,
    Typography,
    TextField
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import StyledInput from '../../components/StyledInput'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { forgotPassword } from './action'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [authError, setAuthError] = useState("");
    const initialValues = {
        email: "",
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required")
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Please Enter a valid Email"
            ),
    });
    const handleSubmit = async (email) => {
        try {
            const response = await dispatch(forgotPassword(email));
            if (response && response.success) {
                navigate('/admin/login');
                toast.success('Password sent successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                console.error("Forgot password request failed:", response.error);
                toast.error(response.error.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch (error) {
            console.error("Forgot password dispatch failed:", error);
            toast.error(error.message || "An error occurred.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };


    return (
        <div className="block-container login-container">
            <div className="login-form">
                <Box component="span" sx={{ display: 'block', flexGrow: 1, textAlign: "center", }}>
                    <img src={logo} width="100" className="logo" />
                </Box>
                <h4 className="form-title">Forgot Password</h4>
                <p style={{ textAlign: 'center' }}>Enter your email address below and we'll send you
                    Password reset Link.</p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                        <Form>
                            <Box sx={{ marginBottom: '10px' }}>
                                <StyledInput
                                    name="email"
                                    label="Email Address"
                                    touched={touched}
                                    errors={errors}
                                />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Button variant="contained" type="submit" className="add-btn" fullWidth disabled={loading}>
                                    {loading ? "Loading..." : "SEND"}
                                </Button>
                            </Box>

                        </Form>
                    )}
                </Formik>
                <Link to='/admin/login'>
                    <Typography variant="subtitle1" className="forgotLink" sx={{ textDecoration: 'none', cursor: 'pointer', textAlign: 'center', mt: 2 }}>
                        Back to login
                    </Typography>
                </Link>
            </div>

        </div>
    );

};

export default ForgotPassword;
