import axiosInstance from '../axios'

import { API_BASE_URL } from '../../config/index'

export const scheduleEmailToClient = async (data) => {
  try {
    const res = await axiosInstance.post(`${API_BASE_URL}/emailSchedule/create`, data)

    return res.data;
  } catch (error) {
    console.error('Error schedule email:', error);
    return error;
  }
}
export const getScheduledMailLog = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/emailSchedule/getAll`)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching email Template:', error);
    return [];
  }
};

export const deleteScheduleMail = async (id) => {
  try {
    const res = await axiosInstance.delete(`${API_BASE_URL}/emailSchedule/delete/${id}`);

    return res.data;
  } catch (error) {
    return error;
  }
}
export const editScheduleMail = async (mailID, data) => {
  try {
    const res = await axiosInstance.put(`${API_BASE_URL}/emailSchedule/update/${mailID}`, data);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getDataByScheduleId = async (ScheduleId) => {

  try {
    const res = await axiosInstance.get(`${API_BASE_URL}/emailSchedule/getById/${ScheduleId}`);
    return res.data;
  } catch (error) {
    console.error("Error in getDataByUsedId:", error);

  }
}

export const updateScheduleMail = async (mailId, data) => {
  try {
    const res = await axiosInstance.put(`${API_BASE_URL}/emailSchedule/update/${mailId}`, data);
    return res.data;
  } catch (error) {
    return error;
  }
};