import React from "react";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import './StyledPhoneInput.css';
import { formatPhoneNumber } from '../utils/phoneUtils';
import { ErrorMessage } from "formik";

const StyledPhoneInput = ({ value, name, label, defaultCountry, maxLength, onChange, touched, errors, helperText, inputProps, autoComplete }) => {
  return (
    <div className={`Input-field custom-styled-input ${errors[name] ? 'error-input' : ''}`}>
      <MuiTelInput
        sx={{ width: "100%" }}
        value={value}
        name={name}
        label={label}
        defaultCountry={defaultCountry}
        maxLength={maxLength}
        onChange={onChange}
        // error={!!errors[name]}
        autoComplete={autoComplete}
      />
      <ErrorMessage name={name} component="div" className="error-message" />
    </div>
  );
};

export default StyledPhoneInput;
