// SearchComponent.js
import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

const SearchComponent = ({ onSearch, name }) => {
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (event) => {
    const query = event.target.value
    setSearchValue(query);
    onSearch(query);
  };

  return (
    <TextField
      id="outlined-basic"
      label={`Search ${name}...`}
      variant="outlined"
      size="small"
      autoComplete='off'
      type="text"
      name="search-field"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        // shrink: true,
      }}
      value={searchValue}
      onChange={handleSearchChange}
    // className="custom-styled-input"
    />




  );
};

export default SearchComponent;
