import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// utils
//
import { useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import { toggleSidebar } from '../../../pages/Dashboard/action';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 44;

const HEADER_DESKTOP = 64;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: alpha('#F9FAFB', 0.8),
  backdropFilter: `blur(${6}px)`,
  WebkitBackdropFilter: `blur(${6}px)`,
  width: '40px',
  height: '40px',
  right: 'unset',
  left: '12px',
  top: '16px',
  borderRadius: '50%'
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: '0 !important',
  minHeight: 'unset !important',
  height: '100%'
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
export default function Header({ onOpenNav }) {
  const dispatch = useDispatch()
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={() => dispatch(toggleSidebar())}
          sx={{
            '& .css-i4bv87-MuiSvgIcon-root': {
              color: '#007AFE'
            },
            mr: 0,
            color: '#007AFE',
            display: { lg: 'none' },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />

      </StyledToolbar>
    </StyledRoot>
  );
}
