import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { Typography, Grid, Button, IconButton } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { getEmailLogDetails, getReports } from '../EmailLog/action'
import { SpinnerCircular } from "spinners-react";

const ViewEmailLog = ({ id, email }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id, isCompany } = useParams();
  const [filteredData, setFilteredData] = useState();
  const emailReport = useSelector((state) => state.emailLog.viewEmailLog)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const getEmailReports = async () => {
      setLoader(true)
      try {
        const response = await dispatch(getEmailLogDetails(id));
        setLoader(false)
      } catch (error) {
        console.error("Error fetching email reports:", error);
        setLoader(false)
      }
    };
    if (id) {
      getEmailReports();
    }

  }, [dispatch]);

  useEffect(() => {
    const filterData = async () => {
      if (emailReport) {
        const data = await emailReport.filter((item) => item.sendToEmail[0] === email)
        setFilteredData(data[0])
      }
    }
    filterData();
  }, [emailReport])

  function formatDateString(dateToModify) {
    const inputDate = new Date(dateToModify);

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const formattedDate = inputDate.toLocaleString('en-US', options);
    return formattedDate;
  }

  const regex = /(<([^>]+)>)/ig;
  const secondregex = /((&nbsp;))*/gmi;

  const tempresult = emailReport?.textbody?.replace(regex, "");
  const cleanTextBody = tempresult?.replace(secondregex, "");
  const onBack = () => {
    navigate(-1);
  }

  return (
    <>
      <div className="email-log-container">
        {loader ?
          (
            <div className="spinner-container">
              <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
            </div>
          ) :
          <div className="grid-container">
            <Grid container gap={2} alignItems={"center"}>
              <Grid xs={12} className="custom-grid add-user-form">
                <h3 style={{ textAlign: 'left' }}>Email Body</h3>
                <Typography variant="subtitle1" align="left">
                  <div className="body-content" dangerouslySetInnerHTML={{ __html: filteredData?.textbody }} />
                </Typography>
              </Grid>
            </Grid>
          </div>}
      </div>
    </>
  );
};

export default ViewEmailLog;
