import axiosInstance from '../axios'

import { API_BASE_URL } from '../../config/index'


export const getEmailLogReport = async (data) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/emailSend/sendEmailReport`, data)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching email report:', error);
    throw error
    // return [];
  }
};

export const getViewEmailLog = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/emailSend/getEmailLog/${id}`)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching email report:', error);
    throw error
    // return [];
  }
};
export const deleteEmailLog = async (clientDetails) => {
  try {
    const res = await axiosInstance.delete(`${API_BASE_URL}/emailSend/delete/${clientDetails.id}`, { data: { email: clientDetails.email } });

    return res.data;
  } catch (error) {
    console.error('Error deleting Email Log:', error);
    return null;
  }
}

export const getEmailReportsByClientId = async (clientId, data) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/emailSend/sendEmailReportPerClient/${clientId}`, data)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching email report:', error);
    throw error
    // return [];
  }
};

export const getEmailReportsByUserId = async (userId) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/emailSend/sendEmailReport/${userId}`)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching email report:', error);
    return [];
  }
};

export const getFollowUpLogReport = async (data) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/emailSend/emailReportByEmailType`, data)
    return response?.data?.data;
  } catch (error) {
    console.error('Error fetching email report:', error);
    throw error
    // return [];
  }
};

export const updateEmailType = async (userId, data) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/emailSend/updateEmailType/${userId}`, data)
    return response?.data?.data;
  } catch (error) {
    console.error('Error fetching email report:', error);
    throw error
  }
};