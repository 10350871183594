import{FORGOT_PASSWORD_SUCCESS} from './action'
const initialState = {
    EmailStatus : [],
};
const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
      case FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          EmailStatus: action.payload,
        };
     
      default:
        return state;
    }
  };
  
  export default forgotPasswordReducer;
  