import { FETCH_EMAIL_TEMPLATE_SUCCESS, DELETE_EMAIL_TEMPLATE_SUCCESS, UPDATE_EMAIL_TEMPLATE_SUCCESS, CREATE_EMAIL_TEMPLATE_SUCCESS, GET_TEMPLATE_DATA_SUCCESS } from './actionTypes';
import { getAllTemplate, deleteTemplate, createNewTemplate, editTemplate, getDataByTemplateId } from '../../api/emailTemplateApi';



export const fetchTemplatesSuccess = (templates) => ({
    type: FETCH_EMAIL_TEMPLATE_SUCCESS,
    payload: templates,
});

export const deleteTemplatesSuccess = (templateId) => ({
    type: DELETE_EMAIL_TEMPLATE_SUCCESS,
    payload: templateId,
});

export const createTemplatesSuccess = (data) => ({
    type: CREATE_EMAIL_TEMPLATE_SUCCESS,
    payload: data,
})

export const updatedTemplatesSuccess = (data) => ({

    type: UPDATE_EMAIL_TEMPLATE_SUCCESS,
    payload: data,
})

export const getTemplateDataSuccess = (user) => ({
    type: GET_TEMPLATE_DATA_SUCCESS,
    payload: user,
});

export const fetchTemplates = () => {
    return async (dispatch) => {
        const templates = await getAllTemplate();
        dispatch(fetchTemplatesSuccess(templates));
    };
};

export const deleteEmailTemplate = (id) => {
    return async (dispatch) => {
        await deleteTemplate(id);
        dispatch(deleteTemplatesSuccess(id));
    };
};

export const addEmailTemplate = (data) => {
    return async (dispatch) => {
        await createNewTemplate(data);
        dispatch(createTemplatesSuccess(data));
    };
};


export const editEmailTemplate = (id, data) => {
    return async () => {
        try {
            const updatedTemplate = await editTemplate(id, data);
            return updatedTemplate;
        } catch (error) {
            console.error("Error updating template:", error);
            return error.response.data;
        }
    };
};

export const getTemplateById = (templateId) => {
    return async (dispatch) => {
        const template = await getDataByTemplateId(templateId);
        dispatch(getTemplateDataSuccess(template.data));
    };
};
