
import { Box, Button, Dialog, DialogContent, DialogTitle, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';

const PopUp = (props) => {
    const { children, onClose, open, title, maxWidth, ...rest } = props
    const closeModel = () => {
        onClose()
    }
    return (

        <Dialog
            open={open}
            fullWidth
            maxWidth={maxWidth || "md"}
            {...rest}

        >
            <DialogTitle sx={{ padding: '12px 24px', paddingBottom: '6px' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h5" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Button onClick={closeModel} sx={{ paddingRight: '0' }} color='inherit'>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers >
                <Box className="popup-content">
                    {children}
                </Box>
            </DialogContent>
        </Dialog>

    )
}

export default PopUp