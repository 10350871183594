import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { deleteUser } from "../../api/userApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, deleteUserSuccess } from "./action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserTable from "../../components/UserTable";
import DialogBox from "../../components/DialogBox";
import { SpinnerCircular } from "spinners-react";
import SearchComponent from "../../components/SearchComponent";
import { getCurrentUser } from "./action";

const Users = () => {
  const roles = [
    { value: 1, name: "Admin" },
    { value: 2, name: "HR" },
    { value: 3, name: "User" },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.users.currentUser);
  const users = useSelector((state) => state.users.userRows);
  const [userData, setUserData] = useState();

  const getUserData = async () => {
    setLoading(true);
    await dispatch(getCurrentUser());
    setLoading(false);
  };

  useEffect(() => {
    if (!currentUser || !currentUser.firstName) {
      getUserData();
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (users) {
      const userRows = users.map((user) => {
        const role = roles.find((role) => role.value === user.position);
        return {
          ...user,
          firstName: capitalizeFirstLetter(user.firstName),
          lastName: capitalizeFirstLetter(user.lastName),
          position: role ? role.name : "",
        };
      });
      setUserData(userRows);
    }
  }, [users]);

  useEffect(() => {
    setLoading(true);
    const fetchUsersData = async () => {
      try {
        await dispatch(fetchUsers());
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching users:", error);
      }
    };

    fetchUsersData();
  }, [dispatch]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleAddUserClick = () => {
    navigate("/admin/users/add-user");
  };

  const handleDeleteUserClick = async (id) => {
    setSelectedUserId(id);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      await deleteUser(selectedUserId);
      dispatch(deleteUserSuccess(selectedUserId));
      setOpenDialog(false);
      setLoading(false);
      toast.success("User deleted successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleEditUserClick = (id) => {
    navigate(`/admin/users/add-user?edit=${id}`);
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const filteredUsers = userData?.filter(
    (user) => {
      const rowText = Object.values(user).join(" ").toLowerCase();
      return rowText.includes(searchQuery.toLowerCase());
    }
    // `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      headerClassName: "header-cell",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      headerClassName: "header-cell",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      headerClassName: "header-cell",
      flex: 1,
      minWidth: 160,
    },
    {
      field: "contactNo",
      headerName: "Contact No.",
      headerClassName: "header-cell",
      flex: 1,
      minWidth: 140,
      sortable: false,
    },
    {
      field: "position",
      headerName: "Role",
      headerClassName: "header-cell",
      sortable: false,
      minWidth: 100,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "header-cell",
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            aria-label="edit"
            onClick={() => handleEditUserClick(params.row._id)}
          >
            <EditIcon />
          </IconButton>

          {currentUser && currentUser._id !== params.row._id && (
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={() => handleDeleteUserClick(params.row._id)}
            >
              <DeleteOutlineIcon sx={{ color: "red" }} />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="block-container">
        <h2 className="block-heading">Users</h2>
        <div className="sub-header">
          <SearchComponent onSearch={handleSearch} name="User" />
          <Button
            variant="contained"
            className="add-btn"
            onClick={handleAddUserClick}
          >
            <AddCircleIcon sx={{ color: "white" }} />
            Add User
          </Button>
        </div>
        {loading ? (
          <div className="spinner-container">
            <SpinnerCircular
              color="#007BFF"
              size={80}
              thickness={100}
              speed={100}
              secondaryColor="rgba(0, 0, 0, 0.25)"
            />
          </div>
        ) : (
          <UserTable rows={filteredUsers} columns={columns} columnName="User" />
        )}
        <DialogBox
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onConfirm={handleConfirmDelete}
          message="Are you sure you want to delete this user?"
          title="Deletion"
        />
      </div>
    </>
  );
};

export default Users;
