import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import StyledInput from "../../components/StyledInput";
import SelectComponent from "../../components/SelectComponent";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createNewUser } from '../../api/userApi';
import { createUsersSuccess, updateUsers, updateUsersSuccess } from "./action";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ButtonGroup from "../../components/ButtonGroup";
import { getUserById } from './action';
import StyledPhoneInput from '../../components/StyledPhoneInput'
import { formatPhoneNumber } from '../../utils/phoneUtils';
import { SpinnerCircular } from 'spinners-react';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'


const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .strict(true)
    .trim('No leading or trailing spaces allowed.')
    .matches(/^[a-zA-Z0-9@#$%^&*:,.]+(?:\s+[a-zA-Z0-9@#$%^&*:,.]+)*$/, 'Invalid characters are not allowed.')
    .min(2, "Too Short!")
    .max(30, "Too Long!"),
  lastName: Yup.string()
    .required("Last Name is required")
    .strict(true)
    .trim('No leading or trailing spaces allowed.')
    .matches(/^[a-zA-Z0-9@#$%^&*:,.]+(?:\s+[a-zA-Z0-9@#$%^&*:,.]+)*$/, 'Invalid characters are not allowed.')
    .min(2, "Too Short!")
    .max(30, "Too Long!"),
  email: Yup.string()
    .email("Enter a valid Email")
    .required("Email is required")
    .matches(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
      "Enter a valid Email"
    ),
  contactNo: Yup.string()
    .required("Contact No. is required")
    .test('isValidTel', 'Please enter a valid number', (value) => matchIsValidTel(value)),

  position: Yup.string().required("Role is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#~^()_+{}[\]:;<>,.?\\/-]).{8,}$/,
      "There should be at least 8 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character"
    ),

});
const validationSchemaEditing = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .strict(true)
    .trim('No leading or trailing spaces allowed.')
    .matches(/^[a-zA-Z0-9@#$%^&*:,.]+(?:\s+[a-zA-Z0-9@#$%^&*:,.]+)*$/, 'Invalid characters are not allowed.')
    .min(2, "Too Short!")
    .max(30, "Too Long!"),
  lastName: Yup.string()
    .required("Last Name is required")
    .strict(true)
    .trim('No leading or trailing spaces allowed.')
    .matches(/^[a-zA-Z0-9@#$%^&*:,.]+(?:\s+[a-zA-Z0-9@#$%^&*:,.]+)*$/, 'Invalid characters are not allowed.')
    .min(2, "Too Short!")
    .max(30, "Too Long!"),
  email: Yup.string()
    .email("Enter a valid Email")
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter a valid Email"
    ),
  contactNo: Yup.string()
    .required("Contact No. is required")
    .test('isValidTel', 'Please enter a valid number', (value) => matchIsValidTel(value)),
  position: Yup.string().required("Role is required"),
});


const AddUserForm = () => {
  const roles = [
    { value: 1, name: "Admin" },
    { value: 2, name: "HR" },
    { value: 3, name: "User" }
  ]

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const editUserId = query.get("edit");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")


  const defaultFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    position: "",
    password: "",
  };

  useEffect(() => {
    const fetchUserToEdit = async () => {
      try {
        setLoading(true)
        await dispatch(getUserById(editUserId))
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    };
    if (editUserId) {
      fetchUserToEdit();
    }
  }, [dispatch])


  const isEditMode = !!editUserId;
  const userToEdit = useSelector((state) => state.users.getUserData);
  const initialValues = isEditMode ? userToEdit || defaultFormValues : defaultFormValues;


  const handleSubmitUser = async (values) => {
    if (error) {
      return;
    }
    try {
      if (editUserId && (userToEdit && userToEdit.email === values.email)) {

        const { email, ...updatedValues } = values;
        setLoading(true)
        const updatedUser = await dispatch(updateUsers(editUserId, updatedValues));
        if (updatedUser) {
          dispatch(updateUsersSuccess(updatedUser));
          setLoading(false)
          navigate('/admin/users');
          toast.success('User Updated successfully!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        else {
          setLoading(false)
        }
      } else {

        try {

          const response = await createNewUser(values);
          if (response.success) {
            toast.success('User Created successfully!', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            dispatch(createUsersSuccess(response));
            navigate('/admin/users');
          }
          else {
            toast.error(response?.response?.data?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } catch (error) {
          setLoading(false)
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

        }
      }
    } catch (error) {
      setLoading(false)
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.error("Error creating/updating user:", error);
    }


  };


  return (
    <>
      {loading ?
        (
          <div className="spinner-container">
            <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
          </div>
        ) : (

          <div className="add-user-form user">
            <h2 className="form-subheading">{isEditMode ? 'Edit User' : 'Add User'}</h2>
            <div className="form-wrapper">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={!isEditMode ? validationSchema : validationSchemaEditing}
                onSubmit={(e) => { handleSubmitUser(e) }}
              >
                {({ touched, errors, values, setFieldValue, isValid, dirty, handleSubmit }) => {
                  const handlePhoneChange = (value) => {
                    const result = value.replace(/[^+\d]+/g, "");
                    const formattedPhoneNumber = formatPhoneNumber(value);
                    setFieldValue("contactNo", formattedPhoneNumber);
                    if (matchIsValidTel(result) !== true) {
                      setError("Please enter a valid number");
                    } else {
                      setError("")
                    }
                  };

                  return (

                    <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(values) }}>
                      <StyledInput
                        name="firstName"
                        label="First Name"
                        touched={touched}
                        errors={errors}
                        value={values.firstName}
                      />

                      <StyledInput
                        name="lastName"
                        label="Last Name"
                        touched={touched}
                        errors={errors}
                        value={values.lastName}
                      />
                      <StyledInput
                        name="email"
                        label="Email Address"
                        touched={touched}
                        errors={errors}
                        disabled={editUserId ? true : false}
                      />

                      {!isEditMode && (
                        <StyledInput
                          name="password"
                          label="Password"
                          touched={touched}
                          errors={errors}
                          type={showPassword ? "text" : "password"}
                          togglePassword={() => setShowPassword(!showPassword)}
                        />
                      )}
                      <StyledPhoneInput
                        value={values.contactNo}
                        name="contactNo"
                        label="Contact Number"
                        defaultCountry="US"
                        maxLength={14}
                        onChange={handlePhoneChange}
                        touched={touched}
                        errors={errors}

                      />

                      <SelectComponent
                        label="Role"
                        onSelecteChange={(e) => setFieldValue("position", e.target.value)}
                        items={roles}
                        name="position"
                        value={values.position}
                        labelError={touched.position && errors.position}
                        helperText={touched.position && errors.position}
                      />
                      <ButtonGroup onCancel={() => navigate('/admin/users')} isEditing={!!editUserId}
                      />

                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        )}
    </>
  );
};
export default AddUserForm;