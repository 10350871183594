import * as React from 'react';
import Box from '@mui/joy/Box';
import Textarea from '@mui/joy/Textarea';
import { ErrorMessage } from 'formik';

export default function StyledTextArea({ name, value, label, touched, errors, labelStyle, autoComplete, className, onChange, ...rest }) {
    return (
        <Box className={`${className} Input-field custom-styled-input ${touched[name] && errors[name] ? 'error-input' : ''}`}>
            <Textarea
                size="lg"
                name={name}
                value={value}
                placeholder={label}
                variant="outlined"
                fullWidth
                {...rest}
                minRows={3}
                onChange={onChange}
            />
            <ErrorMessage name={name} component="div" className="error-message" />
        </Box>
    );
}
