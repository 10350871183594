
import React, { useCallback, useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { deleteEmailLogs, filterChange, getEmailReports, getFollowUpReports, getReports } from './action'
import SelectComponent from '../../components/SelectComponent'
import "react-datepicker/dist/react-datepicker.css";
import { SpinnerCircular } from 'spinners-react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDate } from "../../utils/AppContent";
import PopUp from "../../components/Popup";
import { TableSortLabel } from "@mui/material";
import { formattedDate } from "../Clients/utils";
import ViewEmailLog from "../EmailLog/ViewEmailLog";
import SendEmail from "../SendEmail";

function Row(props) {
    const { row, viewPopupState } = props;
    const [open, setOpen] = React.useState(false);
    const [openFollowUpEmailPopup, setOpenFollowUpEmailPopup] = useState({ show: false, id: '' });
    const [viewPopup, setViewPopup] = viewPopupState
    const navigate = useNavigate();
    const handleView = (id, email, subject) => {
        setViewPopup({
            id,
            email,
            show: true,
            subject
        })
    }
    const handleFollowUpEmail = useCallback((id) => {
        setOpenFollowUpEmailPopup({ show: true, id });
      }, [setOpenFollowUpEmailPopup]);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row?.companyNames[0]}
                </TableCell>
                <TableCell>{row?.sendToEmail[0]}</TableCell>
                <TableCell sx={{ minWidth: '194px !important' }}>{row?.date}</TableCell>
                <TableCell><span className='table-status' style={{ background: row?.color, color: row?.status.toLowerCase() === 'pending' && '#000' }}>{row?.status}</span></TableCell>
                <TableCell align="right" sx={{ minWidth: '150px !important' }}>
                <IconButton color="primary" aria-label="view" onClick={() => handleFollowUpEmail(row.clientId[0])}>
                    <ForwardToInboxIcon />
                </IconButton>
                    <IconButton color="primary" aria-label="view" onClick={() => { handleView(row._id, row.sendToEmail[0], row.subject) }}>
                        <RemoveRedEyeIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography sx={{ fontSize: '18px', fontWeight: '500' }} gutterBottom component="div">
                                Events
                            </Typography>
                            <div className="group-records-wrapper">

                                <div className="view-client-table">
                                    <div className="group-events">
                                        {
                                            row.events?.length > 0 ?
                                                <>
                                                    {
                                                        row.events?.map((event) => (
                                                            <div className="group-event">
                                                                <p className='table-status' style={{ color: event?.event.toLowerCase() === 'pending' ? '#000' : event.color, }}>{event?.event}</p>
                                                                <p>{formattedDate(event?.time)}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <p style={{ textAlign: 'center' }}>No events found</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <PopUp
                onClose={() => setOpenFollowUpEmailPopup({ show: false, id: '' })}
                open={openFollowUpEmailPopup.show}
                title='Follow Up Email'
                className="popup-wrapper follow-up"
            >
                <SendEmail followUp clientUserId={openFollowUpEmailPopup.id} closePopup={() => setOpenFollowUpEmailPopup({ show: false, id: '' })} />
            </PopUp>
        </React.Fragment>
    );
}

const FollowUpLog = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [filteredEmailData, setFilteredEmailData] = useState([]);
    const [emailType, setEmailType] = useState();
    const [valueToSort, setValueToSort] = useState();
    const [orderDirection, setOrderDirection] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [viewPopup, setViewPopup] = useState({
        id: '',
        email: '',
        show: false,
        subject: ''
    })
    const getReportRows = useSelector((state) => state.followUpLog.emailLogRows)

    let emailTypeList = [
        "InitialEmail",
        "FirstFollowUp",
        "SecondFollowUp",
        "ThirdFollowUp",
        "FourthFollowUp",
        "FifthFollowUp"
    ]

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    };

    useEffect(() => {
        const formattedData = getReportRows?.map((email) => ({
            ...email,
            date: email.date
                ? formatDate(email.date)
                : "",
            status: capitalizeFirstLetter(email.status),
        }));

        setFilteredEmailData(formattedData);
    }, [getReportRows]);

    useEffect(() => {
        if(emailType){
            const getData = async () => {
                setLoading(true)
                const dataToSend = {
                    emailType
                }
                await dispatch(getFollowUpReports(dataToSend))
                setLoading(false)
            }
            getData();
        }
    }, [emailType])

    const handleRequestSort = (e, property) => {
        const isAscending = (valueToSort === property && orderDirection === 'asc')
        setValueToSort(property)
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property)
    }

    const sortedRowInformation = (rowArray, comparator) => {
        const stabilizedRowArray = rowArray?.map((el, index) => [el, index])
        stabilizedRowArray?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedRowArray?.map((el) => el[0])
    }

    function descedingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descedingComparator(a, b, orderBy)
            : (a, b) => -descedingComparator(a, b, orderBy)
    }

    return (
        <>
            <div className="block-container email-log">
                <h2 className="block-heading">Follow Up Email Log</h2>
                <SelectComponent
                    label="Email Type"
                    onChange={(e, val) => {
                        setEmailType(e.target.value)
                    }}
                    items={emailTypeList.map((item) => ({ name: item, value: item }))}
                    name="emailType"
                    value={emailType}
                    sx={{ maxWidth: '280px', height: '44px' }}
                    className='filter-input'
                />
                {loading ?
                    (
                        <div className="spinner-container">
                            <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
                        </div>
                    ) :
                    <>
                        {
                            emailType ? 
                        <>
                        
                            {
                                filteredEmailData?.length > 0 ?
                                <Box className="email-log-table followup">
                                    <TableContainer component={Paper} sx={{ marginTop: '14px' }} className="collapse-table-container">
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell key='companyNames'>
                                                        <TableSortLabel
                                                            active={valueToSort === "companyNames"}
                                                            direction={valueToSort === 'companyNames' ? orderDirection : 'asc'}
                                                            onClick={createSortHandler('companyNames')}
                                                        >
                                                            Company Name
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell key='sendTo'>
                                                        <TableSortLabel
                                                            active={valueToSort === "sendTo"}
                                                            direction={valueToSort === 'sendTo' ? orderDirection : 'asc'}
                                                            onClick={createSortHandler('sendTo')}
                                                        >
                                                            Send To
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ minWidth: '192px' }}
                                                        key="date"
                                                    >
                                                        <TableSortLabel
                                                            active={valueToSort === "date"}
                                                            direction={valueToSort === 'date' ? orderDirection : 'asc'}
                                                            onClick={createSortHandler('date')}
                                                        >
                                                            Date
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell key='status'>
                                                        <TableSortLabel
                                                            active={valueToSort === "status"}
                                                            direction={valueToSort === 'status' ? orderDirection : 'asc'}
                                                            onClick={createSortHandler('status')}
                                                        >
                                                            Status
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell align="right">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    sortedRowInformation(filteredEmailData, getComparator(orderDirection, valueToSort))?.map((row, index) => (
                                                        <Row key={index} viewPopupState={[viewPopup, setViewPopup]} row={row} clientIdState={[selectedClientId, setSelectedClientId]} dialogState={[openDialog, setOpenDialog]} />
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box className="table-footer">
                                        <p>Number of rows</p>
                                        <b>{filteredEmailData.length}</b>
                                    </Box>
                                </Box>
                                :
                                <p className='no-data-msg' style={{ marginTop: '60px' }}>No email log found</p>
                            }
                        </>
                        : <p className='no-data-msg' style={{ marginTop: '60px' }}>Select email type to view logs</p>
                        }
                    </>
                }
                <PopUp
                    onClose={() => setViewPopup({
                        show: false,
                        id: '',
                        email: '',
                        subject: ''
                    })}
                    open={viewPopup?.show}
                    title={viewPopup?.subject}
                    className="popup-wrapper"
                >
                    <ViewEmailLog id={viewPopup.id} email={viewPopup.email} />
                </PopUp>
            </div>
        </>
    );
};

export default FollowUpLog;