import { ACTIVITY_FILTER_CHANGE } from './action';

const initialState = {
    filterValue: '',
};

const activityReport = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVITY_FILTER_CHANGE:
            return {
                ...state,
                filterValue: action.payload,
            };
        default:
            return state;
    }
};

export default activityReport;
