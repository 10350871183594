
import axiosInstance from '../axios'

import { API_BASE_URL } from '../../config/index'
import { toast } from 'react-toastify';
import isEmpty from 'lodash'

export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/user/getAll`)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
};
export const createNewUser = async (data) => {

  try {
    const res = await axiosInstance.post(`${API_BASE_URL}/user/create`, data)
    return res.data;
  } catch (error) {
   return error;
  }
}

export const deleteUser = async (id) => {
  try {
    const res = await axiosInstance.delete(`${API_BASE_URL}/user/delete/${id}`);

    return res.data;
  } catch (error) {
   return error;
  }
}
export const editUser = async (userId, data) => {
  try {
    const res = await axiosInstance.put(`${API_BASE_URL}/user/Update/${userId}`, data);
    return res.data;
  } catch (error) {
  return error;
  }
};

export const getProfile = async () => {

  try {
    const res = await axiosInstance.get(`${API_BASE_URL}/user/getProfile`);
    return res.data;
  } catch (error) {
    console.error("Error in getDataByUsedId:", error); // Log the error

  }
}
export const getDataByUserId = async (userId) => {

  try {
    const res = await axiosInstance.get(`${API_BASE_URL}/user/getById/${userId}`);
    return res.data;
  } catch (error) {
    console.error("Error in getDataByUsedId:", error);

  }
}
export const updateProfile = async (data) => {
  try {
    const res = await axiosInstance.put(`${API_BASE_URL}/user/updateProfile`, data);
    return res.data;
  } catch (error) {
    console.error("Error in updateProfile:", error); 
    return error;
  }
}

export const forgotPasswordUser = async (email) => {
  try {
    const res = await axiosInstance.post(`${API_BASE_URL}/user/forgotPassword`, email);
    return res.data;
  } catch (error) {
  
    console.error("Error in forgotPassword:", error);
      throw new Error(error.response.data.message);
   

  }
}