import {
  DataGrid,
} from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";
const UserTable = ({
  rows,
  columns,
  columnName,
  onAddUserClick,
  onEditUserClick,
  onDeleteUserClick,
  activityTable,
  className
}) => {


  return (
    <>
      <div className={`table ${className}`}>
        {rows?.length > 0 ? (
          <div style={{ height: "100%", width: "100%", maxHeight: "100%" }}>
            <DataGrid
              columnHeaderHeight={46}
              rows={rows}
              columns={columns}
              getRowId={(row) => activityTable ? `${row.email_id}${row.sendToEmail[0]}` : row._id}
              rowHeight={46}
              checkboxSelection={false}
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20, 30, 50, 100]}
            />
          </div>
        ) : (
          <p className="no-data-msg">No {columnName} found.</p>
        )}
      </div>
    </>
  );
};

export default UserTable;
