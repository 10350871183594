import axios from 'axios';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers['Authorization'] = `${token}`;
    return config;
}, function (error) {
    console.log("error ", error.status);
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status == 401) {
        localStorage.removeItem('token')
        localStorage.clear();
        window.location.href = '/admin/login';
    }
    return Promise.reject(error);
});

export default axiosInstance;