import{SEND_EMAIL_SUCCESS} from './action'
const initialState = {
    EmailStatus : [],
};
const sendMailReducer = (state = initialState, action) => {
    switch (action.type) {
      case SEND_EMAIL_SUCCESS:
        return {
          ...state,
          EmailStatus: action.payload,
        };
       
      default:
        return state;
    }
  };
  
  export default sendMailReducer;
  