// reducer.js

import { FETCH_USERS_SUCCESS, DELETE_USER_SUCCESS, UPDATE_USER_SUCCESS,GET_USER_DATA_SUCCESS,UPDATE_USER_DATA_SUCCESS,GET_USER_PROFILE_SUCCESS } from './actionTypes';
import {LOGOUT_SUCCESS} from "../Login/actionTypes";
const initialState = {
  userRows: [],
  currentUser: null,
  getUserData:null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        userRows: action.payload,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        userRows: state.userRows.filter(user => user._id !== action.payload),
      };
      case UPDATE_USER_SUCCESS:
        const updatedUser = action.payload;
        const updatedUserRows = state.userRows.map(user => {
          if (user._id === updatedUser._id) {
            return updatedUser;
          }
          return user;
        });
        return {
          ...state,
          userRows: updatedUserRows,
          currentUser: updatedUserRows,
        };
        case GET_USER_DATA_SUCCESS:
          return {
            ...state,
            getUserData: action.payload,
          };
          case GET_USER_PROFILE_SUCCESS:
            return {
              ...state,
              currentUser: action.payload,
            };
          case UPDATE_USER_DATA_SUCCESS:
            const updatedCurrentUser = action.payload;
            const updatedCurrentUserRows = state.userRows.map(user => {
              if (user._id === updatedCurrentUser._id) {
                return updatedCurrentUser;
              }
              return user;
            });
            return {
              ...state,
              userRows: updatedCurrentUserRows,
              currentUser: updatedCurrentUser,
            }; 
            case LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
      };
    default:
      return state;
  }
};

export default userReducer;
