import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deleteUser } from '../../api/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, deleteUserSuccess, fetchTechnologies, deleteTechnologySuccess } from './action';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserTable from '../../components/UserTable';
import DialogBox from '../../components/DialogBox';
import { SpinnerCircular } from 'spinners-react';
import SearchComponent from '../../components/SearchComponent';
import { getCurrentUser } from './action';
import { deleteTech } from "../../api/technologyApi";

const Technology = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTechId, setSelectedTechId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const technology = useSelector((state) => state.technologies.technologiesRows);

    const fetchTechnologiesInfo = async () => {
        setLoading(true);
        try {
            await dispatch(fetchTechnologies());
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTechnologiesInfo();
    }, [])


    const handleSearch = (query) => {
        setSearchQuery(query);
    };


    const handleAddBtnClick = () => {
        navigate("/admin/technology/add");
    };


    const handleDeleteTechnologyClick = async (id) => {
        setSelectedTechId(id);
        setOpenDialog(true);
    }


    const handleConfirmDelete = async () => {
        try {
            setLoading(true);
            setOpenDialog(false);
            const res = await deleteTech(selectedTechId);
            console.log(res)
            if (res?.success) {
                dispatch(deleteTechnologySuccess(selectedTechId));
                toast.success(res?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                toast.error(res?.response?.data?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
            setLoading(false);

        } catch (error) {
            console.error("Error deleting Technology:", error);
        }
    };


    const handleEditTechnologyClick = (id) => {
        navigate(`/admin/technology/add?edit=${id}`);
    };


    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    };


    const filteredData = technology.filter((item) => {
        const rowText = Object.values(item).join(' ').toLowerCase();
        return rowText.includes(searchQuery.toLowerCase());
    });

    const tableRows = filteredData.map((item) => {
        return {
            ...item,
            Name: capitalizeFirstLetter(item.name),
        };
    });



    const columns = [

        { field: 'Name', headerName: 'Name', headerClassName: 'header-cell', flex: 1, minWidth: 220 },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'header-cell',
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton color="primary" aria-label="edit" onClick={() => handleEditTechnologyClick(params.row._id)}>
                        <EditIcon />
                    </IconButton>

                    <IconButton color="primary" aria-label="edit" onClick={() => handleDeleteTechnologyClick(params.row._id)}>
                        <DeleteOutlineIcon sx={{ color: 'red' }} />
                    </IconButton>
                </>
            ),

        },
    ];


    return (
        <>

            <div className="block-container">
                <h2 className="block-heading">Technologies</h2>
                <div className="sub-header">
                    <SearchComponent onSearch={handleSearch} name="technology" />
                    <Button variant="contained" className="add-btn" onClick={handleAddBtnClick}><AddCircleIcon sx={{ color: 'white' }} />Add Technology</Button>
                </div>
                {loading ? (
                    <div className="spinner-container">
                        <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
                    </div>
                ) : (
                    <UserTable
                        rows={tableRows}
                        columns={columns}
                        columnName="Technologies"
                    />
                )}
                <DialogBox
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    onConfirm={handleConfirmDelete}
                    message="Are you sure you want to delete this technology?"
                    title="Deletion"
                />


            </div>

        </>
    );
};

export default Technology;