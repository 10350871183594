import { editTech, getAllTechnologies, getDataByTechnologyId } from '../../api/technologyApi';

export const FETCH_TECHNOLOGY_SUCCESS = 'FETCH_TECHNOLOGY_SUCCESS';
export const GET_TECH_DATA_SUCCESS = 'GET_TECH_DATA_SUCCESS';
export const CREATE_TECH_SUCCESS = 'CREATE_TECH_SUCCESS';
export const UPDATE_TECH_SUCCESS = 'UPDATE_TECH_SUCCESS';
export const DELETE_TECH_SUCCESS = 'DELETE_TECH_SUCCESS';

export const getTechDataSuccess = (user) => ({
    type: GET_TECH_DATA_SUCCESS,
    payload: user,
});

export const createTechnologySuccess = (data) => ({
    type: CREATE_TECH_SUCCESS,
    payload: data,
});

export const updateTechnologySuccess = (data) => ({
    type: UPDATE_TECH_SUCCESS,
    payload: data,
});

export const deleteTechnologySuccess = (userId) => ({
    type: DELETE_TECH_SUCCESS,
    payload: userId,
});

export const fetchTechnologiesSuccess = (Technologies) => ({
    type: FETCH_TECHNOLOGY_SUCCESS,
    payload: Technologies,
});

export const fetchTechnologies = () => {
    return async (dispatch) => {
        const Technologies = await getAllTechnologies();
        dispatch(fetchTechnologiesSuccess(Technologies));
    };
};

export const getTechnologyById = (id) => {
    return async (dispatch) => {
        const technology = await getDataByTechnologyId(id);
        dispatch(getTechDataSuccess(technology.data));
    };
};

export const updateTechnology = (id, data) => {
    return async () => {
        try {
            const updatedTech = await editTech(id, data);
            return updatedTech;
        } catch (error) {
            console.error("Error updating user:", error);
            return null;
        }
    };
};

export const createTechnology = (data) => {
    return (dispatch) => {
        dispatch(createTechnologySuccess(data));
    };
};