// api.js


import axiosInstance from '../axios'
import { API_BASE_URL } from '../../config/index'

export const getAllclients = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/client/getAll`)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
    return [];
  }
};

export const getViewClientData = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/client/getViewClientDetails/${id}`)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
    return [];
  }
};

export const createNewclient = async (data) => {

  try {
    const res = await axiosInstance.post(`${API_BASE_URL}/client/create`, data)
    return res.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
  }
}


export const deleteClient = async (id) => {
  try {
    const res = await axiosInstance.delete(`${API_BASE_URL}/client/delete/${id}`);

    return res.data;
  } catch (error) {
    console.error('Error deleting client:', error);
    return null;
  }
}
export const editclient = async (clientId, data) => {
  try {
    const res = await axiosInstance.put(`${API_BASE_URL}/client/Update/${clientId}`, data);
    return res.data;
  } catch (error) {
    console.error('Error updating client:', error);
    return null;
  }
};


export const getDataByClientId = async (clientId) => {
  try {
    const res = await axiosInstance.get(`${API_BASE_URL}/client/getClient/${clientId}`);
    return res.data;
  } catch (error) {
    console.error("Error in getDataByClientId:", error); // Log the error
    throw error; // Rethrow the error to propagate it to the caller
  }
}