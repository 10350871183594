import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DialogBox = ({ open, onClose, onConfirm, message, title }) => {
  const buttonStyle = {
    backgroundColor: 'red',
    color: 'white',
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm {title}</DialogTitle> {/* Use the title prop here */}
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={buttonStyle} variant="contained">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
