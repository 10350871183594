import {
  BarChart,
  Business,
  Dashboard,
  Email,
  History,
  People,
  Person,
  Schedule,
  Send,
} from "@mui/icons-material";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemText,
  styled,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/HBWebsol_150X150.png";
import useResponsive from "../../../hooks/useResponsive";
import { toggleSidebar } from "../../../pages/Dashboard/action";
import { logout } from "../../../pages/Login/action";
import DialogBox from "../../DialogBox";
import { NavItem } from "./NavItem";
import { StyledNavItem, StyledNavItemIcon } from "./styles";

const openedMixin = (theme) => ({
  width: 200,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 8px)`,
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 200,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const { isNavExpanded } = useSelector((state) => state.dashboard);
  const isDesktop = useResponsive("up", "lg");
  const dispatch = useDispatch();
  const handleNavButtonClick = () => {
    dispatch(toggleSidebar());
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setOpenDialog(true);
  };
  const handleConfirmLogOut = async () => {
    await dispatch(logout());
    if (isNavExpanded) {
      dispatch(toggleSidebar());
    }
    navigate("/admin/login");
    setOpenDialog(false);
  };
  const navConfig = [
    {
      title: "Dashboard",
      path: "/admin/dashboard",
      icon: <Dashboard fontSize="small" className="custom-icon" />,
    },
    {
      title: "Users",
      path: "/admin/users",
      icon: <People fontSize="small" className="custom-icon" />,
    },
    {
      title: "Clients",
      path: "/admin/clients",
      icon: <Business fontSize="small" className="custom-icon" />,
    },
    {
      title: "Email Template",
      path: "/admin/email-template",
      icon: <Email fontSize="small" className="custom-icon" />,
    },
    {
      title: "Send Email",
      path: "/admin/send-mail",
      icon: <Send fontSize="small" className="custom-icon" />,
    },
    {
      title: "Technologies",
      path: "/admin/technology",
      icon: <BuildCircleIcon fontSize="small" className="custom-icon" />,
    },
    {
      title: "Email Log",
      icon: <History fontSize="small" className="custom-icon" />,
      path: "/admin/email-log",
    },
    {
      title: "Followup Log",
      icon: <ForwardToInboxIcon fontSize="small" className="custom-icon" />,
      path: "/admin/followup-log",
    },
    {
      title: "Schedule Email",
      path: "/admin/schedule-mail",
      icon: <Schedule fontSize="small" className="custom-icon" />,
    },

    // {
    //   title: "Activity Report",
    //   path: "/admin/activity-report",
    //   icon: <BarChart fontSize="small" className="custom-icon" />,
    // },
    {
      title: "Profile",
      path: "/admin/profile",
      icon: <Person fontSize="small" className="custom-icon" />,
    },
  ];
  const renderContent = () => (
    <>
      <Box
        component="span"
        className="nav-items"
        sx={{ display: "block", textAlign: "center", marginTop: "24px" }}
      >
        <img src={logo} className="logo" />
      </Box>
      <Box sx={{ mt: 3 }}>
        <List
          disablePadding
          sx={{
            p: isNavExpanded ? (1, 1, 1, 0) : 1,
            ml: isNavExpanded ? 0.5 : 1,
          }}
        >
          {navConfig.map((item) => (
            <NavItem key={item.title} item={item} />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />

      <Box
        sx={{
          p: 1,
          // bgcolor: !!isNavExpanded && '	#ececec',
          mb: 2,
          borderRadius: 2,
          mx: 1,
          pt: 2,
          // animation: isNavExpanded && `${colorIn} 0.6s ease-in-out`,
        }}
      >
        <StyledNavItem
          sx={{
            color: "error.main",
            ...(isNavExpanded && {
              padding: 1,
              py: 0.5,
              "&.MuiButtonBase-root": { height: "unset" },
            }),
          }}
          onClick={handleLogOut}
        >
          <StyledNavItemIcon
            sx={{
              ...(isNavExpanded && {
                "&.MuiListItemIcon-root": { minWidth: "unset" },
              }),
            }}
          >
            <LogoutIcon fontSize="small" className="custom-icon" />
          </StyledNavItemIcon>
          {/* <StyledNavItemIcon sx={{ '&.MuiListItemIcon-root': { minWidth: 'unset' } }}>{icon('ic_logout2')}</StyledNavItemIcon> */}
          {!!isNavExpanded && (
            <ListItemText sx={{ ml: 1 }} disableTypography primary="Log Out" />
          )}
        </StyledNavItem>
        {/* <NavItem item={{ title: 'Log Out', icon: icon('ic_profileIcon') }} /> */}
      </Box>
    </>
  );
  return (
    <>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          // width: { lg: drawerWidth },
          position: "relative",
        }}
      >
        {isDesktop && (
          <IconButton
            sx={{
              alignSelf: "center",
              boxShadow:
                "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              "&:hover": {
                bgcolor: "#fff",
              },
              // left: isNavExpanded ? '176px' : '90px',
            }}
            onClick={handleNavButtonClick}
            className="expand-nav-btn"
          >
            {!isNavExpanded ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronLeftIcon fontSize="small" />
            )}
          </IconButton>
        )}
        {isDesktop ? (
          <Drawer
            open={isNavExpanded}
            className="sidebar-drawer"
            variant="permanent"
            PaperProps={{
              sx: {
                width: 200,
                bgcolor: "#FFFFFF",
              },
            }}
          >
            {renderContent()}
          </Drawer>
        ) : (
          <MuiDrawer
            open={isNavExpanded}
            className="sidebar-drawer"
            onClose={() => dispatch(toggleSidebar())}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: {
                width: 200,
              },
            }}
          >
            {renderContent()}
          </MuiDrawer>
        )}
      </Box>
      <DialogBox
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmLogOut}
        message="Are you sure you want to Logout?"
        title="Logout"
      />
    </>
  );
};

export default Sidebar;
