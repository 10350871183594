
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getScheduleAll } from './action';
import { GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import { deleteScheduleMail } from '../../api/scheduleMailApi'
import { deleteScheduleMailSuccess } from './action';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import DialogBox from '../../components/DialogBox';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UserTable from '../../components/UserTable';
import { SpinnerCircular } from 'spinners-react';

const ScheduleEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formattedScheduleEmailData, setFormattedScheduleEmailData] = useState([]);
  const [sortedMails, setSortedMails] = useState([]);
  const getScheduleRows = useSelector((state) => state.schedule.EmailStatus)

  const getScheduleEmailReports = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getScheduleAll());
      setLoading(false)
      if (response && response.length > 0) {

        const formattedData = response.map((ScheduleMail) => ({
          ...ScheduleMail,
          dateTime: ScheduleMail.dateTime
            ? new Date(ScheduleMail.dateTime).toLocaleString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
            : "",
          status: capitalizeFirstLetter(ScheduleMail.status),
          body:
            ScheduleMail?.body?.replace(/<[^>]*>/g, '')?.length > 50
              ? `${ScheduleMail?.body?.replace(/<[^>]*>/g, '')?.substring(0, 50)}...`
              : ScheduleMail?.body?.replace(/<[^>]*>/g, ''),
        }));

        setFormattedScheduleEmailData(formattedData);
      } else {
        setFormattedScheduleEmailData([]);
        setSortedMails([])
      }
    } catch (error) {
      console.error("Error fetching email reports:", error);
    }
  };
  useEffect(() => {

    getScheduleEmailReports();
  }, []);


  const handleEditScheduleMail = (id) => {
    navigate(`/admin/send-mail?edit=${id}`);
  };
  const handleDeleteScheduleMail = async (id) => {
    setSelectedScheduleId(id);
    setOpenDialog(true);
  }

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      await deleteScheduleMail(selectedScheduleId);
      dispatch(deleteScheduleMailSuccess(selectedScheduleId));
      setOpenDialog(false);
      setLoading(false);
      toast.success('Schedule Mail deleted successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      getScheduleEmailReports();
    } catch (error) {
      console.error("Error deleting Schedule Mail:", error);
    }
  };


  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };


  useEffect(() => {
    if (formattedScheduleEmailData.length > 0) {
      const sortedEmails = [...formattedScheduleEmailData].sort((a, b) => {
        const dateA = new Date(a.addedBy.date).getTime();
        const dateB = new Date(b.addedBy.date).getTime();
        return dateB - dateA;
      });
      setSortedMails(sortedEmails);
    }
  }, [formattedScheduleEmailData, setFormattedScheduleEmailData]);

  const columns: GridColDef[] = [
    { field: 'companyName', headerName: 'Company Name', headerClassName: "header-cell", minWidth: 140, flex: 1, sortable: false },

    { field: 'sendToEmail', headerName: 'Send To', headerClassName: "header-cell", minWidth: 140, flex: 1, sortable: false, valueGetter: (value) => {return value?.row?.emailStatus?.accepted[0]} },

    {
      field: 'dateTime',
      headerName: 'Date And Time',
      headerClassName: "header-cell",
      minWidth: 140, flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: "header-cell",
      minWidth: 100, flex: 1,
    },

    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'header-cell',
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton color="primary" aria-label="edit" onClick={() => handleEditScheduleMail(params.row._id)}>
            <EditIcon />
          </IconButton>


          <IconButton color="primary" aria-label="edit" onClick={() => handleDeleteScheduleMail(params.row._id)}>
            <DeleteOutlineIcon sx={{ color: 'red' }} />
          </IconButton>

        </>
      ),

    },
  ];
  return (
    <>
      {loading ?
        (<div className="spinner-container" >
          <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
        </div >

        ) : (
          <div className="block-container schedule-email">
            <h2 className="block-heading">Schedule Email</h2>
            <UserTable rows={sortedMails} columns={columns} columnName="Schedule Email" />
            <DialogBox
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              onConfirm={handleConfirmDelete}
              message="Are you sure you want to delete this Schedule Email?"
              title="Deletion"
            />
          </div>
        )}
    </>
  );
};

export default ScheduleEmail;