

import React, { useState, useEffect } from "react";
import { GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplates, deleteEmailTemplate } from './action';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserTable from '../../components/UserTable';
import DialogBox from '../../components/DialogBox';
import SearchComponent from '../../components/SearchComponent'
import { SpinnerCircular } from 'spinners-react';


const EmailTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");


  useEffect(() => {
    const fetchEmailData = async () => {
      dispatch(fetchTemplates());
    };
    fetchEmailData();
  }, [dispatch]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleDeleteTemplate = (id) => {
    setSelectedTemplateId(id);
    setOpenDialog(true);
  }

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      await dispatch(deleteEmailTemplate(selectedTemplateId));
      setOpenDialog(false);

      toast.success('Template deleted successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };


  const handleEditTemplate = async (id) => {
    navigate(`/admin/email-template/add-email-template?edit=${id}`)

  }
  const templateRows = useSelector((state) => state.templates.templateRows);

  const filteredTemplates = templateRows.filter((template) => {
    const rowText = Object.values(template).join(' ').toLowerCase();
    return rowText.includes(searchQuery.toLowerCase());
  })

  const columns: GridColDef[] = [
    {
      field: 'name', headerName: 'Name', headerClassName: 'header-cell', flex: 0.5, minWidth: 200, renderCell: (params) => (
        <>
          <span style={{ textTransform: 'capitalize' }}>
            {params.row.name.charAt(0).toUpperCase() + params.row.name.slice(1)}
          </span>
        </>
      ),
    },
    { field: 'subject', headerName: 'Subject', headerClassName: 'header-cell', flex: 0.5, minWidth: 200 },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'header-cell',
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton color="primary" aria-label="edit" onClick={() => { handleEditTemplate(params.row._id) }}>
            <EditIcon />
          </IconButton>

          <IconButton color="primary" aria-label="delete" onClick={() => { handleDeleteTemplate(params.row._id) }} >
            <DeleteOutlineIcon sx={{ color: 'red' }} />
          </IconButton>
        </>
      ),
    },
  ];


  return (
    <>
      <div className="block-container">
        <h2 className="block-heading">Email Templates</h2>
        <div className="sub-header">
          <SearchComponent onSearch={handleSearch} name="templates" />

          <Link to='/admin/email-template/add-email-template'><Button variant="contained" className="add-btn"><AddCircleIcon sx={{ color: 'white' }} />Add Template</Button></Link>
        </div>
        {loading ? (
          <div className="spinner-container">
            <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
          </div>
        ) : (
          <UserTable
            rows={filteredTemplates}
            columns={columns}
            columnName="Email Template"
          />)}
        <DialogBox
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onConfirm={handleConfirmDelete}
          message="Are you sure you want to delete this Template?"
          title="Deletion"
        />
      </div>
    </>
  );
};

export default EmailTemplate;