// reducer.js

import { FETCH_CLIENTS_SUCCESS, DELETE_CLIENT_SUCCESS, UPDATE_CLIENT_SUCCESS, GET_CLIENT_DATA_SUCCESS, FETCH_VIEW_CLIENTS_SUCCESS } from './actionTypes';

const initialState = {
  clientRows: [],
  getClientData: null,
  getViewClientData: null
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clientRows: action.payload,
      };
    case FETCH_VIEW_CLIENTS_SUCCESS:
      return {
        ...state,
        getViewClientData: action.payload,
      };
    case GET_CLIENT_DATA_SUCCESS:
      return {
        ...state,
        getClientData: action.payload,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clientRows: state.clientRows.filter(CLIENT => CLIENT._id !== action.payload),
      };
    case UPDATE_CLIENT_SUCCESS:
      const updatedClient = action.payload;
      const updatedUserRows = state.clientRows.map(CLIENT => {
        if (CLIENT._id === updatedClient._id) {
          return updatedClient;
        }
        return CLIENT;
      });
      return {
        ...state,
        clientRows: updatedUserRows,
      };
    default:
      return state;
  }
};

export default clientReducer;
