import { useState, useEffect, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { toast } from "react-toastify";
import { API_BASE_URL, DIRECTORY_URL } from "../config";
import DOMPurify from 'dompurify';

const modules = {
    toolbar: [
        // ... (your existing toolbar configurations)
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        [{ 'color': ['#E7825E'] }, { 'background': [] }],
        ["link"],
        ["clean"],
        ["image"],
    ],
};

const TextEditor = ({ value, onChange, initialValue, name }) => {
    const [quill, setQuill] = useState(null);

    // const resizeFile = (file) => new Promise(resolve => {
    //     Resizer.imageFileResizer(file, 560, 1000, 'JPEG', 100, 0,
    //         uri => {
    //             resolve(uri);
    //         }, 'file');
    // });

    useEffect(() => {
        if (quill) {
            quill.getEditor().on('paste', handlePaste);
            const toolbar = quill.getEditor().getModule("toolbar");

            if (toolbar) {
                const imageButton = toolbar.container.querySelector(".ql-image");

                if (imageButton) {
                    imageButton.addEventListener("mousedown", handleImageButtonClick);
                }
            }
        }

        return () => {
            // Cleanup event listener on component unmount
            if (quill) {
                quill.getEditor().off('paste', handlePaste);
                const toolbar = quill.getEditor().getModule("toolbar");

                if (toolbar) {
                    const imageButton = toolbar.container.querySelector(".ql-image");

                    if (imageButton) {
                        imageButton.removeEventListener("mousedown", handleImageButtonClick);
                    }
                }
            }
        };
    }, [quill]);

    const handleImageButtonClick = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async (changeEvent) => {
            const file = changeEvent.target.files[0];
            if (file) {
                quill.focus();
                handleImageUpload(file);
            }
        };
    };

    const handlePaste = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.type.indexOf('image') !== -1) {
                // Handle image pasting
                const file = item.getAsFile();
                handleImageUpload(file);
                e.preventDefault(); // Prevent the default paste behavior
                break; // Stop processing other items
            }
        }
    };

    const handleImageUpload = async (file) => {
        var fileName = file.name;
        fileName = fileName.replace(/\s+/g, '');

        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "gif" || extFile == "png" || extFile == "webp") {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("image", file, fileName);
            try {
                const data = await fetch(`${API_BASE_URL}/image/postimage`, {
                    method: "post",
                    headers: {
                        "Authorization": `${token}`,
                        // "Content-Type": "multipart/form-data"
                    },
                    body: formData,
                });

                const uploadedImage = await data.json();
                if (uploadedImage.data) {
                    insertImage(uploadedImage)
                }

            } catch (error) {
                toast.error('Image upload failed', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } else {
            toast.error('Select a image file', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };

    const insertImage = (uploadedImage) => {
        if (quill) {
            const quillEditor = quill.getEditor();
            const range = quillEditor.getSelection();
            const imagePath = `${DIRECTORY_URL}${uploadedImage.data}`;
            const imageAttributes = {
                // src: imagePath,
                style: {
                    maxWidth: '560px',
                    width: '100%',
                },
                // user: 'user',
            };

            if (range) {
                quillEditor.insertEmbed(range.index, 'image', imagePath, imageAttributes);
            } else {
                quillEditor.insertEmbed(quillEditor.getLength(), 'image', imageAttributes);
            }
        }
    };

    const convertClassesToInlineStyles = useCallback(async (content) => {
        if (quill) {
            const quillEditor = quill.getEditor();
            const editorContent = quillEditor.root;

            const alignCenterElements = editorContent.querySelectorAll('.ql-align-center');
            const alignRightElements = editorContent.querySelectorAll('.ql-align-right');
            const imageElem = editorContent.querySelectorAll(['img']);
            const paraElem = editorContent.querySelectorAll(['p']);

            alignCenterElements.forEach(element => {
                element.style.textAlign = 'center';
            });
            alignRightElements.forEach(element => {
                element.style.textAlign = 'right';
            });
            imageElem.forEach(element => {
                element.style.maxWidth = '100%';
            });
            paraElem?.forEach(element => {
                element.style.margin = '0';
                if (!element.classList || element.classList.length === 0) {
                    element.style.textAlign = 'left';
                }
            });
            let htmlString = editorContent.innerHTML;
            onChange(htmlString.trim() === '<p></p>' || htmlString.trim() === '<p><br></p>' || htmlString.trim() === '<br>' || htmlString.trim() === '<p style="margin: 0px; text-align: left;"><br></p>' ? '' : htmlString)
        }
        onChange(content);
    }, [quill, onChange]);

    const handleChange = async (content) => {
        convertClassesToInlineStyles(content)
    };


    return (
        <>
            <ReactQuill
                modules={{
                    ...modules,
                    toolbar: {
                        container: modules.toolbar,
                    },
                }}
                formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'align', 'link', 'image', 'color', 'background', 'code-block']}
                value={value ? value : initialValue}
                theme="snow"
                onChange={handleChange}
                // onBlur={handleBlur}
                placeholder="Content goes here..."
                name={name}
                ref={(el) => setQuill(el)}
            />
        </>
    );
};

export default TextEditor;
