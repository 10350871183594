import axiosInstance from '../axios'

import {API_BASE_URL} from '../../config/index'

export const sendEmailToClient = async (data) => {
    try {
      const res = await axiosInstance.post(`${API_BASE_URL}/emailSend/sendEmail`, data)
      return res.data;
    } catch (error) {
      console.error('Error mailsent email Template:', error);
      return error;
    }
  }
 