
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import 'react-toastify/dist/ReactToastify.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { changeEmailType, deleteEmailLogs, filterChange, getEmailReports, getReports } from './action'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import SelectComponent from '../../components/SelectComponent'
import TuneIcon from '@mui/icons-material/Tune';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Formik, Form } from "formik";
import { fetchClients } from '../Clients/action'
import { Checkbox, FormControl, FormControlLabel, Grid, TableSortLabel } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { getEmailReportsOfClient } from './action'
import { SpinnerCircular } from 'spinners-react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDate } from "../../utils/AppContent";
import PopUp from "../../components/Popup";
import SendEmail from "../SendEmail";
import { toast } from "react-toastify";
import DialogBox from "../../components/DialogBox";
import { formattedDate } from "../Clients/utils";
import useResponsive from "../../hooks/useResponsive";
import DateRangeField from "../../components/DateRangeField";
import ViewEmailLog from './ViewEmailLog';
import dayjs from "dayjs";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { fetchUsers } from "../Users/action";

let emailTypeList = [
  { name: "InitialEmail", value: "InitialEmail" },
  { name: "FirstFollowUp", value: "FirstFollowUp" },
  { name: "SecondFollowUp", value: "SecondFollowUp" },
  { name: "ThirdFollowUp", value: "ThirdFollowUp" },
  { name: "FourthFollowUp", value: "FourthFollowUp" },
  { name: "FifthFollowUp", value: "FifthFollowUp" },
]

const Row = React.memo((props) => {
  const { clientIdState, dialogState, columns, confirmDelete, valueToSort, orderDirection, getComparator, filteredEmailData, sortedRowInformation } = props;
  const [openFollowUpEmailPopup, setOpenFollowUpEmailPopup] = useState({ show: false, id: '' });
  const [open, setOpen] = useState({ show: false, index: '', row: '' });
  const [viewPopup, setViewPopup] = useState({ id: '', email: '', show: false, subject: '' });
  const [selectedClientId, setSelectedClientId] = clientIdState;
  const [openDialog, setOpenDialog] = dialogState;
  const [editField, setEditField] = useState({value: '', edit: false, id: '', index: ''})
  const dispatch = useDispatch();

  const handleDeleteConfirm = useCallback(() => {
    confirmDelete(selectedClientId);
  }, [confirmDelete, selectedClientId]);

  const handleView = useCallback((id, email, subject) => {
    setViewPopup(prevState => ({ ...prevState, id, email, show: true, subject }));
  }, [setViewPopup]);

  const handleFollowUpEmail = useCallback((id) => {
    setOpenFollowUpEmailPopup({ show: true, id });
  }, [setOpenFollowUpEmailPopup]);

  const handleDeleteClientClick = useCallback((id, email) => {
    setSelectedClientId({ id, email });
    setOpenDialog(true);
  }, [setSelectedClientId, setOpenDialog]);

  const handleSaveEmailType = () => {
    const data = {
      emailType: editField.value
    }
    dispatch(changeEmailType(editField.id, data));
    setEditField({value: '', edit: false, id: '', index: ''})
  }

  return (
    <React.Fragment>
      {sortedRowInformation(filteredEmailData, getComparator(orderDirection, valueToSort))?.map((row, index) => (
        <React.Fragment key={index}>
          <TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen({ show: !open.show, index: !open.index ? index : '', row: row })}
              >
                {index === open.index && open.show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            {columns?.map((col) => (
              <React.Fragment key={col.key}>
                {col.value && (
                  <TableCell component="th" scope="row">
                    {col.key === 'status' ? (
                      <span className='table-status' style={{ background: row?.color, color: row?.status.toLowerCase() === 'pending' && '#000' }}>{row?.status}</span>
                    ) : col.key === 'emailType' ? (editField.index === index && editField.edit ? <span className="edit-field"><SelectComponent onSelecteChange={(e) => setEditField({...editField, value: e.target.value})} name="emailType" value={editField.value} items={emailTypeList}/> <IconButton onClick={handleSaveEmailType} size="small"><DoneRoundedIcon size="small"/></IconButton></span> : <span className="edit-field">{row.emailType} <IconButton onClick={()=> setEditField({id: row._id, value: row.emailType, index, edit: true})} size="small"><EditRoundedIcon size="small"/></IconButton></span>) : (
                      col.key === 'companyNames' || col.key === 'sendTo' ? row[col.key][0] : row[col.key]
                    )}
                  </TableCell>
                )}
              </React.Fragment>
            ))}
            <TableCell align="right" sx={{ minWidth: '150px !important' }}>
              <IconButton color="primary" aria-label="view" onClick={() => handleFollowUpEmail(row.clientId[0])}>
                <ForwardToInboxIcon />
              </IconButton>
              <IconButton color="primary" aria-label="view" onClick={() => handleView(row._id, row.sendToEmail[0], row.subject)}>
                <RemoveRedEyeIcon />
              </IconButton>
              <IconButton color="primary" aria-label="view" onClick={() => handleDeleteClientClick(row._id, row.sendToEmail[0])}>
                <DeleteOutlineIcon sx={{ color: 'red' }} />
              </IconButton>
            </TableCell>
          </TableRow>
          {open.show && open.index === index && (
            <TableRow key={`collapse_${index}`}>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={open.show} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="body1" gutterBottom component="div">
                      Events
                    </Typography>
                    <div className="group-records-wrapper">
                      <div className="view-client-table">
                        <div className="group-events">
                          {open.row?.events?.length > 0 ? (
                            open.row?.events?.map((event, eventIndex) => (
                              <div className="group-event" key={eventIndex}>
                                <p className='table-status' style={{ color: event?.event.toLowerCase() === 'pending' ? '#000' : event.color }}>
                                  {event?.event}
                                </p>
                                <p>{formattedDate(event?.time)}</p>
                              </div>
                            ))
                          ) : (
                            <p style={{ textAlign: 'center' }}>No events found</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
      <DialogBox open={openDialog} onClose={() => setOpenDialog(false)} onConfirm={handleDeleteConfirm} message="Are you sure you want to delete this log?" title="Deletion" />
      <PopUp
        onClose={() => setOpenFollowUpEmailPopup({ show: false, id: '' })}
        open={openFollowUpEmailPopup.show}
        title='Follow Up Email'
        className="popup-wrapper follow-up"
      >
        <SendEmail followUp clientUserId={openFollowUpEmailPopup.id} closePopup={() => setOpenFollowUpEmailPopup({ show: false, id: '' })} />
      </PopUp>
      <PopUp
        onClose={() => setViewPopup({ show: false, id: '', email: '', subject: '' })}
        open={viewPopup?.show}
        title={viewPopup?.subject}
        className="popup-wrapper"
      >
        <ViewEmailLog id={viewPopup.id} email={viewPopup.email} />
      </PopUp>
    </React.Fragment>
  );
});


const EmailLog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [clientId, setCleintId] = useState("");
  const [filteredEmailData, setFilteredEmailData] = useState([]);
  const [formattedEmailData, setFormattedEmailData] = useState([]);
  const [reportFetched, setReportFetched] = useState(false);
  const [activeFilter, setActiveFilter] = useState("Last 7 days");
  
  const [valueToSort, setValueToSort] = useState();
  const [orderDirection, setOrderDirection] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [dateValue, setDateValue] = useState();
  const [initialState, setInitialState] = useState({
    companyNames: "",
    status: '',
    user: '',
    emailType: ''
  })
  const [columns, setColumns] = useState([
    { label: 'Company Name', value: true, key: 'companyNames', sortable: true },
    { label: 'Send To', value: true, key: 'sendToEmail', sortable: true },
    { label: 'Date', value: true, key: 'date', sortable: true },
    { label: 'Sent From', value: false, key: 'from', sortable: true },
    { label: 'Send Method', value: false, key: 'sendMethod', sortable: true },
    { label: 'Email Type', value: true, key: 'emailType', sortable: true },
    { label: 'Status', value: true, key: 'status', sortable: true },
  ])
  const [open, setOpen] = useState(false);
  const [openFilterPopup, setOpenFilterPopup] = useState({
    showDataFilter: false,
    showColumnFilter: false
  });
  const [dateRangeValue, setDateRangeValue] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [simpleDateRangeValue, setSimpleDateRangeValue] = useState({
    from: "",
    to: "",
  });
  const isMobile = useResponsive('down', 1024);
  const refOne = useRef(null);
  const filterValues = useSelector(state => state.emailLog.filterValues);

  const dateOptions = [
    { name: "All", value: 100 },
    { name: "Today", value: 0 },
    { name: "Yesterday", value: -1 },
    { name: "Last 7 days", value: -7 },
    { name: "Custom", value: 1 },
  ];

  const statusOptions = [
    { value: 'pending', name: 'Pending' },
    { value: 'processed', name: 'Processed' },
    { value: 'delivered', name: 'Delivered' },
    { value: 'open', name: 'Open' },
    { value: 'click', name: 'Click' },
    { value: 'bounce', name: 'Bounce' },
    { value: 'spam', name: 'Spam' },
    { value: 'unsubscribe', name: 'Unsubscribe' },
    { value: 'reject', name: 'Reject' },
    { value: 'resubscribe', name: 'Resubscribe' },
  ]

  const validationSchemaForLog = Yup.object().shape({
    companyNames: Yup.string(),
    status: Yup.string(),
    user: Yup.string(),
    emailType: Yup.string()
  });
  
  const fetchClientsData = async () => {
    dispatch(fetchClients());
    dispatch(fetchUsers());
  };

  useEffect(() => {
    fetchClientsData();
  }, []);

  const handleConfirmDelete = async () => {
    try {
      setLoading(true)
      dispatch(deleteEmailLogs(selectedClientId));
      setLoading(false)
      setOpenDialog(false);
      toast.success('Email Log deleted successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

    } catch (error) {
      setLoading(false)
      console.error("Error deleting Client:", error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const getClientRows = useSelector((state) => state.clients.clientRows)
  const users = useSelector((state) => state.users.userRows);
  const getReportRows = useSelector((state) => state.emailLog.emailLogRows)

  const companyNameOptions = useMemo(() => {
    return getClientRows.map(client => ({ label: client.companyName, value: client.companyName }));
  }, [getClientRows]);
  
  const userNamesOptions = useMemo(() => {
    return users?.map((user) => ({ name: user.fullName, value: user.fullName }));
  }, [users]);

  useEffect(() => {
    const formattedData = getReportRows?.map((email) => ({
      ...email,
      date: email.date
        ? formatDate(email.date)
        : "",
      status: capitalizeFirstLetter(email.status),
    }));
    setFormattedEmailData(formattedData);
  }, [getReportRows]);

  const clearfilter = async (resetForm) => {
    setFilteredEmailData([]);
    resetForm();
    setReportFetched(false);
    dispatch(filterChange(''));
    setOpenFilterPopup({
      showDataFilter: false,
      showColumnFilter: false
    })
    setInitialState({
      companyNames: "",
      status: '',
      user: '',
      emailType: ''
    })
    setFilteredEmailData(formattedEmailData);
  };

  const handleSubmit = (values) => {
    if (!values.companyNames && !values.status && !values.user && !values.emailType) {
      toast.error('Please select atleast one option from filter', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      dispatch(filterChange(values))
      setOpenFilterPopup({
        showDataFilter: false,
        showColumnFilter: false
      })
    }
  }

  const filterEmailLog = async (formattedData) => {
    let data = formattedData || formattedEmailData;
    data = await data?.filter(item => {
      for (let key in filterValues) {
        if (key === "user") {
          if (filterValues[key] !== '' && item?.addedBy?.fullName?.toString().toLowerCase() !== filterValues[key]?.toString().toLowerCase()) {
            return false;
          }
        } else if (key === "companyNames") {
          if (filterValues[key] !== '' && item[key][0]?.toString().toLowerCase() !== filterValues[key]?.toString().toLowerCase()) {
            return false;
          }
        } else {
          if (filterValues[key] !== '' && item[key]?.toString().toLowerCase() !== filterValues[key]?.toString().toLowerCase()) {
            return false;
          }
        }
      }
      return true;
    });
    setFilteredEmailData(data);
  }

  const getReport = async (values) => {
    try {
      setLoading(true);
      const client = getClientRows.find((client) => client.companyName === values.companyName);
      setCleintId(client?._id);
      const clientId = client?._id;
      const fromDate = new Date(values.from);
      const toDate = new Date(values.to);
      const formatDate = (date) => {
        if (isNaN(date.getTime())) {
          return "";
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
      };

      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);
      const dataToFilter = {
        from: formattedFromDate,
        to: formattedToDate,
      }
      let rawFilteredData = [];
      if (clientId) {
        rawFilteredData = await dispatch(getEmailReportsOfClient(clientId, dataToFilter));
      } else {
        rawFilteredData = await dispatch(getEmailReports(dataToFilter));
      }
      const filteredDataFormatted = await rawFilteredData?.map((data) => ({
        ...data,
        date: data.date
          ? new Date(data.date).toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
          : "",
        status: capitalizeFirstLetter(data.status),
      }));
      setFormattedEmailData(filteredDataFormatted);
      setReportFetched(true);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching email reports:", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (filterValues) {
      filterEmailLog(formattedEmailData)
    } else {
      setFilteredEmailData(formattedEmailData);
    }
  }, [formattedEmailData])

  useEffect(() => {
    if (filterValues) {
      setInitialState({ ...filterValues });
      filterEmailLog();
    }
  }, [filterValues])


  const handleRequestSort = (e, property) => {
    const isAscending = (valueToSort === property && orderDirection === 'asc')
    setValueToSort(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray?.map((el, index) => [el, index])
    stabilizedRowArray?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedRowArray?.map((el) => el[0])
  }

  function descedingComparator(a, b, orderBy, type) {
    const valueA = valueToSort === 'date' ? new Date(a[orderBy]) : a[orderBy];
    const valueB = valueToSort === 'date' ? new Date(b[orderBy]) : b[orderBy]
    
    if (valueB < valueA) {
      return -1;
    }
    if (valueB > valueA) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy, type) {
    return order === 'desc'
      ? (a, b) => descedingComparator(a, b, orderBy, type)
      : (a, b) => -descedingComparator(a, b, orderBy, type)
  }

  const handleDateChange = useCallback((e, type) => {
    if (type === "buttonClick") {
      setDateValue(e?.target?.value);
    } else {
      setDateValue(e?.target?.value);
    }
  }, []);
  
  const handleDateRangeChange = useCallback((e) => {
    setDateRangeValue([e.selection]);
  }, []);

  useEffect(() => {
    if (dateRangeValue) {
      if (dateValue?.value || parseInt(dateValue) === 100) {
        setSimpleDateRangeValue({
          from: dayjs(new Date('2023-12-01')).startOf('day').format("YYYY-MM-DD"),
          to: dayjs(new Date()).startOf('day').format("YYYY-MM-DD"),
          isAll: true
        });
      } else {
        if (parseInt(dateValue) === 1) {
          if (dateRangeValue[0].startDate !== dateRangeValue[0].endDate) {

            setSimpleDateRangeValue({
              from: dayjs(dateRangeValue[0].startDate).startOf("day").format("YYYY-MM-DD"),
              to: dayjs(dateRangeValue[0].endDate).startOf("day").format("YYYY-MM-DD"),
              isAll: false
            });
            setOpen(false)
          }
        }
        else {
          setSimpleDateRangeValue({
            from: dayjs(dateRangeValue[0].startDate).startOf("day").format("YYYY-MM-DD"),
            to: dayjs(dateRangeValue[0].endDate).startOf("day").format("YYYY-MM-DD"),
            isAll: false
          });
        }
      }
    }
  }, [dateRangeValue]);

  useEffect(() => {
    setSimpleDateRangeValue({
      isAll: false,
      from: dayjs(new Date()).subtract(6, 'day').startOf('day').format("YYYY-MM-DD"),
      to: dayjs(new Date()).startOf('day').format("YYYY-MM-DD"),
    });
  }, [])

  const getTime = (value) => {
    const today = dayjs();
    const time = { startDate: new Date(), endDate: new Date() }
    if (value === 0) {
      time.startDate = today.startOf("day").format("YYYY-MM-DD")
      time.endDate = today.startOf("day").format("YYYY-MM-DD")
    } else if (value === -1) {
      time.startDate = today.subtract(1, 'day').startOf('day').format("YYYY-MM-DD")
      time.endDate = today.subtract(1, 'day').startOf('day').format("YYYY-MM-DD")
    } else if (value === -7) {
      time.startDate = today.subtract(6, 'day').startOf('day').format("YYYY-MM-DD")
      time.endDate = today.startOf("day").format("YYYY-MM-DD")
    }
    return time
  }
  useEffect(() => {
    if (dateValue || dateValue === 0) {
      const value = dateValue?.value || parseInt(dateValue);
      if (value !== 1) {
        setDateRangeValue(
          dateRangeValue.map((v, index) =>
            index === 0
              ? {
                ...getTime(value),
                key: "selection",
              }
              : dateValue
          )
        );
      }
    }
  }, [dateValue]);

  useEffect(() => {
    if (simpleDateRangeValue && simpleDateRangeValue.from && simpleDateRangeValue.to) {
      setLoading(true);
      const data = {
        ...simpleDateRangeValue,
        status: ''
      }
      getReport(data);
    }
  }, [simpleDateRangeValue]);

  const onColumnChange = useCallback((e, col) => {
    let data = columns?.map((item) => {
      if (col.key === item.key) {
        return {
          ...item,
          value: e.target.checked
        };
      }
      return item;
    });
    setColumns([...data]);
  }, [columns]);

  return (
    <>
      <div className="block-container email-log">
        <h2 className="block-heading">Email Log</h2>
        {loading ?
          (
            <div className="spinner-container">
              <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
            </div>
          ) :
          <>
            <Box className="dashbaord-filter email-log">
              <Box className="date-picker-wrapper">
                {isMobile ? (
                  <FormControl sx={{ width: "46%", maxWidth: "240px" }}>
                    <SelectComponent
                      menuName={"label"}
                      menuValue={"value"}
                      labelId="date-label"
                      label="Date"
                      inputLabel="Date"
                      disableHelperText
                      items={dateOptions}
                      sx={{ width: "100%", marginTop: '0px !important' }}
                      name="date"
                      defaultValue={-7}
                      value={dateValue}
                      onChange={handleDateChange}
                    />
                  </FormControl>
                ) : (
                  <div className="dashbaord-filter-wrapper">
                    {dateOptions?.map((item) => (
                      <button
                        key={item.name}
                        value={item.value}
                        onClick={(e) => { handleDateChange(e, "buttonClick"); setActiveFilter(item.name) }}
                        className={`filter-btn ${item.name === activeFilter ? 'active' : ''}`}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                )}
                {dateValue?.value || parseInt(dateValue) === 1 ? (
                  <FormControl
                    sx={{ width: "54%", marginLeft: "12px", maxWidth: "230px" }}
                  >
                    <DateRangeField
                      state={dateRangeValue}
                      handleChange={handleDateRangeChange}
                      open={open}
                      openCalender={setOpen}
                      dateRef={refOne}
                      label={"Select Date Range"}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </FormControl>
                ) : (
                  ""
                )}
              </Box>
              <Box className="icon-btn-wrapper">
                <IconButton onClick={() => setOpenFilterPopup({ showDataFilter: true, showColumnFilter: false })}>
                  <FilterAltOutlinedIcon />
                </IconButton>
                <IconButton onClick={() => setOpenFilterPopup({ showDataFilter: false, showColumnFilter: true })}>
                  <TuneIcon />
                </IconButton>
              </Box>
            </Box>
            {
              filteredEmailData?.length > 0 ?
              <Box className="email-log-table">
                <TableContainer component={Paper} sx={{ marginTop: '14px' }} className="collapse-table-container email-log">
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {
                          columns?.map((col) => (
                            <>
                              {
                                col.value === true &&
                                <TableCell key={col.key}>
                                  <TableSortLabel
                                    active={col.sortable === true ? valueToSort === col.key : ''}
                                    direction={col.sortable === true ? valueToSort === col.key ? orderDirection : 'asc' : null}
                                    onClick={col.sortable === true && createSortHandler(col.key)}
                                  >
                                    {col.label}
                                  </TableSortLabel>
                                </TableCell>
                              }
                            </>
                          ))
                        }
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <Row sortedRowInformation={sortedRowInformation} orderDirection={orderDirection} valueToSort={valueToSort} getComparator={getComparator} filteredEmailData={filteredEmailData} columns={columns} confirmDelete={handleConfirmDelete} clientIdState={[selectedClientId, setSelectedClientId]} dialogState={[openDialog, setOpenDialog]} />
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="table-footer">
                  <p>Number of rows</p>
                  <b>{filteredEmailData.length}</b>
                </Box>
                </Box>
                :
                <p className='no-data-msg' style={{ marginTop: '60px' }}>No email log found.</p>
            }
          </>
        }

        <PopUp
          onClose={() => setOpenFilterPopup({
            showDataFilter: false,
            showColumnFilter: false
          })}
          open={openFilterPopup?.showColumnFilter}
          title='Column Filter'
          className="popup-wrapper column-filter"
        >
          <div className="column-filter-wrapper">
            {
              columns?.map((col) => (
                <FormControlLabel key={col.key} control={<Checkbox checked={col.value} onChange={(e) => onColumnChange(e, col)} />} label={col.label} />
              ))
            }
          </div>
        </PopUp>

        <PopUp
          onClose={() => setOpenFilterPopup({
            showDataFilter: false,
            showColumnFilter: false
          })}
          open={openFilterPopup?.showDataFilter}
          title='Filter'
          className="popup-wrapper filter"
        >
          <div className="popup-filter">
            <Formik
              validationSchema={validationSchemaForLog}
              initialValues={initialState}
              onSubmit={handleSubmit}
            >
              {({ touched, errors, values, setFieldValue, resetForm }) => {
                return (
                  <Form>
                    <div className="input-container">
                      <div style={{ flex: 0.5 }}>
                        {companyNameOptions && companyNameOptions.length > 0 ? (
                          <SelectComponent
                            // key={companyNameOptions}
                            // className="custom-select-client"
                            searchable
                            label="Select Company Name"
                            onChange={(e, val) => setFieldValue("companyNames", val?.value)}
                            name="companyNames"
                            value={values.companyNames}
                            labelError={errors.companyNames}
                            helperText={touched.companyNames && errors.companyNames}
                            items={[...companyNameOptions].sort((a, b) => a.label.localeCompare(b.label))}
                          />
                        ) : (
                          <p>Loading company names...</p>
                        )}
                      </div>
                      <div>
                        <SelectComponent
                          // className="custom-select-client"
                          label="Select Status"
                          onSelecteChange={(e) => setFieldValue("status", e.target.value)}
                          name="status"
                          value={values.status}
                          items={statusOptions}
                        />
                      </div>
                      <div>
                        <SelectComponent
                          // className="custom-select-client"
                          label="Select User"
                          onSelecteChange={(e) => setFieldValue("user", e.target.value)}
                          name="user"
                          value={values.user}
                          items={userNamesOptions}
                        />
                      </div>
                      <div>
                        <SelectComponent
                          // className="custom-select-client"
                          label="Select Email Type"
                          onSelecteChange={(e) => setFieldValue("emailType", e.target.value)}
                          name="emailType"
                          value={values.emailType}
                          items={emailTypeList}
                        />
                      </div>

                      <div className="get-report-btn">
                        <div>
                          <Button type="submit" variant="contained" color="primary" style={{ marginRight: '5px' }}>
                            Get Report
                          </Button>
                          {filterValues && (
                            <Button onClick={() => clearfilter(resetForm)} variant="contained" color="error" startIcon={<ClearRoundedIcon sx={{ '& svg': { color: '#fff' } }} />}>
                              Clear
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </PopUp>
      </div>
    </>
  );
};

export default EmailLog;