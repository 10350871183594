import { FETCH_USERS_SUCCESS,GET_USER_PROFILE_SUCCESS, DELETE_USER_SUCCESS, CREATE_USER_SUCCESS, UPDATE_USER_SUCCESS, GET_USER_DATA_SUCCESS, UPDATE_USER_DATA_SUCCESS } from './actionTypes';

import { getAllUsers, editUser, getProfile, updateProfile ,getDataByUserId} from '../../api/userApi';


export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  payload: users,
});

export const createUsersSuccess = (data) => ({
  type: CREATE_USER_SUCCESS,
  payload: data,
});

export const updateUsersSuccess = (data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: data,
});

export const deleteUserSuccess = (userId) => ({
  type: DELETE_USER_SUCCESS,
  payload: userId,
});



export const fetchUsers = () => {
  return async (dispatch) => {
    const users = await getAllUsers();
    dispatch(fetchUsersSuccess(users));
  };
};


export const updateUsers = (id, data) => {
  return async () => {
    try {
      const updatedUser = await editUser(id, data);
      return updatedUser;
    } catch (error) {
      console.error("Error updating user:", error);
      return null;
    }
  };
};

export const createUser = (user) => {
  return (dispatch) => {
    dispatch(createUsersSuccess(user));
  };
};

export const getUserProfileSuccess = (user) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: user,
});


export const getUserDataSuccess = (user) => ({
  type: GET_USER_DATA_SUCCESS,
  payload: user,
});
export const updatedUserSuccess = (user) => ({
  type: UPDATE_USER_DATA_SUCCESS,
  payload: user,
});


export const getCurrentUser = () => {
  return async (dispatch) => {
    const user = await getProfile();
    dispatch(getUserProfileSuccess(user?.data));
  };
}

export const UpdateCurrentUser = (id, data) => {
  return async (dispatch) => {
    try {
      const updatedUser = await updateProfile(id, data); 
      dispatch(updatedUserSuccess(updatedUser));
      return updatedUser;
    } catch (error) {
      console.error("Error updating user:", error);
      return error;
    }
  };
};


export const getUserById = (userId) => {
  return async (dispatch) => {
    const user = await getDataByUserId(userId);
    dispatch(getUserDataSuccess(user.data));
  };
};
