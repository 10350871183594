import React, { useMemo, useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import StyledInput from '../../components/StyledInput'
import { Autocomplete, Box, Chip, Grid, OutlinedInput, TextField, Typography } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { City, Country, State } from "country-state-city";
import { createNewclient } from "../../api/clientApi";
import { createClientsSuccess } from "./action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateClient, updateClientsSuccess } from './action'
import ButtonGroup from "../../components/ButtonGroup";
import StyledPhoneInput from '../../components/StyledPhoneInput'
import { formatPhoneNumber } from '../../utils/phoneUtils';
import "react-country-state-city/dist/react-country-state-city.css";
import { SpinnerCircular } from 'spinners-react';
import './Clients.css';
import { getClientById } from './action';
import CountryModifier from "./CountryModifier";
import { getFullObjects } from "./utils";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { MultipleSelectChip } from "../../components/StyledMultipleSelect";
import SelectComponent from "../../components/SelectComponent";
import { fetchTechnologies, fetchTechnologiesSuccess } from "../Technology/action";
import StyledTextArea from "../../components/TextArea";


const validationSchema = Yup.object().shape({
    // cp_firstName:  Yup.string().required("First Name is required"),
    // cp_lastName:  Yup.string().required("Last Name is required"),
    companyName: Yup.string()
        .required("Company Name is required")
        .strict(true)
        .trim('No leading or trailing spaces allowed.')
        .matches(/^[a-zA-Z0-9@#$%^&*:,.]+(?:\s+[a-zA-Z0-9@#$%^&*:,.]+)*$/, 'Invalid characters are not allowed.'),
    email: Yup.string()
        .email("Enter a valid Email")
        .required("Email is required")
        .matches(
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
            "Enter a valid Email"
        ),
    cp_email: Yup.string().email("Invalid email").matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
        "Enter a valid Email"
    ),
    websiteLink: Yup.string()
        .matches(
            /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}([/a-zA-Z0-9._-]*)*$/,
            "Invalid websiteLink"
        ),
    // contactNo: Yup.string().required("Contact No. is required"),
    // country: Yup.string().required("Country is required"),
    // city: Yup.string().required("city is required"),
});
const gridSpacing = 2;

const AddClient = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [contactError, setContactError] = useState("");
    const [whatsAppError, setwhatsAppError] = useState("");
    const [cpContactError, setCpContactError] = useState("");
    const [cpWhatsAppError, setCpwhatsAppError] = useState("");
    const [technology, setTechnology] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    const query = new URLSearchParams(location.search);
    const editClientId = query.get("edit");
    const [countryid, setCountryid] = useState("");
    const [stateid, setstateid] = useState("");
    const defaultFormValues = {
        companyName: "",
        email: "",
        websiteLink: "",
        linkedinUrl: "",
        githubUrl: "",
        contactNo: "",
        whatsAppNo: "",
        technologies: [],
        noOfEmployees: "",
        cp_firstName: "",
        cp_lastName: "",
        cp_contactNo: "",
        cp_email: "",
        cp_whatsAppNo: "",
        projectinfo: '',
        address: {
            line_1: "",
            line_2: "",
            country: null,
            city: null,
            state: null,
            zip: "",
        }
    };
    const countryData = Country.getAllCountries();
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [initialValues, setInitialValues] = useState(defaultFormValues);
    const clientToEdit = useSelector((state) => state.clients.getClientData);
    const technologyData = useSelector((state) => state.technologies.technologiesRows);

    const fetchClientToEdit = async () => {
        try {
            setLoading(true);
            await dispatch(getClientById(editClientId));
            setLoading(false);
            if (clientToEdit && clientToEdit[0]) {
                setSelectedCountry(clientToEdit[0].address.country[0]);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchTechnologiesInfo = async () => {
        try {
            await dispatch(fetchTechnologies());

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (editClientId) {
            fetchClientToEdit();
        }


    }, [editClientId]);

    useEffect(() => {
        fetchTechnologiesInfo();
    }, [])

    const isEditMode = !!editClientId;
    useEffect(() => {
        if (clientToEdit) {
            let addressValues = isEditMode && clientToEdit ? {
                ...clientToEdit,
                address: {
                    ...clientToEdit.address,
                    state: clientToEdit?.address?.state && getFullObjects(
                        {
                            state: clientToEdit?.address?.state && clientToEdit?.address?.state,
                            country: clientToEdit?.address?.country && clientToEdit?.address?.country,
                            type: "state",
                            setCityData: setCityData
                        }),
                    country: clientToEdit?.address?.country && getFullObjects(
                        {
                            country: clientToEdit?.address?.country && clientToEdit?.address?.country,
                            type: "country",
                            setStateData: setStateData,
                        }
                    ),
                    city: clientToEdit?.address?.city && getFullObjects(
                        {
                            city: clientToEdit?.address?.city && clientToEdit?.address?.city,
                            country: clientToEdit?.address?.country && clientToEdit?.address?.country,
                            type: "city"
                        }
                    ),

                },
            }
                : defaultFormValues;
            setInitialValues(addressValues);
        }
    }, [clientToEdit, isEditMode])

    useEffect(() => {
        if (countryid?.isoCode) {
            setStateData(State.getStatesOfCountry(countryid?.isoCode));
        }
    }, [countryid]);

    useEffect(() => {
        if (clientToEdit?.address?.country) {
            const countryIso = Country.getAllCountries().find(country => country?.name === clientToEdit?.address?.country)
            setCountryid(countryIso);
            const states = countryIso && State.getStatesOfCountry(countryIso.isoCode)
            states && setStateData(states)
            const stateIso = countryIso && State.getStatesOfCountry(countryIso.isoCode).find(state => state?.isoCode === clientToEdit?.address?.state)?.isoCode
            const cities = countryIso && stateIso && City.getCitiesOfState(countryIso.isoCode, stateIso)
            cities && setCityData(cities)
        }
    }, [clientToEdit]);

    useEffect(() => {
        if (stateid?.isoCode) {
            setCityData(City.getCitiesOfState(countryid?.isoCode, stateid?.isoCode));
        }
    }, [stateid]);

    const handleSubmitClient = async (values) => {
        try {
            const { address, companyName, contactNo, whatsAppNo, cp_contactNo, cp_email, cp_firstName, cp_lastName, cp_whatsAppNo, email, githubUrl, linkedinUrl, noOfEmployees, websiteLink, _id, technologies, projectinfo } = values;
            const clientData = {
                companyName,
                contactNo,
                cp_contactNo,
                cp_email,
                cp_firstName,
                cp_lastName,
                cp_whatsAppNo,
                email,
                githubUrl,
                linkedinUrl,
                noOfEmployees,
                websiteLink,
                projectinfo,
                whatsAppNo,
                _id,
                technologies,
                address: {
                    line_1: address?.line_1,
                    line_2: address?.line_2,
                    country: address?.country?.name,
                    city: address?.city?.name,
                    state: address?.state?.isoCode,
                    zip: address?.zip,
                },
            };

            if (editClientId) {
                const updatedClient = await dispatch(updateClient(editClientId, clientData));
                if (updatedClient) {
                    dispatch(updateClientsSuccess(updatedClient));
                    navigate('/admin/clients');
                    toast.success('Client Updated successfully!', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } else {
                const newClient = await createNewclient(clientData)

                if (newClient) {
                    dispatch(createClientsSuccess(newClient))

                    toast.success('Client Created successfully!', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    navigate("/admin/clients")
                }
            }
            // await dispatch(getClientById(editClientId));
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }

    };
    const handleCountryChange = (val) => {
        setCountryid(val);
    };

    const handleRegionChange = (val) => {
        setstateid(val);
    };

    useEffect(() => {
        if (technologyData) {
            setTechnologies(
                technologyData?.map((tech) => (
                    { value: tech._id, name: tech.name }
                ))
            )
        }
    }, [technologyData])

    return (

        <div className="add-user-form add-client pt-0">
            <h2 className="form-subheading">{editClientId ? "Edit Client" : "Add Client"}</h2>
            <div className="form-wrapper">
                {!initialValues || loading ? (
                    <div className="spinner-container">
                        <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
                    </div>
                )
                    : (
                        <Formik
                            // enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(e) => { handleSubmitClient(e) }}
                            autocomplete="off"
                        >
                            {({ values, touched, errors, setFieldValue, isValid, handleSubmit }) => {
                                const handlePhoneChange = (fieldName, value) => {
                                    const result = fieldName !== 'technologies' && value?.replace(/[^+\d]+/g, "");
                                    const formattedPhoneNumber = fieldName !== 'technologies' ? formatPhoneNumber(value) : value;
                                    setFieldValue(fieldName, formattedPhoneNumber);
                                    if (fieldName !== 'technologies' && matchIsValidTel(result) !== true) {
                                        if (fieldName == "contactNo") {
                                            setContactError("Please enter a valid Contact number");
                                        } else if (fieldName == "whatsAppNo") {
                                            setwhatsAppError("Please enter a valid WhatsApp number");
                                        }
                                        else if (fieldName == "cp_contactNo") {
                                            setCpContactError("Please enter a valid Contact number");
                                        }
                                        else if (fieldName == "cp_whatsAppNo") {
                                            setCpwhatsAppError("Please enter a valid WhatsApp number");
                                        }
                                    } else {
                                        if (fieldName === "contactNo") {
                                            setContactError("");
                                        } else if (fieldName === "whatsAppNo") {
                                            setwhatsAppError("");
                                        } else if (fieldName === "cp_contactNo") {
                                            setCpContactError("");
                                        } else if (fieldName === "cp_whatsAppNo") {
                                            setCpwhatsAppError("");
                                        } else if (fieldName === "technologies") {
                                            setTechnology([...technology, value]);
                                        }
                                    }
                                };
                                return (
                                    <>
                                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(values) }}>
                                            <div className="fields-wrapper">
                                                <Grid container spacing={gridSpacing} className="main-wrapper" style={{ paddingBottom: '0px' }}>
                                                    <Grid item xs={12} sm={6} className="company-container container-height">
                                                        <Typography variant="subtitle1" align="left" className="subheading">
                                                            Contact Person Details
                                                        </Typography>
                                                        <Grid sx={{ padding: '15px' }}>
                                                            <StyledInput name="cp_firstName" label="First Name" touched={touched} errors={errors} value={values?.cp_firstName} />
                                                            <StyledInput name="cp_lastName" label="Last Name" touched={touched} errors={errors} value={values?.cp_lastName} />
                                                            <StyledInput name="cp_email" label="Email Address" touched={touched} errors={errors} value={values?.cp_email} />
                                                            <Grid container spacing={gridSpacing} className="half-width-container">
                                                                <Grid item xs={6}>

                                                                    <StyledPhoneInput
                                                                        value={values?.cp_contactNo}
                                                                        name="cp_contactNo"
                                                                        label="Contact Number"
                                                                        defaultCountry="US"
                                                                        maxLength={14}
                                                                        onChange={(value) => handlePhoneChange("cp_contactNo", value)}
                                                                        touched={touched}
                                                                        errors={errors}
                                                                        helperText={touched.cp_contactNo && errors.cp_contactNo}
                                                                    />
                                                                    {cpContactError && (
                                                                        <div className="alert-message">
                                                                            {cpContactError}
                                                                        </div>
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <StyledPhoneInput
                                                                        value={values?.cp_whatsAppNo}
                                                                        name="cp_whatsAppNo"
                                                                        label="WhatsApp Number"
                                                                        defaultCountry="US"
                                                                        maxLength={14}
                                                                        onChange={(value) => handlePhoneChange("cp_whatsAppNo", value)}
                                                                        touched={touched}
                                                                        errors={errors}
                                                                        helperText={touched.cp_whatsAppNo && errors.cp_whatsAppNo}
                                                                    />
                                                                    {cpWhatsAppError && (
                                                                        <div className="alert-message">
                                                                            {cpWhatsAppError}
                                                                        </div>
                                                                    )}
                                                                </Grid>
                                                                <StyledTextArea
                                                                    name="projectinfo"
                                                                    label="Project Information"
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    value={values?.projectinfo}
                                                                    className='text-area'
                                                                    onChange={(e) => setFieldValue('projectinfo', e.target.value)}
                                                                />
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                                                        <Grid item xs={12} mb={2} className="company-container">
                                                            <Typography variant="subtitle1" align="left" className="subheading">
                                                                Company Details
                                                            </Typography>
                                                            <Grid className="sub-container">
                                                                <StyledInput name="companyName" label="Company Name" touched={touched} errors={errors} value="hello" />
                                                                <StyledInput name="email" label="Email Address" touched={touched} errors={errors} value={values?.email} />
                                                                <StyledInput name="websiteLink" label="Website Link" touched={touched} errors={errors} value={values?.websiteLink} />
                                                                <StyledInput name="linkedinUrl" label="Linkedin Url" touched={touched} errors={errors} value={values?.linkedinUrl} />
                                                                <StyledInput name="githubUrl" label="Github Url" touched={touched} errors={errors} value={values?.githubUrl} />
                                                                <Grid container spacing={gridSpacing} className="half-width-container">
                                                                    <Grid item xs={6}>


                                                                        <StyledPhoneInput
                                                                            value={values?.contactNo}
                                                                            name="contactNo"
                                                                            label="Contact Number"
                                                                            defaultCountry="US"
                                                                            maxLength={14}
                                                                            onChange={(value) => handlePhoneChange("contactNo", value)}
                                                                            touched={touched}
                                                                            errors={errors}
                                                                            helperText={touched.contactNo && errors.contactNo}
                                                                        />
                                                                        {contactError && (
                                                                            <div className="alert-message">
                                                                                {contactError}
                                                                            </div>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={6}>

                                                                        <StyledPhoneInput
                                                                            value={values?.whatsAppNo}
                                                                            name="whatsAppNo"
                                                                            label="WhatsApp Number"
                                                                            defaultCountry="US"
                                                                            maxLength={14}
                                                                            onChange={(value) => handlePhoneChange("whatsAppNo", value)}
                                                                            touched={touched}
                                                                            errors={errors}
                                                                            helperText={touched.whatsAppNo && errors.whatsAppNo}
                                                                        />
                                                                        {whatsAppError && (
                                                                            <div className="alert-message">
                                                                                {whatsAppError}
                                                                            </div>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={12} className="full-width-field">
                                                                        <SelectComponent
                                                                            label="Select Technology Name"
                                                                            items={technologies}
                                                                            multiple={true}
                                                                            value={values?.technologies}
                                                                            name='technologies'
                                                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                            renderValue={(selected) => (
                                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                    {selected.map((value) => (
                                                                                        technologies.map(item => (
                                                                                            item.value === value &&
                                                                                            <Chip key={value} label={item.name} />
                                                                                        ))
                                                                                    ))}
                                                                                </Box>
                                                                            )}
                                                                            onSelecteChange={(e) => handlePhoneChange("technologies", e.target.value)}
                                                                        // onSelecteChange={(e) => setTechnology([...technology, e.target.value])} 
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} mb={2} className="company-container">
                                                            <Typography variant="subtitle1" align="left" className="subheading">
                                                                Company Address
                                                            </Typography>
                                                            <Grid className="sub-container">
                                                                <StyledInput name="address.line_1" label="Address 1" touched={touched} errors={errors} value={values?.address?.line_1} />
                                                                <StyledInput name="address.line_2" label="Address 2" touched={touched} errors={errors} value={values?.address?.line_2} />
                                                                <Grid container spacing={gridSpacing} className="half-width-container">
                                                                    <Grid item xs={6}>
                                                                        <div className="Input-field custom-styled-input select-field">
                                                                            <Autocomplete
                                                                                name='address.country'
                                                                                getOptionLabel={(option) => option.name}
                                                                                value={values?.address?.country || null}
                                                                                onChange={(event, newValue) => {
                                                                                    setFieldValue("address.country", newValue);
                                                                                    setFieldValue("country", newValue?.name || '');
                                                                                    handleCountryChange(newValue);
                                                                                }}
                                                                                renderOption={(props, option) => (
                                                                                    <li {...props}>{option.name}</li>
                                                                                )}
                                                                                id="controllable-states-demo"
                                                                                options={countryData}
                                                                                sx={{ width: '100%' }}
                                                                                renderInput={(params) => <TextField {...params} label="Country" />}
                                                                            />
                                                                        </div>

                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <div className="Input-field custom-styled-input select-field">
                                                                            <Autocomplete
                                                                                name='address.state'
                                                                                getOptionLabel={(option) => option?.name}
                                                                                value={values?.address?.state || null}
                                                                                onChange={(event, newValue) => {
                                                                                    setFieldValue("address.state", newValue);
                                                                                    setFieldValue("state", newValue?.name || '');
                                                                                    handleRegionChange(newValue);
                                                                                }}
                                                                                renderOption={(props, option) => (
                                                                                    <li {...props}>{option?.name}</li>
                                                                                )}
                                                                                id="controllable-states-demo"
                                                                                options={stateData}
                                                                                sx={{ width: '100%' }}
                                                                                renderInput={(params) => <TextField {...params} label="State" />}
                                                                            />
                                                                        </div>

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={gridSpacing} className="half-width-container">
                                                                    <Grid item xs={6}>
                                                                        <div className="Input-field custom-styled-input select-field" >
                                                                            <Autocomplete
                                                                                name='address.city'
                                                                                getOptionLabel={(option) => option.name}
                                                                                value={values?.address?.city || null}
                                                                                onChange={(event, newValue) => {
                                                                                    setFieldValue("address.city", newValue);
                                                                                    setFieldValue("city", newValue?.name || '');
                                                                                }}
                                                                                renderOption={(props, option) => (
                                                                                    <li {...props}>{option.name}</li>
                                                                                )}
                                                                                id="controllable-states-demo"
                                                                                options={cityData}
                                                                                sx={{ width: '100%' }}
                                                                                renderInput={(params) => <TextField {...params} label="City" />}
                                                                            />
                                                                        </div>
                                                                        {
                                                                            <div className="Input-field custom-styled-input">

                                                                            </div>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <StyledInput name="address.zip" label="Zip Code" touched={touched} errors={errors} value={values?.address?.zip} />
                                                                    </Grid>
                                                                </Grid>


                                                            </Grid>
                                                        </Grid>

                                                    </Grid>

                                                </Grid>
                                                <CountryModifier useFormikContext={useFormikContext} stateData={stateData} cityData={cityData} handleRegionChange={handleRegionChange} />
                                            </div>
                                            <ButtonGroup onCancel={() => navigate('/admin/clients')} isEditing={!!editClientId} />
                                        </Form>
                                    </>
                                )
                            }}
                        </Formik>
                    )}

            </div>
        </div>

    );
};

export default AddClient;
