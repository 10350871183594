import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import StyledInput from "../../components/StyledInput";
import { Box, Chip, OutlinedInput, Stack } from "@mui/material";
import SelectComponent from "../../components/SelectComponent";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients } from '../Clients/action'
import { fetchTemplates } from '../EmailTemplate/action'
import { sendEmail, emailSendSuccess } from './action';
import { emailScheduleSuccess } from '../ScheduleEmail/action';
import { scheduleEmail } from '../ScheduleEmail/action'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerCircular } from 'spinners-react';
import TextEditor from "../../components/editor";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getScheduleMailById } from '../ScheduleEmail/action';
import { getClientById } from '../Clients/action';
import { updateScheduleEmail } from '../ScheduleEmail/action';
import moment from 'moment';
import { fetchTechnologies } from "../Technology/action";
import DateNTimePicker from "../../components/DateNTimePicker";
import dayjs from "dayjs";


const SendEmail = ({ clientUserId, closePopup, className, isViewClient, followUp, mailId }) => {
  const buttonStyle = {
    backgroundColor: 'red',
    color: 'white',
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false)
  const [sendNowClicked, setSendNowClicked] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [scheduleClientId, setScheduleCleintId] = useState(null);
  const [selectedTemplateSubject, setSelectedTemplateSubject] = useState("");
  const [selectedTemplateBody, setSelectedTemplateBody] = useState("");
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedClient, setSelectedClient] = useState();
  const [clientDetails, setClientDetails] = useState("");
  const AllClients = useSelector((state) => state.clients.clientRows)
  const AllTemplates = useSelector((state) => state.templates.templateRows)
  // const clients = AllClients.map((item) => item.companyName);
  const clients = AllClients.map((item) => ({ label: item.companyName, value: item._id }));
  const Templates = AllTemplates.map((item) => item.name);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const editScheduleId = query.get("edit");
  const technologyData = useSelector((state) => state.technologies.technologiesRows);
  const [technologies, setTechnologies] = useState();
  const [bodyError, setBodyError] = useState('');
  const [emailOptions, setEmailOptions] = useState([]);
  const [emailType, setEmailType] = useState();

  const defaultFormValues = {
    client: followUp ? [] : "",
    subject: selectedTemplateSubject || '',
    clientId: [],
    dateTime: selectedDateTime ? Datetime.moment(selectedDateTime) : "",
    Template: '',
    emailType: '',
    email: []
  };
  let emailTypeList = ["InitialEmail",
    "FirstFollowUp",
    "SecondFollowUp",
    "ThirdFollowUp",
    "FourthFollowUp",
    "FifthFollowUp"]

  const [initialValues, setInitialValues] = useState(defaultFormValues);

  const validationSchema = Yup.object().shape({
    subject: Yup.string()
      .required("Subject is required")
      .strict(true)
      .trim('No leading or trailing spaces allowed.'),
    client: followUp ? Yup.array().min(1, 'Email is required').required('Client is required') : Yup.string().required("Client is required"),
    emailType: Yup.string()
      .required("Email Type is required"),
    email: followUp || (emailType && emailType) === "InitialEmail" ? Yup.array() : Yup.array().min(1, 'Email is required').required('Email is required'),
  });
  const validationSchemaForSchedule = Yup.object().shape({
    email: followUp || (emailType && emailType) === "InitialEmail" ? Yup.array() : Yup.array().min(1, 'Email is required').required('Email is required'),
    subject: Yup.string()
      .required("Subject is required")
      .strict(true)
      .trim('No leading or trailing spaces allowed.'),
    client: followUp ? Yup.array().min(1, 'Email is required').required('Client is required') : Yup.string().required("Client is required"),
    emailType: Yup.string()
      .required("Email Type is required"),
    dateTime: Yup.date()
      .nullable()
      .required("Date and Time is required")
      // .transform((originalValue, originalObject) => {
      //   const parsedDate = new Date(originalValue);
      //   return isNaN(parsedDate) ? undefined : parsedDate;
      // }, true)
      .typeError("Please enter a valid date and time")
      .min(new Date(), "Selected date must be in the future"),
  });

  const ScheduleMailToEdit = useSelector((state) => state.schedule.getScheduleData);

  const isEditMode = !!editScheduleId;
  useEffect(() => {
    let mailValues;
    if (ScheduleMailToEdit && ScheduleMailToEdit.dateTime) {
      const DateTimeFormat = new Date(ScheduleMailToEdit.dateTime);
      const formattedDate =
        `${DateTimeFormat.getMonth() + 1
        }/${DateTimeFormat.getDate()}/${DateTimeFormat.getFullYear()} ` +
        `${DateTimeFormat.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`;

      ScheduleMailToEdit.dateTime = formattedDate;
      ScheduleMailToEdit.client = clientDetails?.companyName;
      ScheduleMailToEdit.clientId = clientDetails?._id;
      setEmailType(isEditMode ? ScheduleMailToEdit?.emailType : '')
      mailValues = {...ScheduleMailToEdit, email: ScheduleMailToEdit?.email_list}
      defaultFormValues.client = clientDetails?.companyName || followUp ? [] : '';
    }
    if (editScheduleId && ScheduleMailToEdit) {
      setSelectedTemplateBody(ScheduleMailToEdit.body)
    }
    setInitialValues(isEditMode ? mailValues || defaultFormValues : defaultFormValues)
  }, [ScheduleMailToEdit, clientDetails])

  useEffect(() => {
    if (params.clientId) {
      setScheduleCleintId(params.clientId)
    }
  }, [params])
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      dispatch(fetchClients());
      dispatch(fetchTemplates());
      setLoading(false)
    };
    fetchData();
  }, [dispatch]);

  const fetchTechnologiesInfo = async () => {
    try {
      await dispatch(fetchTechnologies());

    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    fetchTechnologiesInfo();
    if (clientUserId) {
      setScheduleCleintId(clientUserId);
    }
  }, [])

  useEffect(() => {
    if (clientDetails) {
      setSelectedClientId(clientDetails ? clientDetails._id : "");
      setSelectedClient(clientDetails);
      if (followUp) {
        if (!mailId) {
          setEmailOptions([
            clientDetails.email,
            clientDetails.cp_email !== "" && clientDetails.cp_email
          ].filter(Boolean));
          setInitialValues({
            ...initialValues,
            client: [
              clientDetails.email,
              clientDetails.cp_email !== "" && clientDetails.cp_email
            ].filter(Boolean)
          })
        } else if (mailId) {
          setEmailOptions([mailId]);
          setInitialValues({
            ...initialValues,
            client: [mailId]
          })
        }
      }else{
        setInitialValues({
          ...initialValues,
          client: clientDetails?.companyName
        })
      }
    }
  }, [clientDetails])

  useEffect(() => {
    if (selectedClient) {
      if (!followUp) {
        setEmailOptions([
          selectedClient?.email,
          selectedClient?.cp_email !== "" && selectedClient?.cp_email
        ].filter(Boolean));
        setInitialValues({
          ...initialValues,
          email: [
            selectedClient.email,
            selectedClient.cp_email !== "" && selectedClient.cp_email
          ].filter(Boolean)
        })
      }
    }else{
      setEmailOptions([])
      setInitialValues({
        ...initialValues,
        email: []
      })
    }
  }, [selectedClient])

  useEffect(() => {
    const fetchScheduleMailToEdit = async () => {
      try {
        setLoading(true)
        const response = await dispatch(getScheduleMailById(editScheduleId))
        setScheduleCleintId(response?.sendTo)
        setLoading(false);

      } catch (error) {
        setLoading(false)
      }
    };
    if (editScheduleId) {
      fetchScheduleMailToEdit();
    }
  }, [dispatch, editScheduleId])

  useEffect(() => {
    const fetchClientToEdit = async () => {
      try {
        setLoading(true);
        const response = await dispatch(getClientById(scheduleClientId));
        setClientDetails(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.response?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    };
    if (scheduleClientId) {
      fetchClientToEdit();
    }
  }, [dispatch, scheduleClientId]);

  const onBack = () => {
    // setEmailType('InitialEmail')
    if (clientUserId && closePopup) {
      closePopup({
        show: false,
        id: ''
      })
    } else
      navigate('/admin/email-log');
  }

  const handleSubmitSendMail = async (values) => {
    if (selectedTemplateBody) {

      if (sendNowClicked && !values.dateTime) {
        console.error("Error: Please select a date and time for scheduling.");
        return;
      }
      let dateNTime = new Date(values.dateTime)
      let formattedDateTime = null;
      const momentObj = moment(dateNTime);

      if (dateNTime) {
        const date = momentObj.format("YYYY-MM-DD");
        const time = momentObj.format("HH:mm:ss");
        const timezone = momentObj.format("Z");
        formattedDateTime = `${date}T${time}${timezone}`;
      }

      const dataToSend = {
        sendTo: [clientUserId ? clientDetails._id : selectedClientId],
        subject: values.subject,
        textbody: selectedTemplateBody,
        emailType: values.emailType,
        ...(sendNowClicked
          ? {
            dateTime: formattedDateTime,
            name: values.subject,
          }
          : {}),
        ...(followUp
          ? {
            email_list: values.client,
          }
          : {}),
        ...(!followUp && emailType !== 'InitialEmail' &&
        {
          email_list: values.email,
        }
        )
      };
      const emptyTemplates = [
        '<p></p>',
        '<p><br></p>',
        '<br>',
        '<p style="margin: 0px; text-align: left;"><br></p>'
      ]
      try {
        if (selectedTemplateBody?.length > 0 && (emptyTemplates.includes(selectedTemplateBody.trim()))) {
          setBodyError('This field is required');
        } else {
          setBodyError('');
          if (selectedClientId && !sendNowClicked && !isEditMode) {
            setLoading(true);
            const status = await dispatch(sendEmail(dataToSend));

            if (status.success) {
              dispatch(emailSendSuccess(status));
              navigate('/admin/email-log');
              toast.success('Email Sent successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              if (closePopup) {
                closePopup({ show: false, id: '' })
              }
            } else {
              toast.error(status?.response?.data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else if (selectedClientId && sendNowClicked && !isEditMode) {
            setLoading(true);
            const status = await dispatch(scheduleEmail(dataToSend));
            if (status.success) {
              dispatch(emailScheduleSuccess(status));
              navigate('/admin/schedule-mail');
              toast.success('Email Schedule successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              closePopup({ show: false, id: '' })
            } else {
              toast.error(status?.response?.data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }
          else {
            setLoading(true);
            const status = await dispatch(updateScheduleEmail(ScheduleMailToEdit._id, dataToSend));
            if (status.success) {
              navigate('/admin/schedule-mail');
              toast.success('Email Schedule Updated successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              toast.error(status?.response?.data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
      setLoading(false);
    } else {
      setBodyError('This field is required');
    }
  }

  useEffect(() => {
    if (selectedClient && technologyData) {
      let data = [];
      technologyData.map((item) => (
        selectedClient.technologies?.map((tech) => {
          if (tech === item._id) {
            data = [...data, item]
          }
        })
        // item._id === selectedClient._id
      ));
      const techNames = data.map((item) => item.name);
      setTechnologies(techNames);
    }
  }, [selectedClient, technologyData]);

  useEffect(() => {
    if (selectedTemplateBody?.length > 0) {
      setBodyError('')
    }
  }, [selectedTemplateBody])

  return (
    <>
      {
        loading ? (
          <div className="spinner-container" >
            <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
          </div >
        ) : (
          <div className={`add-user-form send-email ${className}`}>
            {
              (!clientUserId || isViewClient) &&
              <h2 className="form-subheading">{isEditMode ? 'Edit Schedule Email' : 'Send Email'}</h2>
            }
            <div className="form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={(!isEditMode && sendNowClicked) ? validationSchemaForSchedule : validationSchema}
                onSubmit={(e) => { handleSubmitSendMail(e) }}
              >
                {({ touched, errors, values, handleSubmit, handleChange, setFieldValue }) => {
                  return(

                  <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(values) }} className="email-form">
                    <div className="input-wrapper">  
                      <Stack spacing={2}>
                        {
                          !followUp ?
                            <SelectComponent
                              label="Company Name"
                              name='client'
                              value={values.client ? values.client : clientDetails && (editScheduleId || params.clientId || clientUserId) ? clientDetails?.companyName : ''}
                              // defaultValue={clientDetails && (editScheduleId || params.clientId || clientUserId) ? { label: clientDetails?.companyName, value: clientDetails?._id } : ''}
                              searchable
                              onChange={(e, val) => {
                                if (!val) {
                                  setClientDetails('')
                                }
                                const selectedValue = val?.label;
                                const selectedClientDetails = AllClients.find(
                                  (client) => client.companyName === selectedValue
                                );
                                setSelectedClientId(selectedClientDetails ? selectedClientDetails._id : "");
                                setFieldValue("client", selectedValue ? selectedValue : '');
                                setFieldValue("clientId", selectedClientDetails ? selectedClientDetails._id : "");
                                setSelectedClient(selectedClientDetails);
                                if(!selectedValue){
                                  setFieldValue('email', [])
                                }
                              }}
                              labelError={touched.client && errors.client}
                              helperText={touched.client && errors.client}
                              items={[...clients].sort((a, b) => a.label.localeCompare(b.label))}
                            />
                            :
                            <>
                              <SelectComponent
                                label="Email"
                                value={values.client}
                                name='client'
                                multiple
                                disabled={mailId || false}
                                singleValue
                                onChange={(e) => {
                                  const {
                                    target: { value },
                                  } = e;
                                  setFieldValue("client", typeof value === 'string' ? value.split(',') : value,);
                                }}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} sx={{ backgroundColor: '#e8f2ff' }} />
                                    ))}
                                  </Box>
                                )}
                                labelError={touched.client && errors.client}
                                helperText={touched.client && errors.client}
                                items={emailOptions}
                              />
                            </>
                        }

                        <SelectComponent
                          label="Template"
                          onChange={(e, val) => {
                            const selectedValue = e.target.value;
                            const selectedTemplateObj = AllTemplates.find(
                              (template) => template.name === selectedValue
                            );
                            setSelectedTemplateSubject(selectedTemplateObj?.subject || "");
                            setFieldValue("subject", selectedTemplateObj?.subject || "");
                            setSelectedTemplateBody(selectedTemplateObj?.body || "")
                            setFieldValue("body", selectedTemplateObj?.body || "")
                            setFieldValue("Template", selectedValue || "")
                          }}
                          items={Templates.map((role) => ({ name: role, value: role }))}
                          name="Template"
                          value={values.Template}
                          labelError={errors.Template}
                          helperText={touched.Template && errors.Template}
                        />

                        {(sendNowClicked || isEditMode) ? (
                          <div className="date-picker-container custom-style">
                            <DateNTimePicker
                              value={values.dateTime ? dayjs(values.dateTime) : ''}
                              labelError={errors.dateTime}
                              helperText={touched.dateTime && errors.dateTime}
                              disablePast
                              onChange={(date) => {
                                setFieldValue("dateTime", date);
                                setSelectedDateTime(date);
                              }}
                            />
                            {/* <Datetime
                              inputProps={inputProps}
                              isValidDate={(current) => current.isAfter(Datetime.moment().subtract(1, 'day'))}
                            />
                            {touched.dateTime && errors.dateTime && (
                              <div className="error-input">{errors.dateTime}</div>
                            )} */}
                          </div>
                        ) : null}
                        <StyledInput
                          name="subject"
                          label="Subject"
                          touched={touched}
                          errors={errors}
                          value={values.subject}
                          InputLabelProps={{
                            shrink: values.subject ? true : false,
                          }}
                        />
                        <SelectComponent
                          label="Email Type"
                          onChange={(e, val) => {
                            setFieldValue("emailType", e.target.value);
                            setEmailType(e.target.value)
                          }}
                          items={emailTypeList.map((item) => ({ name: item, value: item }))}
                          name="emailType"
                          value={values.emailType}
                          labelError={errors.emailType}
                          helperText={touched.emailType && errors.emailType}
                        />
                        {
                          !followUp && emailType && values.emailType !== 'InitialEmail' &&
                          <SelectComponent
                            label="Email"
                            value={values.email}
                            name='email'
                            multiple
                            singleValue
                            disabledText="Please select Company before selecting email "
                            onChange={(e) => {
                              const {
                                target: { value },
                              } = e;
                              setFieldValue("email", typeof value === 'string' ? value.split(',') : value,);
                            }}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} sx={{ backgroundColor: '#e8f2ff' }} />
                                ))}
                              </Box>
                            )}
                            labelError={touched.email && errors.email}
                            helperText={touched.email && errors.email}
                            items={emailOptions}
                          />
                        }
                      </Stack>
                      {
                        selectedClient &&
                        <div className="client-details">
                          <h3>Client Details:</h3>
                          <p><b>Email: </b> {mailId ? mailId : (selectedClient.email && selectedClient.cp_email ? `${selectedClient.email}, ${selectedClient.cp_email}` : selectedClient.email ? selectedClient.email : selectedClient.cp_email ? selectedClient.cp_email : '-')}</p>
                          <p><b>Projects: </b> {selectedClient.projectinfo ? selectedClient.projectinfo : '-'}</p>
                          <p><b>Technologies: </b> {technologies && technologies.length > 0 ? technologies.map((item) => <span style={{ marginRight: '7px' }}>{item},</span>) : '-'}</p>
                          <p><b>Website: </b> {selectedClient.websiteLink ? <a href={selectedClient.websiteLink} rel="noreferrer" target="_blank">{selectedClient.websiteLink}</a> : '-'}</p>
                          <p><b>Address: </b>{selectedClient.address.line_1 && selectedClient.address.line_1}{selectedClient.address.line_2 && ', ' + selectedClient.address.line_2}{selectedClient.address.city && ', ' + selectedClient.address.city}{selectedClient.address.state && ', ' + selectedClient.address.state}{selectedClient.address.country && ', ' + selectedClient.address.country}{!selectedClient.address.line_1 && !selectedClient.address.line_2 && !selectedClient.address.country && !selectedClient.address.state && !selectedClient.address.city && '-'}</p>
                        </div>
                      }
                    </div>

                    <div className="editor-container">
                      <TextEditor
                        value={selectedTemplateBody}
                        // onChange={(content) => setFieldValue("body", content.trim() === '<p></p>' || content.trim() === '<br>' || content.trim() === '<p style="margin: 0px; text-align: left;"><br></p>' ? '' : content)}

                        onChange={setSelectedTemplateBody}
                      />
                      {bodyError && <div className="error-input">{bodyError}</div>}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ marginTop: '20px' }}
                        className="send-email-btn"
                      >
                        <Button
                          variant="contained"
                          style={buttonStyle}
                          onClick={onBack}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary" onClick={() => {
                          setSendNowClicked(true);
                        }}>
                          {isEditMode ? 'Save Changes' : 'Schedule'}
                        </Button>
                        {
                          !isEditMode ? (
                            <Button type="submit" variant="contained" color="primary" onClick={() => {
                              setSendNowClicked(false);
                            }}>
                              Send it now
                            </Button>
                          ) : null}
                      </Stack>
                    </div>
                  </Form>
                )}}
              </Formik>
            </div>
          </div>

        )}
    </>
  );
};

export default SendEmail;