// actions.js
import * as actionTypes from './actionTypes';
import axios from 'axios';
import { API_BASE_URL } from '../../config/index'

const baseURL = `${API_BASE_URL}/user/login`;
export const loginSuccess = (token) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: token,
});

export const loginFailure = (error) => ({
  type: actionTypes.LOGIN_FAILURE,
  payload: error,
});

export const logoutSuccess = () => ({
  type: actionTypes.LOGOUT_SUCCESS,
});

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(baseURL, {
        email,
        password,
      });
      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        isLogin: true,
        token: response.data.token,
        error: null, // Clear any previous error
      });
      return response.data
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred.";
      dispatch({
        type: actionTypes.LOGIN_FAILURE,
        isLogin: false,
        error: errorMessage,
      });
      return error
    }
  };
};

export const logout = () => {
  localStorage.removeItem('token');


  return (dispatch) => {
    dispatch(logoutSuccess());
  };
};
