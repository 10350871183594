import { API_BASE_URL } from "../../config";
import axiosInstance from "../axios";

export const getAllTechnologies = async () => {
    try {
        const response = await axiosInstance.get(`${API_BASE_URL}/technology/getAll`)
        return response.data.data;
    } catch (error) {
        console.error('Error fetching Technologies:', error);
        return [];
    }
};

export const getDataByTechnologyId = async (id) => {

    try {
        const res = await axiosInstance.get(`${API_BASE_URL}/technology/getById/${id}`);
        return res.data;
    } catch (error) {
        console.error("Error in getDataByUsedId:", error);

    }
}

export const createNewTech = async (data) => {

    try {
        const res = await axiosInstance.post(`${API_BASE_URL}/technology/create`, data)
        return res.data;
    } catch (error) {
        return error;
    }
}

export const deleteTech = async (id) => {
    try {
        const res = await axiosInstance.delete(`${API_BASE_URL}/technology/delete/${id}`);

        return res.data;
    } catch (error) {
        return error;
    }
}
export const editTech = async (technologyId, data) => {
    try {
        const res = await axiosInstance.put(`${API_BASE_URL}/technology/Update/${technologyId}`, data);
        return res.data;
    } catch (error) {
        return error;
    }
};