import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import StyledInput from "../../components/StyledInput";
import { Stack } from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import { Editor } from "@tinymce/tinymce-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editEmailTemplate, createTemplatesSuccess, updatedTemplatesSuccess } from './action'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createNewTemplate } from '../../api/emailTemplateApi'
import ButtonGroup from "../../components/ButtonGroup";
import { SpinnerCircular } from 'spinners-react';
import { getTemplateById } from './action';
import TinymceEditor from "../../components/editor";
import TextEditor from "../../components/editor";
import { emailTemplateValue } from "../../utils/AppContent";
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Template Name is required")
        .strict(true)
        .trim('No leading or trailing spaces allowed.')
        .matches(/^[a-zA-Z0-9@#$%^&*:,.]+(?:\s+[a-zA-Z0-9@#$%^&*:,.]+)*$/, 'Invalid characters are not allowed.'),
    subject: Yup.string()
        .required("Subject is required"),
    body: Yup.string()
        .required("Description is required")

});

const AddEmailTemplate = () => {
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation()
    const query = new URLSearchParams(location.search);
    const editTemplateId = query.get("edit");
    const defaultFormValues = {
        name: "",
        subject: "",
        body: "",
    };

    useEffect(() => {

        const fetchTemplateToEdit = async () => {
            try {
                setLoading(true)
                await dispatch(getTemplateById(editTemplateId))
                setLoading(false)
            } catch (error) {

                setLoading(false)
            }
        };
        if (editTemplateId) {
            fetchTemplateToEdit();
        }
    }, [dispatch])

    const isEditMode = !!editTemplateId;

    const TemplateToEdit = useSelector((state) => state.templates.getTemplateData)
    const initialValues = isEditMode ? TemplateToEdit || defaultFormValues : defaultFormValues;

    const handleSubmitTemplate = async (values) => {

        try {
            if (editTemplateId) {
                setLoading(true);
                const gotUpdatedTemplate = await dispatch(editEmailTemplate(editTemplateId, values))
                if (gotUpdatedTemplate?.success) {
                    dispatch(updatedTemplatesSuccess(gotUpdatedTemplate))
                    setLoading(false);
                    toast.success('Template Updated successfully!', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    navigate('/admin/email-template');
                } else {
                    toast.error(gotUpdatedTemplate.message || 'Something went wrong', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }

            } else {
                const newTemplate = await createNewTemplate(values);
                if (newTemplate?.success) {
                    dispatch(createTemplatesSuccess(newTemplate));
                    toast.success('Template Created successfully!', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    navigate('/admin/email-template');
                } else {
                    toast.error(newTemplate.message || 'Something went wrong', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <div className='loader-text'>
            {loading ?
                (<div className="spinner-container">
                    <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
                </div>)
                : (
                    <div className="add-user-form send-email">
                        <h2 className="form-subheading">{editTemplateId ? "Edit Template" : "Add Template"}</h2>
                        <div className="form-wrapper">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(e) => { handleSubmitTemplate(e) }}
                            >
                                {({ touched, errors, values, setFieldValue, isValid, handleSubmit }) => {
                                    return (
                                        <Form className="email-form" onSubmit={(e) => { e.preventDefault(); handleSubmit(values) }}>
                                            <div className="input-wrapper">
                                                <StyledInput
                                                    name="name"
                                                    label="Template Name"
                                                    touched={touched}
                                                    errors={errors}
                                                    value={values.name}
                                                />
                                                <StyledInput
                                                    name="subject"
                                                    label="Subject"
                                                    touched={touched}
                                                    errors={errors}
                                                    value={values.subject}
                                                />
                                            </div>
                                            <div className="editor-container">
                                                <TextEditor
                                                    value={values.body}
                                                    onChange={(content) => setFieldValue("body", content.trim() === '<p></p>' || content.trim() === '<br>' || content.trim() === '<p style="margin: 0px; text-align: left;"><br></p>' ? '' : content)}
                                                // initialValue={emailTemplateValue}
                                                />
                                                {touched.body && errors.body && <div style={{ color: 'red', textAlign: 'left' }}>{errors.body}</div>}
                                                <ButtonGroup onCancel={() => navigate('/admin/email-template')} isEditing={!!editTemplateId} />
                                            </div>
                                        </Form>
                                    )
                                }
                                }
                            </Formik>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default AddEmailTemplate;
