// reducer.js

import { DELETE_TECH_SUCCESS, FETCH_TECHNOLOGY_SUCCESS, GET_TECH_DATA_SUCCESS, UPDATE_TECH_SUCCESS } from './action';

const initialState = {
    technologiesRows: [],
    techData: null,
};

const technologiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TECHNOLOGY_SUCCESS:
            return {
                ...state,
                technologiesRows: action.payload,
            };
        case DELETE_TECH_SUCCESS:
            return {
                ...state,
                technologiesRows: state.technologiesRows.filter(user => user._id !== action.payload),
            };
        case UPDATE_TECH_SUCCESS:
            const updatedTech = action.payload;
            const updatedTechRows = state.technologiesRows.map(user => {
                if (user._id === updatedTech._id) {
                    return updatedTech;
                }
                return user;
            });
            return {
                ...state,
                technologiesRows: updatedTechRows,
            };
        case GET_TECH_DATA_SUCCESS:
            return {
                ...state,
                techData: action.payload,
            };
        default:
            return state;
    }
};

export default technologiesReducer;
