import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SpinnerCircular } from 'spinners-react';
import { getClientById, getViewClientDetails } from "./action";
import { getEmailReportsOfClient } from "../EmailLog/action";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import SendEmail from "../SendEmail";
import { formattedDate } from "./utils";
import useResponsive from "../../hooks/useResponsive";
import PopUp from "../../components/Popup";


const Row = (props) => {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ minWidth: '194px !important' }}>{formattedDate(row?.time)}</TableCell>
                <TableCell><span className='table-status' style={{ background: row?.color, color: row?.event.toLowerCase() === 'pending' && '#000' }}>{row?.event}</span></TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const ViewClient = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [valueToSortEmail, setValueToSortEmail] = useState();
    const [orderDirectionEmail, setOrderDirectionEmail] = useState();
    const [valueToSortCPEmail, setValueToSortCPEmail] = useState();
    const [orderDirectionCPEmail, setOrderDirectionCPEmail] = useState();
    const viewClientDetails = useSelector((state) => state.clients.getViewClientData);
    const query = new URLSearchParams(location.search);
    const clientId = query.get("view");
    const navigate = useNavigate()
    const isDesktop = useResponsive("up", "lg");
    const [events, setEvents] = useState({
        companyMailEvents: [],
        clientMailEvents: []
    });
    const [openFollowUpEmailPopup, setOpenFollowUpEmailPopup] = useState({
        show: false,
        id: '',
        mail: ''
    })

    const handleFollowUpEmail = (id, mail) => {
        setOpenFollowUpEmailPopup({
            show: true,
            id,
            mail
        })
    }

    useEffect(() => {
        if (clientId) {
            fetchClientDetails()
        }
    }, [clientId]);

    useEffect(() => {
        async function getEvents() {
            let eventsData = {
                companyMailEvents: [],
                clientMailEvents: []
            };
            const groupedEvents = [];
            await viewClientDetails?.emailLogs?.map((item) => {
                item?.events?.map((event) => {
                    const eventId = event.id;

                    const existingGroup = groupedEvents.find(group => group[0].id === eventId);

                    if (existingGroup) {
                        existingGroup.push(event);
                    } else {
                        groupedEvents.push([event]);
                    }
                    eventsData = {
                        companyMailEvents: [...eventsData.companyMailEvents, groupedEvents],
                        clientMailEvents: [...eventsData.clientMailEvents]
                    }
                })
            })
            await viewClientDetails?.cp_emailLogs?.map((item) => {
                item?.events?.map((event) => {
                    eventsData = {
                        companyMailEvents: [...eventsData.companyMailEvents],
                        clientMailEvents: [...eventsData.clientMailEvents, event]
                    }
                })
            })
            setEvents(eventsData)
        }
        if (viewClientDetails) {
            getEvents();
        }
    }, [viewClientDetails])

    const fetchClientDetails = async () => {
        try {
            setLoading(true);
            await dispatch(getViewClientDetails(clientId));
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleRequestSortEmail = (e, property) => {
        const isAscending = (valueToSortEmail === property && orderDirectionEmail === 'asc')
        setValueToSortEmail(property)
        setOrderDirectionEmail(isAscending ? 'desc' : 'asc')
    }

    const handleRequestSortCPEmail = (e, property) => {
        const isAscending = (valueToSortCPEmail === property && orderDirectionCPEmail === 'asc')
        setValueToSortCPEmail(property)
        setOrderDirectionCPEmail(isAscending ? 'desc' : 'asc')
    }

    const createSortHandlerEmail = (property) => (event) => {
        handleRequestSortEmail(event, property)
    }

    const createSortHandlerCPEmail = (property) => (event) => {
        handleRequestSortCPEmail(event, property)
    }

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const sortedRowInformation = (rowArray, comparator) => {
        let stabilizedRowArray = rowArray?.map((el, index) => [el, index])
        stabilizedRowArray = stabilizedRowArray.map(el => [{ ...el[0], event: capitalizeFirstLetter(el[0].event) }])
        stabilizedRowArray?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedRowArray?.map((el) => el[0])
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descedingComparator(a, b, orderBy)
            : (a, b) => -descedingComparator(a, b, orderBy)
    }

    function descedingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }

    const onBack = () => {
        navigate(-1);
    }

    return (
        <>
            <div className="add-user-form add-client view-client-container">
                <div className="form-subheading">
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <IconButton aria-label="back" size="medium" onClick={onBack}>
                            <ChevronLeftRoundedIcon size="large" />
                        </IconButton>
                        <h2 className="report-heading" style={{ textTransform: 'capitalize' }}>{viewClientDetails?.clietDetails?.companyName}</h2>
                    </div>
                </div>
                {loading ? (
                    <div className="spinner-container">
                        <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
                    </div>
                ) : (
                    <div className={`add-client view-client-wrapper`}>
                        <div className="client-info-wrapper">
                            <div className="info-card">
                                <h3>Company Details</h3>
                                <div className="col-wrapper">
                                    <div className="col">
                                        <div className="info">
                                            <b>Email: </b> <p> {viewClientDetails?.clietDetails.email || '-'}</p>
                                        </div>
                                        <div className="info">
                                            <b>Contact No: </b> <p> {viewClientDetails?.clietDetails.contactNo || '-'}</p>
                                        </div>
                                        <div className="info">
                                            <b>Address: </b> <p>{viewClientDetails?.clietDetails?.address?.line_1 && viewClientDetails?.clietDetails?.address?.line_1}{viewClientDetails?.clietDetails?.address?.line_2 && ', ' + viewClientDetails?.clietDetails?.address?.line_2}{viewClientDetails?.clietDetails?.address?.city && ', ' + viewClientDetails?.clietDetails?.address?.city}{viewClientDetails?.clietDetails?.address?.state && ', ' + viewClientDetails?.clietDetails?.address?.state}{viewClientDetails?.clietDetails?.address?.country && ', ' + viewClientDetails?.clietDetails?.address?.country}{!viewClientDetails?.clietDetails?.address?.line_1 && !viewClientDetails?.clietDetails?.address?.line_2 && !viewClientDetails?.clietDetails?.address?.country && !viewClientDetails?.clietDetails?.address?.state && !viewClientDetails?.clietDetails?.address?.city && '-'}</p>
                                        </div>
                                        <div className="info">
                                            <b>Whatsapp No: </b> <p> {viewClientDetails?.clietDetails.whatsAppNo || '-'}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="info">
                                            <b>Projects: </b> <p> {viewClientDetails?.clietDetails.projectinfo || '-'}</p>
                                        </div>
                                        <div className="info">
                                            <b>Website: </b> <p> {viewClientDetails?.clietDetails.websiteLink ? <a href={viewClientDetails?.clietDetails.websiteLink} rel="noreferrer" target="_blank">{viewClientDetails?.clietDetails.websiteLink}</a> : '-'}</p>
                                        </div>
                                        <div className="info">
                                            <b>Linkedin: </b> <p> {viewClientDetails?.clietDetails.linkedinUrl ? <a href={viewClientDetails?.clietDetails.linkedinUrl} rel="noreferrer" target="_blank">{viewClientDetails?.clietDetails.linkedinUrl}</a> : '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="info-card">
                                <h3>Contact Person Details</h3>
                                <div className="info">
                                    <b>Name: </b> <p> {viewClientDetails?.clietDetails.cp_firstName ? `${viewClientDetails?.clietDetails.cp_firstName} ${viewClientDetails?.clietDetails.cp_lastName}` : '-'}</p>
                                </div>
                                <div className="info">
                                    <b>Email: </b> <p> {viewClientDetails?.clietDetails.cp_email || '-'}</p>
                                </div>
                                <div className="info">
                                    <b>Contact No: </b> <p> {viewClientDetails?.clietDetails.cp_contactNo || '-'}</p>
                                </div>
                                <div className="info">
                                    <b>Whatsapp No: </b> <p> {viewClientDetails?.clietDetails.cp_whatsAppNo || '-'}</p>
                                </div>
                            </div>
                        </div>
                        <div className={`form-wrapper client-table-container`}>
                            <TableContainer sx={{ padding: '0 20px' }} className="collapse-table-container">
                                <div className="client-log-header">
                                    <h3 className="email-heading">Company email: {viewClientDetails?.clietDetails?.email || '-'}</h3>
                                    <Button size="small" variant="contained" className="add-btn" onClick={() => handleFollowUpEmail(viewClientDetails?.clietDetails._id, viewClientDetails?.clietDetails.email)}><MailOutlineRoundedIcon sx={{ color: 'white', fontSize: '20px' }} />Send Mail</Button>
                                </div>
                                {viewClientDetails?.emailLogs && viewClientDetails?.emailLogs?.length > 0 ?
                                    <>
                                        {
                                            viewClientDetails.emailLogs.map((log) => (
                                                <div className="group-records-wrapper">
                                                    <div className="group-records-head">
                                                        <p>{log.subject}</p>
                                                        <div className="right-col">
                                                            <p>Sent by: {log.from}</p>
                                                        </div>
                                                    </div>
                                                    <div className="view-client-table">
                                                        <div className="group-events">
                                                            {
                                                                log.events?.map((event) => (
                                                                    <div className="group-event">
                                                                        <p className='table-status' style={{ color: event?.event.toLowerCase() === 'pending' ? '#000' : event.color, }}>{event?.event}</p>
                                                                        <p>{formattedDate(event?.time)}</p>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </>
                                    : <p style={{ fontSize: '14px', marginTop: '20px' }}>No Email log found</p>
                                }

                            </TableContainer>
                            <TableContainer sx={{ padding: '0 20px' }} className="collapse-table-container">
                                <div className="client-log-header">
                                    <h3 className="email-heading">Contact Person email: {viewClientDetails?.clietDetails?.cp_email || '-'}</h3>
                                    {
                                        viewClientDetails?.clietDetails?.cp_email &&
                                        <Button size="small" variant="contained" className="add-btn" onClick={() => handleFollowUpEmail(viewClientDetails?.clietDetails._id, viewClientDetails?.clietDetails?.cp_email)}><MailOutlineRoundedIcon sx={{ color: 'white', fontSize: '20px' }} />Send Mail</Button>
                                    }
                                </div>
                                {viewClientDetails?.cp_emailLogs && viewClientDetails?.cp_emailLogs?.length > 0 ?
                                    <>
                                        {
                                            viewClientDetails.cp_emailLogs.map((log) => (
                                                <div className="group-records-wrapper">
                                                    <div className="group-records-head">
                                                        <p>{log.subject}</p>
                                                        <div className="right-col">
                                                            <p>Sent by: {log.from}</p>
                                                        </div>
                                                    </div>
                                                    <div className="group-events">
                                                        {
                                                            log.events?.map((event) => (
                                                                <div className="group-event">
                                                                    <p className='table-status' style={{ color: event?.event.toLowerCase() === 'pending' ? '#000' : event.color }}>{event?.event}</p>
                                                                    <p>{formattedDate(event?.time)}</p>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </>
                                    : <p style={{ fontSize: '14px', marginTop: '20px' }}>No Email log found</p>
                                }

                            </TableContainer>
                            {/* <div style={{ marginTop: '30px' }}><SendEmail clientUserId={clientId} className={'view-client'} isViewClient /></div> */}
                        </div>
                    </div>)}
            </div>
            <PopUp
                onClose={() => setOpenFollowUpEmailPopup({
                    show: false,
                    id: '',
                    mail: ''
                })}
                open={openFollowUpEmailPopup.show}
                title='Follow Up Email'
                className="popup-wrapper follow-up"
            >
                <SendEmail followUp clientUserId={openFollowUpEmailPopup.id} mailId={openFollowUpEmailPopup.mail} closePopup={setOpenFollowUpEmailPopup} />
            </PopUp>
        </>
    )
}

export default ViewClient