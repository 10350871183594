import { SCHEDULE_EMAIL_SUCCESS, FETCH_SCHEDULE_EMAIL_LOG_SUCCESS, DELETE_SCHEDULE_SUCCESS, GET_SCHEDULE_DATA_SUCCESS, UPDATE_SCHEDULE_DATA_SUCCESS } from './action'
const initialState = {
  EmailStatus: [],
  getScheduleData: null,
};
const schedulMailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_EMAIL_SUCCESS:
      return {
        ...state,
        EmailStatus: action.payload,
      };
    case FETCH_SCHEDULE_EMAIL_LOG_SUCCESS:
      return {
        ...state,
        EmailStatus: action.payload,
      };
    case DELETE_SCHEDULE_SUCCESS:
      const updatedEmailStatus = state.EmailStatus.filter(mail => mail._id !== action.payload);
      return { 
        ...state,
         EmailStatus: updatedEmailStatus 
        };
    case GET_SCHEDULE_DATA_SUCCESS:
      return {
        ...state,
        getScheduleData: action.payload,
      };
    case UPDATE_SCHEDULE_DATA_SUCCESS:
      const updatedData = action.payload;
      const updatedDataRows = state.EmailStatus.map(data => {
        if (data._id === updatedData._id) {
          return updatedData;
        }
        return data;
      });
      return {
        ...state,
        EmailStatus: updatedDataRows,
      };
    default:
      return state;
  }
};

export default schedulMailReducer;
