import { Box, TextField } from '@mui/material';
import { format } from 'date-fns';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRangeField = ({ state, handleChange, open, openCalender, dateRef, label, sx }) => {
  return (
    <Box sx={sx} className="date-range-picker-wrapper">
      <TextField
        variant="outlined"
        value={`${format(state[0].startDate, "dd/MM/yyyy")} to ${format(state[0].endDate, "dd/MM/yyyy")}`}
        readOnly
        className='dateInputBox'
        onClick={() => openCalender(open => !open)}
        label={label}
        sx={sx}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Box ref={dateRef}>
        {
          open &&
          <Box sx={{ position: 'absolute', right: 0, zIndex: 101, background: '#fff', boxShadow: '0px 0px 12px rgba(0,0,0,0.05)' }}>
            <DateRange
              editableDateInputs
              onChange={handleChange}
              moveRangeOnFirstSelection={false}
              className='calendarElement'
              ranges={state}
              months={1}
              direction='horizontal'
              style={{ ...sx }}
            />
          </Box>
        }
      </Box>
    </Box>
  )
}

export default DateRangeField