import { sendEmailToClient } from '../../api/sendMailApi';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';

export const emailSendSuccess=(status)=>({
    type:SEND_EMAIL_SUCCESS,
    payload: status,
})

export const sendEmail = (data) => {
    return async () => {
        try {
            const status = await sendEmailToClient(data);
           return status;
        } catch (error) {
    
        }
      };
}
