import React, { useEffect, useState } from "react";
import { Grid, Box, Stack, TextField, Typography, Tabs, Tab, InputAdornment, IconButton } from "@mui/material";
import CardBlocks from "../../components/CardBlocks";
import StyledInput from "../../components/StyledInput";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import profile_image from '../../assets/images/profile_pic.png';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, UpdateCurrentUser, updatedUserSuccess } from './action';
import SelectComponent from "../../components/SelectComponent";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerCircular } from 'spinners-react';
import StyledPhoneInput from '../../components/StyledPhoneInput'
import { formatPhoneNumber } from '../../utils/phoneUtils';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import axiosInstance from "../../api/axios";
import { API_BASE_URL } from "../../config";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Profile = () => {

  const buttonStyle = {
    backgroundColor: 'red',
    color: 'white',
  };

  const [loading, setLoading] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [error, setError] = useState("")
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false
  })
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser)
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getUserData = async () => {
      setLoading(true)
      await dispatch(getCurrentUser());
      setLoading(false)
    }
    if (!currentUser || !currentUser.firstName) {
      getUserData();
    }
  }, [dispatch, currentUser]);

  const roles = [
    { value: 1, name: "Admin" },
    { value: 2, name: "HR" },
    { value: 3, name: "User" }
  ]

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .strict(true)
      .trim('No leading or trailing spaces allowed.')
      .matches(/^[a-zA-Z0-9@#$%^&*:,.]+(?:\s+[a-zA-Z0-9@#$%^&*:,.]+)*$/, 'Invalid characters are not allowed.')
      .min(2, "Too Short!")
      .max(30, "Too Long!"),
    lastName: Yup.string()
      .required("Last Name is required")
      .strict(true)
      .trim('No leading or trailing spaces allowed.')
      .matches(/^[a-zA-Z0-9@#$%^&*:,.]+(?:\s+[a-zA-Z0-9@#$%^&*:,.]+)*$/, 'Invalid characters are not allowed.')
      .min(2, "Too Short!")
      .max(30, "Too Long!"),
    email: Yup.string()
      .email("Invalid email")
      .strict(true)
      .trim('No leading or trailing spaces allowed.')
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email format"
      ),
    contactNo: Yup.string()
      .required("Contact No. is required")
      .test('isValidTel', 'Please enter a valid number', (value) => matchIsValidTel(value)),

    position: Yup.string().required("Role is required"),
  });

  const resetPasswordValidationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Old Password is required"),
    newPassword: Yup.string()
      .required("New Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#~^()_+{}[\]:;<>,.?\\/-]).{8,}$/,
        "There should be at least 8 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character"
      ),
  });

  const defaultFormValues = {
    firstName: currentUser?.firstName || "",
    lastName: currentUser?.lastName || "",
    email: currentUser?.email || "",
    contactNo: currentUser?.contactNo || "",
    position: currentUser?.position || "",
    oldPassword: '',
    newPassword: ''
  };

  const initialValues = defaultFormValues;

  const handleSubmitProfile = async (values) => {
    try {
      if (currentUser) {
        const response = await dispatch(UpdateCurrentUser(values))
        if (response.success) {
          dispatch(updatedUserSuccess(response))
          navigate('/admin/users');
          toast.success('Profile Updated successfully!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        else {
          toast.error(response?.response?.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } catch (error) {
      console.log("error", error)
    }

  };

  const handleClickShowPassword = (type) => {
    if (type === "old") {
      setShowPassword(prev => ({
        old: !prev.old,
        new: prev.new
      }
      ))
    } else {
      setShowPassword(prev => ({
        old: prev.old,
        new: !prev.new
      }
      ))
    }
  }

  const resetPassword = async (values, { resetForm }) => {
    if (values.oldPassword && values.newPassword && currentUser.email) {
      try {
        const data = {
          email: currentUser?.email,
          passwordOld: values.oldPassword,
          passwordNew: values.newPassword,
        }
        const res = await axiosInstance.post(`${API_BASE_URL}/user/changePassword`, data);
        if (res.status === 200) {
          toast.success('Password Updated successfully!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setShowResetPassword(false)
          resetForm();
        }
        else {
          toast.error(res?.response?.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.error('Old & New password fields are required', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  return (

    <>
      {loading ?
        (
          <div className="spinner-container">
            <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
          </div>
        ) : (

          <div className="add-user-form user">
            <Box sx={{ width: '100%' }} className="user-tabs-wrapper">
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="User Information" {...a11yProps(0)} />
                  <Tab label="Reset Password" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} className="user-panels" index={0}>
                <div className="form-wrapper" style={{ marginTop: '12px' }}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}

                    validationSchema={validationSchema}
                    onSubmit={(e) => { handleSubmitProfile(e) }}
                  >
                    {({ touched, errors, values, setFieldValue, handleSubmit, handleChange, isValid, dirty }) => {
                      const handlePhoneChange = (value) => {
                        const result = value.replace(/[^+\d]+/g, "");
                        const formattedPhoneNumber = formatPhoneNumber(value);
                        setFieldValue("contactNo", formattedPhoneNumber);
                        if (matchIsValidTel(result) !== true) {
                          setError("Please enter a valid number");
                        } else {
                          setError("")
                        }
                      };

                      return (

                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(values) }}>
                          <StyledInput
                            name="firstName"
                            label="First Name"
                            touched={touched}
                            errors={errors}
                            onChange={handleChange}
                          />

                          <StyledInput
                            name="lastName"
                            label="Last Name"
                            touched={touched}
                            errors={errors}
                            onChange={handleChange}
                          />
                          <StyledInput
                            name="email"
                            label="Email Address"
                            touched={touched}
                            errors={errors}
                            value={values.email}
                          // disabled={currentUser ? true : false}
                          />
                          <StyledPhoneInput
                            value={values.contactNo}
                            name="contactNo"
                            label="Contact Number"
                            defaultCountry="US"
                            maxLength={14}
                            onChange={handlePhoneChange}
                            touched={touched}
                            errors={errors}
                            helperText={touched.contactNo && errors.contactNo}
                          />

                          <SelectComponent
                            label="Role"
                            onSelecteChange={(e) => setFieldValue("position", e.target.value)}
                            items={roles}
                            name="position"
                            value={values.position}
                            labelError={errors.position}
                            helperText={touched.position && errors.position}
                          />
                          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} sx={{ marginTop: '20px' }}>
                            <Button variant="contained" style={!showResetPassword ? buttonStyle : {}} onClick={() => navigate('/admin/users')} disabled={showResetPassword}>
                              Cancel
                            </Button>

                            <Button variant="contained" color="primary" type="submit" disabled={showResetPassword}>
                              Save
                            </Button>

                          </Stack>

                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} className="user-panels" index={1}>
                <div className="form-wrapper" style={{ marginTop: '12px' }}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}

                    validationSchema={resetPasswordValidationSchema}
                    onSubmit={resetPassword}
                  >
                    {({ touched, errors, values, setFieldValue, handleSubmit, handleChange, isValid, dirty }) => {

                      return (

                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(values) }}>
                          <StyledInput
                            name="oldPassword"
                            label="Old Password"
                            type={showPassword.old ? 'text' : 'password'}
                            togglePassword={() => handleClickShowPassword('old')}
                            touched={touched}
                            errors={errors}
                            value={values.oldPassword}
                            onChange={(e) => setFieldValue("oldPassword", e.target.value)}
                          // disabled={currentUser ? true : false}
                          />
                          <StyledInput
                            name="newPassword"
                            label="New Password"
                            type={showPassword.new ? 'text' : 'password'}
                            togglePassword={() => handleClickShowPassword('new')}
                            touched={touched}
                            errors={errors}
                            value={values.newPassword}
                            onChange={(e) => setFieldValue("newPassword", e.target.value)}
                          // disabled={currentUser ? true : false}
                          />
                          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} sx={{ marginTop: '20px' }}>
                            <Button variant="contained" size="small" style={buttonStyle} onClick={() => navigate('/admin/users')}>
                              Cancel
                            </Button>
                            <Button variant="contained" size="small" color="primary" type="submit">
                              Reset
                            </Button>
                          </Stack>

                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </CustomTabPanel>
            </Box>
          </div>
        )}
    </>

  );
};

export default Profile;
