import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Outlet, Navigate, useNavigate } from "react-router-dom";
import Dashboard from '../pages/Dashboard';
import Clients from '../pages/Clients';
import SendEmail from '../pages/SendEmail';
import ScheduleEmail from '../pages/ScheduleEmail';
import EmailTemplate from '../pages/EmailTemplate';
import ViewEmailLog from '../pages/EmailLog/ViewEmailLog';
import EmailLog from '../pages/EmailLog';
import Profile from '../pages/Users/Profile';
import Login from '../pages/Login';
import Users from '../pages/Users';
import AddUserForm from '../pages/Users/AddUserForm';
import AddClient from '../pages/Clients/AddClient';
import AddEmailTemplate from '../pages/EmailTemplate/AddEmailTemplate';
import MainLayout from '../components/MainLayout';
import ForgotPassword from '../pages/ForgotPassword';
import ActivityLog from '../pages/Activity';
import Technology from "../pages/Technology";
import AddTechnology from "../pages/Technology/AddTechnology";
import ViewClient from "../pages/Clients/ViewClient";
import FollowUpLog from "../pages/FollowUpLog";
const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

const ProtectedRoute = ({ children }) => {
  if (isAuthenticated()) {
    return children;
  } else {
    return <Navigate to="/admin/login" replace />;
  }
};

export default function Routing() {
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate(isAuthenticated() ? '/admin/dashboard' : '/admin/login')
    }
  }, [])
  return (

    <Routes>
      <Route
        path="/admin/"
        element={<MainLayout />}
      >
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="users/*" element={<ProtectedRoute><Outlet /></ProtectedRoute>}>
          <Route index element={<Users />} />
          <Route path="add-user" element={<AddUserForm />} />
        </Route>
        <Route path="clients/*" element={<ProtectedRoute><Clients /></ProtectedRoute>} />
        <Route path="send-mail/*" element={<ProtectedRoute><SendEmail /></ProtectedRoute>} />
        <Route path="send-mail/:id" element={<ProtectedRoute><SendEmail /></ProtectedRoute>} />
        <Route path="send-mail/client/:clientId" element={<ProtectedRoute><SendEmail /></ProtectedRoute>} />
        <Route path="schedule-mail/*" element={<ProtectedRoute><ScheduleEmail /></ProtectedRoute>} />
        <Route path="email-template/*" element={<ProtectedRoute><EmailTemplate /></ProtectedRoute>} />
        <Route path="email-template/add-email-template" element={<ProtectedRoute><AddEmailTemplate /></ProtectedRoute>} />
        <Route path="email-log/*" element={<ProtectedRoute><EmailLog /></ProtectedRoute>} />
        <Route path="followup-log/*" element={<ProtectedRoute><FollowUpLog /></ProtectedRoute>} />
        <Route path="email-log/:id/:isCompany" element={<ProtectedRoute><ViewEmailLog /></ProtectedRoute>} />
        <Route path="profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        {/* <Route path="activity-report" element={<ProtectedRoute><ActivityLog /></ProtectedRoute>} /> */}
        <Route path="clients/add-client" element={<ProtectedRoute><AddClient /></ProtectedRoute>} />
        <Route path="clients/view-client" element={<ProtectedRoute><ViewClient /></ProtectedRoute>} />
        <Route path="technology" element={<ProtectedRoute><Technology /></ProtectedRoute>} />
        <Route path="technology/add" element={<ProtectedRoute><AddTechnology /></ProtectedRoute>} />
      </Route>
      {/* Routes outside of MainLayout */}
      {
        !isAuthenticated() &&
        <>
          <Route path="/admin/forgotpassword" element={<ForgotPassword />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/" element={<Login />} />
        </>
      }
    </Routes>
  );
}
