// reducer.js

import { CLEAR_DATA, FETCH_DASHBOARD_DATA_LOADING, FETCH_DASHBOARD_DATA_SUCCESS, TOGGLE_SIDEBAR } from './action';

const initialState = {
  dashboardData: [],
  isLoading: false,
  isNavExpanded: false
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        isLoading: false
      };

    case FETCH_DASHBOARD_DATA_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isNavExpanded: !state?.isNavExpanded
      };
    case CLEAR_DATA:
      return {
        ...state,
        dashboardData: {}
      }
    default:
      return state;
  }
};

export default dashboardReducer;
