import React, { useEffect, useState, useMemo } from "react";
import { GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import './Clients.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients } from "./action";
import { deleteClientSuccess } from '../Clients/action'
import { deleteClient } from '../../api/clientApi'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UserTable from '../../components/UserTable';
import DialogBox from '../../components/DialogBox';
import { SpinnerCircular } from 'spinners-react';
import SearchComponent from '../../components/SearchComponent'

const Clients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clientsData, setClientsData] = useState();
  const [filteredClients, setFilteredClients] = useState();
  const clientRows = useSelector((state) => state.clients.clientRows);

  useEffect(() => {
    if (clientRows) {
      const data = clientRows.map((item) => {
        const addedBy = item.addedBy?.date ? new Date(item.addedBy?.date).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }) : '';
        const editedBy = item.editedBy?.date ? new Date(item.editedBy?.date).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }) : '';
        return {
          ...item,
          addedByDate: addedBy,
          editedByDate: editedBy
        }
      })
      setClientsData(data);
    }
  }, [clientRows])

  useEffect(() => {
    const data = clientsData?.filter((client) => {
      const rowText = Object.values(client).join(' ').toLowerCase();
      return rowText.includes(searchQuery.toLowerCase());
    });
    setFilteredClients(data)
  }, [clientsData, searchQuery])

  useEffect(() => {
    const data = clientsData?.filter((client) => {
      if (client.address) {
        const rowText = Object?.values(client.address).join(' ').toLowerCase();
        return rowText.includes(searchAddress.toLowerCase());
      }
    });
    setFilteredClients(data)
  }, [clientsData, searchAddress])

  const fetchClientsData = async () => {
    setLoading(true)
    dispatch(fetchClients());
    setLoading(false)
  };
  useEffect(() => {
    fetchClientsData();
  }, [dispatch]);

  const handleDeleteClientClick = async (id) => {
    setSelectedClientId(id);
    setOpenDialog(true);
  }


  const handleSearch = (query) => {
    setSearchQuery(query);
  };
  const handleSearchAddress = (e) => {
    setSearchAddress(e);
  }

  const handleConfirmDelete = async () => {
    try {
      setLoading(true)
      await deleteClient(selectedClientId);
      dispatch(deleteClientSuccess(selectedClientId));
      setLoading(false)
      setOpenDialog(false);
      toast.success('Client deleted successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

    } catch (error) {
      setLoading(false)
      console.error("Error deleting Client:", error);
    }
  };

  const handleAddClientClick = () => {
    navigate("/admin/clients/add-client");
  };

  const handleViewClientClick = (id) => {
    navigate(`/admin/clients/view-client?view=${id}`);
  };

  const handleEditClientClick = (id) => {

    navigate(`/admin/clients/add-client?edit=${id}`)
  }

  const columns: GridColDef[] = [
    { field: 'companyName', headerName: 'Company Name', headerClassName: "header-cell", minWidth: 120, flex: 1, },

    {
      field: 'email',
      headerName: 'Company Email',
      type: 'email',
      headerClassName: "header-cell",
      minWidth: 160, flex: 1,
    },
    {
      field: 'addedByDate',
      headerName: 'Created On',
      headerClassName: "header-cell",
      minWidth: 140, flex: 1,
    },
    {
      field: 'editedByDate',
      headerName: 'Updated On',
      headerClassName: "header-cell",
      minWidth: 140, flex: 1,
      sortable: false,
    },
    {
      field: 'addedByFullName',
      headerName: 'Added by',
      headerClassName: "header-cell",
      minWidth: 140, flex: 1,
      sortable: false,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: "header-cell",
      minWidth: 180,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton color="primary" aria-label="edit" onClick={() =>
            window.open(`${window.location.origin}/admin/send-mail/client/${params.row._id}`)
            //  navigate(`/admin/send-mail/client/${params.row._id}`)
          }>
            <MailOutlineRoundedIcon />
          </IconButton>
          <IconButton color="primary" aria-label="edit" onClick={() => handleEditClientClick(params.row._id)}>
            <EditIcon />
          </IconButton>
          <IconButton color="primary" aria-label="edit" onClick={() => handleViewClientClick(params.row._id)}>
            <RemoveRedEyeIcon />
          </IconButton>
          <IconButton color="primary" aria-label="edit" onClick={() => handleDeleteClientClick(params.row._id)}>
            <DeleteOutlineIcon sx={{ color: 'red' }} />
          </IconButton>
        </>
      ),
    },
  ];


  const sortedClients = useMemo(() => {
    if (filteredClients?.length > 0) {
      return [...filteredClients].sort((a, b) => {
        const dateA = new Date(a.addedBy.date).getTime();
        const dateB = new Date(b.addedBy.date).getTime();
        return dateB - dateA;
      });
    }
    return [];
  }, [filteredClients]);


  return (
    <>
      <div className="block-container">
        <h2 className="block-heading">Clients</h2>
        <div className="sub-header">
          <div className="search-wrapper">
            <SearchComponent onSearch={handleSearch} name="company" />
            <SearchComponent onSearch={handleSearchAddress} name="address" />
          </div>
          <Button variant="contained" className="add-btn" onClick={handleAddClientClick}><AddCircleIcon sx={{ color: 'white' }} />Add Client</Button>
        </div>
        {loading ? (
          <div className="spinner-container">
            <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
          </div>
        ) : (
          <UserTable
            rows={sortedClients}
            columns={columns}
            columnName="Client"
          />
        )}

        <DialogBox open={openDialog} onClose={() => setOpenDialog(false)} onConfirm={handleConfirmDelete} message="Are you sure you want to delete this client?" title="Deletion" />
      </div>

    </>
  );
};

export default Clients;