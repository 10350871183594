import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from '../../assets/images/HBWebsol_150X150.png';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './action';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Stack,
} from '@mui/material';
import StyledInput from '../../components/StyledInput'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authDataRes = useSelector(({ auth }) => auth);
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Email is required")
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
        "Enter a valid Email"
      ),
    password: Yup.string()
      .required("Password is required")
  });

  const initialValues = {
    username: "",
    password: "",
  };
  useEffect(() => {
    if (authDataRes?.isLogin && authDataRes?.token) {
      localStorage.setItem('token', authDataRes?.token);
      navigate("/admin/dashboard");
    }
    // else {
    //   toast.error(authDataRes.error, {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //   });
    // }
  }, [authDataRes])

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const res = await dispatch(login(values.username, values.password));
      if (!res?.response?.data?.success) {
        toast.error(res?.response?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <div className="block-container login-container">
      <div className="login-form">
        <Box component="span" sx={{ display: 'block', flexGrow: 1, textAlign: "center" }}>
          <img src={logo} width="100" alt="Logo" className="logo" />
        </Box>
        <h4 className="form-title">Sign in</h4>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, handleChange, handleBlur, handleSubmit, type }) => (
            <Form>
              <Box sx={{ marginBottom: '10px' }}>
                <StyledInput
                  name="username"
                  label="Email Address"
                  touched={touched}
                  errors={errors}
                />

              </Box>
              <Box>
                <StyledInput
                  name="password"
                  label="Password"
                  touched={touched}
                  errors={errors}
                  type={showPassword ? "text" : "password"}
                  togglePassword={() => setShowPassword(!showPassword)}
                />
              </Box>
              <Box>
                <Stack direction="row" alignItems="center" justifyContent="end" spacing={1}>
                  <Link to='/admin/forgotpassword'>
                    <Typography variant="subtitle1" className="forgotLink" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                      Forgot Password?
                    </Typography>
                  </Link>
                </Stack>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" type="submit" className="add-btn" fullWidth disabled={loading}>
                  {loading ? "Loading..." : "SIGN IN"}
                </Button>
              </Box>
              {/* {authDataRes.error && (
                <Box className="alert-danger">
                  {authDataRes.error}
                </Box>
              )} */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
