import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './StyledInput.css'; // Import your custom CSS file

const StyledInput = ({ name, value, label, touched, togglePassword, errors, labelStyle, autoComplete, ...rest }) => {
  const isPassword = name.toLowerCase().includes('password');
  return (
    <div className={`Input-field custom-styled-input ${touched[name] && errors[name] ? 'error-input' : ''}`} style={{ marginTop: '12px' }}>
      <Field
        as={TextField}
        name={name}
        label={label}
        variant="outlined"
        size="small"
        fullWidth
        type={isPassword && !rest.type ? 'password' : 'text'}
        autoComplete={autoComplete}
        {...rest}
        InputProps={{
          endAdornment: isPassword && (
            <InputAdornment position="end">
              <IconButton
                onClick={togglePassword}
                edge="end"
              >
                {rest.type == "text" ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        // InputLabelProps={{
        //   style: labelStyle || { fontSize: '14px' },
        //   classes: {
        //     root: 'custom-color',
        //   },
        // }}
        classes={{
          root: 'custom-styled-input',
          outlined: 'custom-fieldset-color',
        }}
      />
      <ErrorMessage name={name} component="div" className="error-message" />
    </div>
  );
};

export default StyledInput;
