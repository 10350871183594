// reducer.js

import { FETCH_EMAIL_TEMPLATE_SUCCESS,DELETE_EMAIL_TEMPLATE_SUCCESS,UPDATE_EMAIL_TEMPLATE_SUCCESS,GET_TEMPLATE_DATA_SUCCESS} from './actionTypes';

const initialState = {
    templateRows : [],
    getTemplateData:null
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateRows: action.payload,
      };
    case  DELETE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateRows: state.templateRows.filter(template => template._id !== action.payload),
      };
      case GET_TEMPLATE_DATA_SUCCESS:
        return {
          ...state,
          getTemplateData: action.payload,
        };
      case UPDATE_EMAIL_TEMPLATE_SUCCESS:
        const updatedTemplate = action.payload;
        let updatedTemplateRows = state.templateRows.map(template => {
          if (template._id === updatedTemplate._id) {
            return updatedTemplate;
          }
          return template;
        });
          return {
            ...state,
            templateRows: updatedTemplateRows,
          };
         
      
       
    default:
      return state;
  }
};

export default templateReducer;
