
export const ACTIVITY_FILTER_CHANGE = 'ACTIVITY_FILTER_CHANGE';



export const filterChange = (fieldValue) => {
    return ({
        type: ACTIVITY_FILTER_CHANGE,
        payload: fieldValue,
    })
};