

import { deleteEmailLog, getEmailLogReport, getEmailReportsByClientId, getEmailReportsByUserId, getViewEmailLog, updateEmailType } from '../../api/emailLogApi';
import { FETCH_VIEW_EMAIL_LOG_SUCCESS, FETCH_EMAIL_LOG_SUCCESS, FETCH_EMAIL_LOG_BY_CLIENT_SUCCESS, FETCH_EMAIL_LOG_BY_USER_SUCCESS, DELETE_EMAIL_LOG_SUCCESS, EMAIL_LOG_FILTER_CHANGE, UPDATE_EMAIL_TYPE_SUCCESS } from './actionTypes'

export const filterChange = (fieldValue) => {
  return ({
    type: EMAIL_LOG_FILTER_CHANGE,
    payload: fieldValue,
  })
};
export const fetchEmailLogSuccess = (emailLog) => ({
  type: FETCH_EMAIL_LOG_SUCCESS,
  payload: emailLog,
});
export const fetchViewEmailLogDetails = (emailLog) => ({
  type: FETCH_VIEW_EMAIL_LOG_SUCCESS,
  payload: emailLog,
});
export const getEmailClientSuccess = (reportsOfClient) => ({
  type: FETCH_EMAIL_LOG_BY_CLIENT_SUCCESS,
  payload: reportsOfClient,
});
export const getEmailUserSuccess = (reportsOfUser) => ({
  type: FETCH_EMAIL_LOG_BY_USER_SUCCESS,
  payload: reportsOfUser,
});
export const updateEmailTypeSuccess = (id, value) => ({
  type: UPDATE_EMAIL_TYPE_SUCCESS,
  payload: {id,value},
});
export const deleteEmailLogsSuccess = (id) => ({
  type: DELETE_EMAIL_LOG_SUCCESS,
  payload: id,
})
export const getReports = () => {
  return async (dispatch) => {
    const reports = await getEmailLogReport();
    dispatch(fetchEmailLogSuccess(reports));
    return reports;
  };
};
export const getEmailReportsOfClient = (clientId, dataToFilter) => async (dispatch) => {
  try {
    const reportsOfClient = await getEmailReportsByClientId(clientId, dataToFilter);
    dispatch(getEmailClientSuccess(reportsOfClient));
    return reportsOfClient;
  } catch (error) {
    dispatch({ type: 'EMAIL_REPORTS_FAILURE', payload: error.message });
    throw error;
  }
};

export const getEmailLogDetails = (id) => async (dispatch) => {
  const reports = await getViewEmailLog(id);
  dispatch(fetchViewEmailLogDetails(reports));
  return reports;
}

export const deleteEmailLogs = (clientDetails) => async (dispatch) => {
  deleteEmailLog(clientDetails);
  dispatch(deleteEmailLogsSuccess(clientDetails));
}

export const getEmailReports = (dataToFilter) => async (dispatch) => {
  try {
    const reportsOfClient = await getEmailLogReport(dataToFilter);
    dispatch(getEmailClientSuccess(reportsOfClient));
    return reportsOfClient;
  } catch (error) {
    dispatch({ type: 'EMAIL_REPORTS_FAILURE', payload: error.message });
    throw error;
  }
};


export const getEmailReportsOfUser = (userId) => async (dispatch) => {
  try {
    const reportsOfUser = await getEmailReportsByUserId(userId);
    dispatch(getEmailUserSuccess(reportsOfUser));
    return reportsOfUser;
  } catch (error) {
    dispatch({ type: 'EMAIL_REPORTS_FAILURE', payload: error.message });
    throw error;
  }
};

export const changeEmailType = (id, value) => async (dispatch) => {
  try {
    await updateEmailType(id, value);
    dispatch(updateEmailTypeSuccess(id, value.emailType));
  } catch (error) {
    dispatch({ type: 'EMAIL_REPORTS_FAILURE', payload: error.message });
    throw error;
  }
}