import React from 'react';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';

const ButtonGroup = ({ onCancel, onSave, isEditing, isValid }) => {
  const buttonStyle = {
    backgroundColor: 'red',
    color: 'white',
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} sx={{ marginTop: '20px' }}>
      <Button variant="contained" style={buttonStyle} onClick={onCancel}>
        Cancel
      </Button>
      <Button type="submit" variant="contained" color="primary" onClick={onSave}>
        {isEditing ? 'Save' : 'Add'}
      </Button>
    </Stack>
  );
};

export default ButtonGroup;
