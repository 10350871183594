import React, { useEffect, useState } from 'react';
import { AppBar, Box, CssBaseline, Toolbar, styled } from '@mui/material';
import AppHeader from '../MainLayout/AppHeader';
import AppSidebar from '../MainLayout/AppSidebar';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import useResponsive from '../../hooks/useResponsive';

const MainLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const location = useLocation();
  const isLoginPage = location.pathname === '/admin/login' || location.pathname === '/forgotpassword';
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    toggleSidebar();
  }, []);
  const isDesktop = useResponsive('up', 'lg');
  return (
    <Box sx={{ display: 'flex', height: '100%', scrollBehavior: 'none', overflow: "hidden", backgroundColor: "#E5E5E5" }}>
      {!isDesktop && <Header />}
      {/* header */}
      {/* {!isLoginPage && (
        <AppBar
          position="fixed"
          sx={{
            backgroundColor:'#fff',
            zIndex: 9999,
            boxShadow:'none'
          }}
        >
          <Toolbar>
            <AppHeader handleSidebarToggle={toggleSidebar}/>
          </Toolbar>
        </AppBar>
      )} */}

      {/* {!isLoginPage && (
        <AppSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )} */}
      <Sidebar />
      {/* main content */}
      <Box
        sx={{
          // width: isSidebarOpen ? '80%' : 'calc(100% - 70px)',
          flexGrow: 1,
          padding: '14px',
          paddingLeft: isDesktop ? '20px' : '60px',
          overflow: 'auto',
          background: '#eee',
          transition: 'padding-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          // marginLeft: isSidebarOpen ? '255px' : '54px',
          display: isLoginPage ? 'flex' : '',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
