import axiosInstance from '../axios'

import { API_BASE_URL } from '../../config/index'

export const getAllDashBoardData = async (data) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/dashboard/getDashboard`, data)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching getAllDashBoardData:', error);
    return [];
  }
};