import { getFollowUpLogReport } from "../../api/emailLogApi";

export const FETCH_FOLLOW_UP_LOG_SUCCESS = "FETCH_FOLLOW_UP_LOG_SUCCESS";

export const fetchFollowUpLogSuccess = (emailLog) => ({
    type: FETCH_FOLLOW_UP_LOG_SUCCESS,
    payload: emailLog,
});

export const getFollowUpReports = (data) => {
    return async (dispatch) => {
        const reports = await getFollowUpLogReport(data);
        dispatch(fetchFollowUpLogSuccess(reports));
        return reports;
    };
};