import React from "react";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, Autocomplete, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import './SelectComponent.css';

const useStyles = makeStyles((theme) => ({
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&.MuiSelect-select": {
      color: "grey",
      fontSize: '15px',
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&.error-input .MuiOutlinedInput-notchedOutline": {
      borderColor: "red!important",
    }
  },
}));

const SelectComponent = ({
  label,
  onSelecteChange,
  items,
  name,
  errors,
  value,
  multiple,
  labelError,
  helperText,
  className,
  searchable,
  disableHelperText,
  defaultValue,
  singleValue,
  disabledText,
  ...rest
}) => {
  const inputLabel = React.useRef(null);
  const classes = useStyles();
  const containerStyle = {
    width: "100%"
  };

  const isError = labelError && labelError !== "";

  return (
    <FormControl variant="outlined" fullWidth className={`${isError ? 'error-input' : ''} ${className || ''}`} >
      {
        !searchable
          ?
          <>
            <InputLabel ref={inputLabel} id={`select-${label}`} style={{ fontSize: '15px' }}>
              {label}
            </InputLabel>
            <Select
              className={`${classes.select} ${isError ? 'error-input' : ''} custom-styled-input`}
              label={label}
              labelId={`select-${label}`}
              name={name}
              onChange={onSelecteChange}
              value={value}
              multiple={Boolean(multiple)}
              {...rest}
              style={containerStyle}
            >
              {items?.length > 0 ?
                items?.map((item) => (
                  <MenuItem value={singleValue ? item : item.value} key={singleValue ? item : item.value}>
                    {singleValue ? item : item.name}
                  </MenuItem>
                ))
                :
                <MenuItem sx={{ fontSize: '13px' }} value='' disabled>{disabledText}</MenuItem>
              }
            </Select>
          </>
          :
          <>
            <Autocomplete
              className="custom-styled-input center-label"
              disablePortal
              defaultValue={defaultValue}
              id={`select-${label}`}
              options={[...items]}
              value={value}
              renderInput={(params) => <TextField {...params} label={label} />}
              onChange={onSelecteChange}
              {...rest}
            />
          </>
      }
      {
        !disableHelperText &&
        <FormHelperText
          sx={{ color: isError ? "red" : "inherit", marginLeft: "0px", marginTop: '10px' }}
        >
          {isError ? labelError : helperText}
        </FormHelperText>
      }
    </FormControl>
  );
};

export default SelectComponent;
