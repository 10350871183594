
import { scheduleEmailToClient, getScheduledMailLog, getDataByScheduleId, updateScheduleMail } from '../../api/scheduleMailApi';
export const SCHEDULE_EMAIL_SUCCESS = 'SCHEDULE_EMAIL_SUCCESS'
export const FETCH_SCHEDULE_EMAIL_LOG_SUCCESS = 'FETCH_SCHEDULE_EMAIL_LOG_SUCCESS';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_DATA_SUCCESS = 'GET_SCHEDULE_DATA_SUCCESS';
export const UPDATE_SCHEDULE_DATA_SUCCESS = 'UPDATE_SCHEDULE_DATA_SUCCESS'

export const emailScheduleSuccess = (status) => ({
  type: SCHEDULE_EMAIL_SUCCESS,
  payload: status,
})

export const fetchScheduleEmailLogSuccess = (scheduleLog) => ({
  type: FETCH_SCHEDULE_EMAIL_LOG_SUCCESS,
  payload: scheduleLog,
});

export const deleteScheduleMailSuccess = (scheduleLog) => ({
  type: DELETE_SCHEDULE_SUCCESS,
  payload: scheduleLog,
});

export const getScheduleDataSuccess = (scheduleLog) => ({
  type: GET_SCHEDULE_DATA_SUCCESS,
  payload: scheduleLog,
});

export const updateScheduleDataSuccess = (mailData) => ({
  type: UPDATE_SCHEDULE_DATA_SUCCESS,
  payload: mailData,
});


export const scheduleEmail = (data) => {
  return async () => {
    try {
      const status = await scheduleEmailToClient(data);
      return status;
    } catch (error) {
      return error;
    }
  };
}

export const getScheduleAll = () => {
  return async (dispatch) => {
    const reports = await getScheduledMailLog();
    dispatch(fetchScheduleEmailLogSuccess(reports));
    return reports;
  };
};

export const getScheduleMailById = (mailId) => {
  return async (dispatch) => {
    const mail = await getDataByScheduleId(mailId);
    dispatch(getScheduleDataSuccess(mail.data));
    return mail.data;
  };
};

export const updateScheduleEmail = (id, data) => {
  return async (dispatch) => {
    const mail = await updateScheduleMail(id, data);
    dispatch(updateScheduleDataSuccess(mail.data));
    return mail;
  };
};