import React from 'react';
import Chart from 'react-apexcharts';

function ApexCharts({ chartData, className, height, width, type }) {
    return (
        <Chart
            options={chartData?.options}
            series={chartData?.series}
            type={type}
            height={height}
            width={width}
            className={className}
        />
    )
}

export default ApexCharts