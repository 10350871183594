import { forgotPasswordUser } from '../../api/userApi'
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const forgotPassword = (email) => {
    return async (dispatch) => {
        try {
            const forgotPasswordEmail = await forgotPasswordUser(email);
            dispatch(forgotPasswordSuccess(forgotPasswordEmail));
            return forgotPasswordEmail;
        } catch (error) {
            console.error("Forgot password failed:", error);
            return { success: false, error };

        }
    };
}


export const forgotPasswordSuccess = (user) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: user,
});
