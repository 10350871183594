// reducer.js

import { FETCH_FOLLOW_UP_LOG_SUCCESS } from "./action";

const initialState = {
    emailLogRows: [],
};

const followUpLog = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FOLLOW_UP_LOG_SUCCESS:
            return {
                ...state,
                emailLogRows: action.payload,
            };
        default:
            return state;
    }
};

export default followUpLog;
