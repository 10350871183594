import React from "react";
import "./style.css";
import Routing from './Routing'
import "./App.css";
import store from './store';
import { Provider } from 'react-redux';

const App = () => {
  return (

    <Provider store={store}>
      <Routing />
    </Provider>
  );
};
export default App;