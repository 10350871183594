import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import StyledInput from "../../components/StyledInput";
import SelectComponent from "../../components/SelectComponent";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createNewUser } from '../../api/userApi';
import { createTechnologySuccess, createUsersSuccess, getTechnologyById, updateTechnology, updateTechnologySuccess, updateUsers, updateUsersSuccess } from "./action";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ButtonGroup from "../../components/ButtonGroup";
import StyledPhoneInput from '../../components/StyledPhoneInput'
import { formatPhoneNumber } from '../../utils/phoneUtils';
import { SpinnerCircular } from 'spinners-react';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { createNewTech } from "../../api/technologyApi";


const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Technology Name is required")
        .strict(true)
        .trim('No leading or trailing spaces allowed.')
        .matches(/^[a-zA-Z0-9@#$%^&*:,.]+(?:\s+[a-zA-Z0-9@#$%^&*:,.]+)*$/, 'Invalid characters are not allowed.')
        .max(30, "Too Long!")
});


const AddTechnology = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const editId = query.get("edit");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("")


    const defaultFormValues = {
        name: "",
    };

    const fetchTechnologyToEdit = async () => {
        try {
            setLoading(true)
            await dispatch(getTechnologyById(editId))
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        if (editId) {
            fetchTechnologyToEdit();
        }
    }, [])


    const isEditMode = !!editId;
    const technologyToEdit = useSelector((state) => state.technologies.techData);
    const initialValues = isEditMode ? technologyToEdit || defaultFormValues : defaultFormValues;


    const handleSubmitTechnology = async (values) => {
        if (error) {
            return;
        }
        try {
            if (editId && technologyToEdit) {
                setLoading(true)
                const updatedTech = await dispatch(updateTechnology(editId, values));
                if (updatedTech?.success) {
                    dispatch(updateTechnologySuccess(updatedTech));
                    setLoading(false)
                    navigate('/admin/technology');
                    toast.success('Technology Updated successfully!', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                else {
                    toast.error(updatedTech?.response?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setLoading(false)
                }
            } else {

                try {

                    const response = await createNewTech(values);
                    if (response.success) {
                        toast.success('Technology Created successfully!', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });

                        dispatch(createTechnologySuccess(response));
                        navigate('/admin/technology');
                    }
                    else {
                        toast.error(response?.response?.data?.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } catch (error) {
                    setLoading(false)
                    toast.error(error.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                }
            }
        } catch (error) {
            setLoading(false)
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            console.error("Error creating/updating Technology:", error);
        }


    };


    return (
        <>
            {loading ?
                (
                    <div className="spinner-container">
                        <SpinnerCircular color="#007BFF" size={80} thickness={100} speed={100} secondaryColor="rgba(0, 0, 0, 0.25)" />
                    </div>
                ) : (

                    <div className="add-user-form user">
                        <h2 className="form-subheading">{isEditMode ? 'Edit Technology' : 'Add Technology'}</h2>
                        <div className="form-wrapper">
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(e) => { handleSubmitTechnology(e) }}
                            >
                                {({ touched, errors, values, setFieldValue, isValid, dirty, handleSubmit }) => {

                                    return (

                                        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(values) }}>
                                            <StyledInput
                                                name="name"
                                                label="Name"
                                                touched={touched}
                                                errors={errors}
                                                value={values.name}
                                            />

                                            <ButtonGroup onCancel={() => navigate('/admin/technology')} isEditing={!!editId}
                                            />

                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                )}
        </>
    );
};
export default AddTechnology;