// api.js

import axiosInstance from '../axios'

import { API_BASE_URL } from '../../config/index'

export const getAllTemplate = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/emailTemplate/getAll`)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching email Template:', error);
    return [];
  }
};


export const createNewTemplate = async (data) => {

  try {
    const res = await axiosInstance.post(`${API_BASE_URL}/emailTemplate/create`, data)
    return res.data;
  } catch (error) {
    console.error('Error creating email Template:', error);
    return error.response.data;
  }
}


export const deleteTemplate = async (id) => {
  try {
    const res = await axiosInstance.delete(`${API_BASE_URL}/emailTemplate/delete/${id}`);
    return res.data;
  } catch (error) {
    console.error('Error deleting email Template:', error);
    return null;
  }
}


export const editTemplate = async (id, data) => {
  try {
    const res = await axiosInstance.put(`${API_BASE_URL}/emailTemplate/Update/${id}`, data);
    return res.data;
  } catch (error) {
    console.error('Error updating email Template:', error);
    return null;
  }
};

export const getDataByTemplateId = async (templateId) => {
  try {
    const res = await axiosInstance.get(`${API_BASE_URL}/emailTemplate/get/${templateId}`);
    return res.data;
  } catch (error) {
    console.error("Error in getDataByTemplateId:", error); // Log the error

  }
}