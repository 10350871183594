import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ListItemText, Stack } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import useResponsive from "../../../hooks/useResponsive";
import { toggleSidebar } from "../../../pages/Dashboard/action";

export const NavItem = ({ item }) => {
  const { title, path, icon, info } = item;
  const [menuName, setMenuName] = useState("");
  const dispatch = useDispatch();
  const isDesktop = useResponsive("up", "lg");
  const handleNavClicked = (event) => {
    if (!isDesktop) {
      dispatch(toggleSidebar());
    }
  };
  const isNavExpanded = useSelector((state) => state.dashboard.isNavExpanded);

  return (
    <StyledNavItem
      onClick={handleNavClicked}
      component={path && RouterLink}
      to={path}
      sx={{
        mr: "4px",
        py: 1,
        height: "40px",
        "&.active": {
          bgcolor: "rgb(0 122 254 / 20%)",
          color: '#007AFE',
          fontWeight: "fontWeightBold",
          "& .css-i4bv87-MuiSvgIcon-root": {
            color: "white",
          },
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      {isNavExpanded && (
        <>
          <ListItemText disableTypography primary={title} />
          {item.children && (
            <Stack>
              {menuName !== item.title ? (
                <KeyboardArrowRightIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Stack>
          )}
          {info && info}
        </>
      )}
    </StyledNavItem>
  );
};
