import React, { useEffect, useRef, useState } from "react";
import { Box, FormControl, Grid, IconButton, Tab, Tabs, alpha } from "@mui/material";
import DashboardCard from "./dashboardCard";
import { clearDashboardChartData, fetchData } from "./action";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { format, addDays } from "date-fns";
import "./dashboardCard.css";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SelectComponent from "../../components/SelectComponent";
import DateRangeField from "../../components/DateRangeField";
import useResponsive from "../../hooks/useResponsive";
import ApexCharts from "../../components/ApexChart";
import UserTable from "../../components/UserTable";
import PopUp from "../../components/Popup";
import SendEmail from "../SendEmail";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboardData, isLoading } = useSelector((state) => state.dashboard);
  const [tabValue, setTabValue] = useState('FirstFollowUp');
  const [loader, setLoader] = useState(false);
  const [followUpLoader, setFollowUpLoader] = useState(false);
  const [zoomInChart, setZoomInChart] = useState({
    type: '',
    show: false,
    data: '',
    title: ''
  });
  const [openFollowUpEmailPopup, setOpenFollowUpEmailPopup] = useState({
    show: false,
    id: ''
  })
  const [dateRangeValue, setDateRangeValue] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const customLegendFormatter = (seriesName, opts) => {
    return `
          <div class="legend-item-label">${seriesName}</div>
          <div class="legend-item-value">${opts.w.globals.series[opts.seriesIndex]}</div>
      `;
  };
  const [followUpChartData, setFollowUpChartData] = useState({
    nonCumulativeFollowUpPieChart: {

      series: [],
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              total: {
                show: true,
                label: 'Total',
              }
            }
          }
        },
        labels: [],
      },
    },
    followUpPieChartData: {
      series: [],
      options: {
        labels: [],
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true, custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let total = 0; for (let x of series) { total += x; } let selected = series[seriesIndex];
            return w.config.labels[seriesIndex] + ": " + selected + " ( " + (selected / total * 100).toFixed(2) + "% )";
          }
        },
        colors: [],
        legend: {
          position: 'bottom',
          formatter: customLegendFormatter,
          onItemHover: {
            highlightDataSeries: true
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              formatter: function (val, opts) {
                let total = 0;
                opts.series.forEach(function (series) {
                  total += series;
                });
                let percentage = (val / total * 100).toFixed(2) + "%";
                return opts.w.globals.labels[opts.seriesIndex] + ": " + percentage;
              }
            }
          }
        }
      }
    },
    followUpBarChartData: {
      series: [],
      options: {
        colors: [],
        chart: {
          id: "basic-bar",
          type: 'bar',
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
      },
    }
  })
  const [chartData, setChartData] = useState({
    nonCumulativeEmailPieChart: {

      series: [],
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              total: {
                show: true,
                label: 'Total',
              }
            }
          }
        },
        labels: [],
      },
    },
    emailPieChartData: {
      series: [],
      options: {
        labels: [],
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true, custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let total = 0; for (let x of series) { total += x; }
            let selected = series[seriesIndex];
            return w.config.labels[seriesIndex] + ": " + selected + " ( " + (selected / total * 100).toFixed(2) + "% )";
          }
        },
        colors: [],
        legend: {
          position: 'bottom',
          formatter: customLegendFormatter,
          onItemHover: {
            highlightDataSeries: true
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              formatter: function (val, opts) {
                let total = 0;
                opts.series.forEach(function (series) {
                  total += series;
                });
                let percentage = (val / total * 100).toFixed(2) + "%";
                return opts.w.globals.labels[opts.seriesIndex] + ": " + percentage;
              }
            }
          }
        }
      }
    },
    clientLineChartData: {
      series: [],
      options: {
        chart: {
          type: "line",
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        colors: [],
        stroke: {
          width: [4, 4],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: "20%"
          }
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#ccc",
            },
          }
        ],
      }
    },
    emailBarChartData: {
      series: [],
      options: {
        colors: [],
        chart: {
          id: "basic-bar",
          type: 'bar',
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
      },
    },
  });

  const refOne = useRef(null);
  const [dateValue, setDateValue] = useState();
  const [open, setOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [simpleDateRangeValue, setSimpleDateRangeValue] = useState({
    from: "",
    to: "",
  });
  const [activeFilter, setActiveFilter] = useState("Last 7 days");
  const dateOptions = [
    { name: "All", value: 100 },
    { name: "Today", value: 0 },
    { name: "Yesterday", value: -1 },
    { name: "Last 7 days", value: -7 },
    { name: "Custom", value: 1 },
  ];
  const fetchDashboardData = async (date) => {
    setTabValue('FirstFollowUp');
    await dispatch(fetchData(date));
    setTimeout(() => {
      setLoader(false)
    }, 1500);
  };

  const clearDashboardData = () => {
    dispatch(clearDashboardChartData())
  }

  const handleDateChange = (e, type) => {
    if (type === "buttonClick") {
      setDateValue(e?.target?.value);
    } else {
      // setDateValue(e.target);
      setDateValue(e?.target?.value);
    }
  };
  const handleDateRangeChange = (e) => {
    setDateRangeValue([e.selection]);
  };
  const isMobile = useResponsive('down', 1024);
  const toggleDropDown = () => {
    if (window.innerWidth < 1024) {
      setShowDropDown(!showDropDown);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1024) {
      toggleDropDown();
    }
  }, []);

  useEffect(() => {
    if (dateRangeValue) {
      if (dateValue?.value || parseInt(dateValue) === 100) {
        setSimpleDateRangeValue({
          from: dayjs(new Date('2023-12-01')).startOf('day').format("YYYY-MM-DD"),
          to: dayjs(new Date()).startOf('day').format("YYYY-MM-DD"),
          isAll: true
        });
      } else {
        if (parseInt(dateValue) === 1) {
          if (dateRangeValue[0].startDate !== dateRangeValue[0].endDate) {

            setSimpleDateRangeValue({
              from: dayjs(dateRangeValue[0].startDate).startOf("day").format("YYYY-MM-DD"),
              to: dayjs(dateRangeValue[0].endDate).startOf("day").format("YYYY-MM-DD"),
              isAll: false
            });
            setOpen(false)
          }
        }
        else {
          setSimpleDateRangeValue({
            from: dayjs(dateRangeValue[0].startDate).startOf("day").format("YYYY-MM-DD"),
            to: dayjs(dateRangeValue[0].endDate).startOf("day").format("YYYY-MM-DD"),
            isAll: false
          });
        }
      }
    }
  }, [dateRangeValue]);

  useEffect(() => {
    setSimpleDateRangeValue({
      isAll: false,
      from: dayjs(new Date()).subtract(6, 'day').startOf('day').format("YYYY-MM-DD"),
      to: dayjs(new Date()).startOf('day').format("YYYY-MM-DD"),
    });
  }, [])

  const getTime = (value) => {
    const today = dayjs();
    const time = { startDate: new Date(), endDate: new Date() }
    if (value === 0) {
      time.startDate = today.startOf("day").format("YYYY-MM-DD")
      time.endDate = today.startOf("day").format("YYYY-MM-DD")
    } else if (value === -1) {
      time.startDate = today.subtract(1, 'day').startOf('day').format("YYYY-MM-DD")
      time.endDate = today.subtract(1, 'day').startOf('day').format("YYYY-MM-DD")
    } else if (value === -7) {
      time.startDate = today.subtract(6, 'day').startOf('day').format("YYYY-MM-DD")
      time.endDate = today.startOf("day").format("YYYY-MM-DD")
    }
    return time
  }
  useEffect(() => {
    if (dateValue || dateValue === 0) {
      const value = dateValue?.value || parseInt(dateValue);
      if (value !== 1) {
        setDateRangeValue(
          dateRangeValue.map((v, index) =>
            index === 0
              ? {
                ...getTime(value),
                key: "selection",
              }
              : dateValue
          )
        );
      }
    }
  }, [dateValue]);

  useEffect(() => {
    if (simpleDateRangeValue && simpleDateRangeValue.from && simpleDateRangeValue.to) {
      setLoader(true);
      clearDashboardData()
      fetchDashboardData(simpleDateRangeValue);
    }
  }, [simpleDateRangeValue]);

  useEffect(() => {
    if (dashboardData && dashboardData?.CumulativeEmailPieChart && dashboardData?.EmailBarChart && dashboardData?.NonCumulativeEmailPieChart) {
      const followUpsum = dashboardData?.FollowUpData?.FirstFollowUp?.NonCumulativePieChart?.series.reduce((acc, num) => acc + num, 0);
      const followUpSeries = dashboardData?.FollowUpData?.FirstFollowUp?.NonCumulativePieChart?.series.map(value => isNaN((value / followUpsum) * 100) ? 0 : Math.round((value / followUpsum) * 100));
      const emailSum = dashboardData?.NonCumulativeEmailPieChart?.series.reduce((acc, num) => acc + num, 0);
      const emailSeries = dashboardData?.NonCumulativeEmailPieChart?.series.map(value => isNaN((value / emailSum) * 100) ? 0 : parseInt(((value / emailSum) * 100)));

      setChartData({
        emailPieChartData: {
          ...chartData.emailPieChartData,
          series: dashboardData?.CumulativeEmailPieChart?.series,
          options: {
            ...chartData.emailPieChartData.options,
            labels: dashboardData?.CumulativeEmailPieChart?.labels,
            colors: dashboardData?.CumulativeEmailPieChart?.colors
          }
        },
        clientLineChartData: {
          ...chartData.clientLineChartData,
          series: dashboardData?.ClientLineChart?.series,
          options: {
            ...chartData.clientLineChartData.options,
            xaxis: {
              categories: [...dashboardData?.ClientLineChart?.categories]
            },
            colors: dashboardData?.ClientLineChart?.colors
          }
        },
        emailBarChartData: {
          ...chartData.emailBarChartData,
          series: dashboardData?.EmailBarChart?.series,
          options: {
            ...chartData.emailBarChartData.options,
            xaxis: {
              categories: dashboardData?.EmailBarChart?.categories
            },
            colors: dashboardData?.EmailBarChart?.colors
          }
        },
        nonCumulativeEmailPieChart: {
          ...chartData.nonCumulativeEmailPieChart,
          series: emailSeries,
          options: {
            ...chartData?.nonCumulativeEmailPieChart?.options,
            colors: dashboardData?.NonCumulativeEmailPieChart?.colors,
            labels: dashboardData?.NonCumulativeEmailPieChart?.labels,
            plotOptions: {
              radialBar: {
                dataLabels: {
                  ...chartData?.nonCumulativeEmailPieChart?.options.plotOptions.radialBar.dataLabels,
                  value: {
                    fontSize: '16px',
                    formatter: function (val, opts) {
                      const index = opts.config.series.indexOf(parseInt(val));
                      return dashboardData?.NonCumulativeEmailPieChart?.series[index]
                    }
                  },
                  total: {
                    show: true,
                    label: 'Total',
                    formatter: function (w) {
                      return emailSum
                    }
                  }
                }
              }
            },
          }
        },
      })
      setFollowUpChartData({
        followUpPieChartData: {
          ...followUpChartData.followUpPieChartData,
          series: dashboardData?.FollowUpData?.FirstFollowUp?.CumulativePieChart?.series,
          options: {
            ...followUpChartData?.followUpPieChartData?.options,
            labels: dashboardData?.FollowUpData?.FirstFollowUp?.CumulativePieChart?.labels,
            colors: dashboardData?.FollowUpData?.FirstFollowUp?.CumulativePieChart?.colors
          }
        },
        nonCumulativeFollowUpPieChart: {
          ...followUpChartData.nonCumulativeFollowUpPieChart,
          series: followUpSeries,
          options: {
            ...followUpChartData?.nonCumulativeFollowUpPieChart?.options,
            colors: dashboardData?.FollowUpData?.FirstFollowUp?.NonCumulativePieChart?.colors,
            labels: dashboardData?.FollowUpData?.FirstFollowUp?.NonCumulativePieChart?.labels,
            plotOptions: {
              radialBar: {
                dataLabels: {
                  ...followUpChartData?.nonCumulativeFollowUpPieChart?.options.plotOptions.radialBar.dataLabels,
                  value: {
                    fontSize: '16px',
                    formatter: function (val, opts) {
                      const index = opts.config.series.indexOf(parseInt(val));
                      return dashboardData?.FollowUpData?.FirstFollowUp?.NonCumulativePieChart?.series[index]
                    }},
                  total: {
                    show: true,
                    label: 'Total',
                    formatter: function (w) {
                      return followUpsum
                    }
                  }
                }
              }
            },
          }
        },
        followUpBarChartData: {
          ...followUpChartData.followUpBarChartData,
          series: dashboardData?.FollowUpData?.FirstFollowUp?.Barchart?.series,
          options: {
            ...followUpChartData.followUpBarChartData.options,
            xaxis: {
              categories: dashboardData?.FollowUpData?.FirstFollowUp?.Barchart?.categories,
            },
            colors: dashboardData?.FollowUpData?.FirstFollowUp?.Barchart?.colors,
          }
        },
      })
    }
  }, [dashboardData])

  const handleTabChange = async(e, newValue) => {
    setFollowUpLoader(true);
    setTabValue(newValue)
    const followUpsum = dashboardData?.FollowUpData[newValue]?.NonCumulativePieChart?.series.reduce((acc, num) => acc + num, 0);
    const followUpSeries = dashboardData?.FollowUpData[newValue]?.NonCumulativePieChart?.series.map(value => isNaN((value / followUpsum) * 100) ? 0 : Math.round((value / followUpsum) * 100));
    const data = {
      nonCumulativeFollowUpPieChart: {
        ...followUpChartData.nonCumulativeFollowUpPieChart,
        series: followUpSeries,
        options: {
          ...followUpChartData?.nonCumulativeFollowUpPieChart?.options,
          colors: dashboardData?.FollowUpData[newValue]?.NonCumulativePieChart?.colors,
          labels: dashboardData?.FollowUpData[newValue]?.NonCumulativePieChart?.labels,
          plotOptions: {
            radialBar: {
              dataLabels: {
                ...followUpChartData?.nonCumulativeFollowUpPieChart?.options.plotOptions.radialBar.dataLabels,
                value: {
                  fontSize: '16px',
                  formatter: function (val, opts) {
                    const index = opts.config.series.indexOf(parseInt(val));
                    return dashboardData?.FollowUpData[newValue]?.NonCumulativePieChart?.series[index]
                  },
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return followUpsum
                  }
                }
              }
            }
          },
        }
      },
      followUpBarChartData: {
        ...followUpChartData.followUpBarChartData,
        series: dashboardData?.FollowUpData[newValue]?.Barchart?.series,
        options: {
          ...followUpChartData.followUpBarChartData.options,
          xaxis: {
            categories: dashboardData?.FollowUpData[newValue]?.Barchart?.categories,
          },
          colors: dashboardData?.FollowUpData[newValue]?.Barchart?.colors,
        }
      },
      followUpPieChartData: {
        ...followUpChartData.followUpPieChartData,
        series: dashboardData?.FollowUpData[newValue]?.CumulativePieChart?.series,
        options: {
          ...followUpChartData?.followUpPieChartData?.options,
          labels: dashboardData?.FollowUpData[newValue]?.CumulativePieChart?.labels,
          colors: dashboardData?.FollowUpData[newValue]?.CumulativePieChart?.colors
        }
      },
    }
    await setFollowUpChartData({...data})
    setFollowUpLoader(false)
  }

  return (
    <>
      <Box className="d-flex dashboard-filter-wrapper" sx={{ mb: 3, mt: 2 }}>
        <h2 className="block-heading dashboard">Dashboard</h2>

        <Box className="dashbaord-filter">
          {isMobile ? (
            <FormControl sx={{ width: "46%", maxWidth: "240px" }}>
              <SelectComponent
                menuName={"label"}
                menuValue={"value"}
                labelId="date-label"
                label="Date"
                inputLabel="Date"
                disableHelperText
                items={dateOptions}
                sx={{ width: "100%", marginTop: '0px !important' }}
                name="date"
                defaultValue={-7}
                value={dateValue}
                onChange={handleDateChange}
              />
            </FormControl>
          ) : (
            <div className="dashbaord-filter-wrapper">
              {dateOptions?.map((item) => (
                <button
                  value={item.value}
                  onClick={(e) => { handleDateChange(e, "buttonClick"); setActiveFilter(item.name) }}
                  className={`filter-btn ${item.name === activeFilter ? 'active' : ''}`}
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}
          {dateValue?.value || parseInt(dateValue) === 1 ? (
            <FormControl
              sx={{ width: "54%", marginLeft: "12px", maxWidth: "230px" }}
            >
              <DateRangeField
                state={dateRangeValue}
                handleChange={handleDateRangeChange}
                open={open}
                openCalender={setOpen}
                dateRef={refOne}
                label={"Select Date Range"}
                sx={{
                  width: "100%",
                }}
              />
            </FormControl>
          ) : (
            ""
          )}
        </Box>
      </Box>
      {loader ? (
        <div className="spinner-container">
          <SpinnerCircular
            color="#007BFF"
            size={80}
            thickness={100}
            speed={100}
            secondaryColor="rgba(0, 0, 0, 0.25)"
          />
        </div>
      ) : (
        <div className="dashboard-container">
          <div className="row">
            <div className="col big">
              <div className="col-head">
                <h3 className="col-heading">Email Summary</h3>
                <IconButton onClick={() => setZoomInChart({
                  type: 'bar',
                  show: true,
                  data: chartData?.emailBarChartData,
                  title: 'Email Logs'
                })}>
                  <ZoomInIcon sx={{ fontSize: '22px' }} color='primary' />
                </IconButton>
              </div>
              <div style={{ height: 'calc(100% - 32px)' }}>
                <ApexCharts style={{ minHeight: "450px !important" }} type='bar'
                  height='100%'
                  width='100%'
                  // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                  chartData={chartData?.emailBarChartData} />
              </div>
            </div>
            <div className="col">
              <div className="col-head">
                <h3 className="col-heading">Cumulative Email Summary</h3>
                <IconButton onClick={() => setZoomInChart({
                  type: 'pie',
                  show: true,
                  data: chartData?.emailPieChartData,
                  title: 'Cumulative Email Logs'
                })}>
                  <ZoomInIcon sx={{ fontSize: '22px' }} color='primary' />
                </IconButton>
              </div>
              <div style={{ height: 'calc(100% - 32px)' }}>
                <ApexCharts style={{ minHeight: "450px !important" }} type='pie'
                  height='100%'
                  width='100%'
                  // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                  chartData={chartData?.emailPieChartData} />
              </div>

            </div>
            <div className="col big">
              <div className="col-head">
                <h3 className="col-heading">Email Radial Bar</h3>
                <IconButton onClick={() => setZoomInChart({
                  type: 'radialBar',
                  show: true,
                  data: chartData.nonCumulativeEmailPieChart,
                  title: 'Email Radial Bar'
                })}>
                  <ZoomInIcon sx={{ fontSize: '22px' }} color='primary' />
                </IconButton>
              </div>
              <div style={{ height: 'calc(100% - 32px)' }}>
                <ApexCharts style={{ minHeight: "450px !important" }} type='radialBar'
                  height='100%'
                  width='100%'
                  // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                  chartData={chartData.nonCumulativeEmailPieChart} />
              </div>

            </div>

          </div>
          <div className="row">
            <div className="col col-half">
              <div className="col-head">
                <h3 className="col-heading">Clients</h3>
                <IconButton onClick={() => setZoomInChart({
                  type: 'line',
                  show: true,
                  data: chartData?.clientLineChartData,
                  title: 'Clients'
                })}>
                  <ZoomInIcon sx={{ fontSize: '22px' }} color='primary' />
                </IconButton>
              </div>
              <div style={{ height: 'calc(100% - 32px)' }}>
                <ApexCharts style={{ minHeight: "450px !important" }} type='line'
                  height='100%'
                  width='100%'
                  // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                  chartData={chartData?.clientLineChartData} />
              </div>

            </div>
            {/* <div className="col col-half">
              <h3 className="col-heading">Followup Emails</h3>
              <UserTable rows={followUpEmailData} columns={columns} columnName="Email Log" />
            </div> */}
          </div>
          <div className="followUp">
            <Box sx={{ width: '100%' }}>
              <h3 className="col-heading">Followup Emails</h3>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="#007AFE"
                indicatorColor="#007AFE"
                aria-label="Follow Up Tabs"
              >
                <Tab value="FirstFollowUp" label="First Follow Up" />
                <Tab value="SecondFollowUp" label="second Follow Up" />
                <Tab value="ThirdFollowUp" label="Third Follow Up" />
                <Tab value="FourthFollowUp" label="Fourth Follow Up" />
                <Tab value="FifthFollowUp" label="Fifth Follow Up" />
              </Tabs>
            </Box>
            <div className="row">
              {
                !followUpLoader &&
                <>
                <div className="col big">
                  <div className="col-head">
                    <h3 className="col-heading">Follow Up Email Summary</h3>
                    <IconButton onClick={() => setZoomInChart({
                      type: 'bar',
                      show: true,
                      data: followUpChartData?.followUpBarChartData,
                      title: 'Email Logs'
                    })}>
                      <ZoomInIcon sx={{ fontSize: '22px' }} color='primary' />
                    </IconButton>
                  </div>
                  <div style={{ height: 'calc(100% - 32px)' }}>
                    <ApexCharts style={{ minHeight: "450px !important" }} type='bar'
                      height='100%'
                      width='100%'
                      // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                      chartData={followUpChartData?.followUpBarChartData} />
                  </div>
                </div>
                <div className="col">
                  <div className="col-head">
                    <h3 className="col-heading">Cumulative Follow Up Email Summary</h3>
                    <IconButton onClick={() => setZoomInChart({
                      type: 'pie',
                      show: true,
                      data: followUpChartData?.followUpPieChartData,
                      title: 'Cumulative Email Logs'
                    })}>
                      <ZoomInIcon sx={{ fontSize: '22px' }} color='primary' />
                    </IconButton>
                  </div>
                  <div style={{ height: 'calc(100% - 32px)' }}>
                    <ApexCharts style={{ minHeight: "450px !important" }} type='pie'
                      height='100%'
                      width='100%'
                      // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                      chartData={followUpChartData?.followUpPieChartData} />
                  </div>

                </div>
                <div className="col big">
                  <div className="col-head">
                    <h3 className="col-heading">Follow Up Email Radial Bar</h3>
                    <IconButton onClick={() => setZoomInChart({
                      type: 'radialBar',
                      show: true,
                      data: followUpChartData?.nonCumulativeFollowUpPieChart,
                      title: 'Email Radial Bar'
                    })}>
                      <ZoomInIcon sx={{ fontSize: '22px' }} color='primary' />
                    </IconButton>
                  </div>
                  <div style={{ height: 'calc(100% - 32px)' }}>
                    <ApexCharts style={{ minHeight: "450px !important" }} type='radialBar'
                      height='100%'
                      width='100%'
                      // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                      chartData={followUpChartData?.nonCumulativeFollowUpPieChart} />
                  </div>

                </div>
                </>
              }
            </div>
          </div>
        </div>

      )}
      <PopUp
        onClose={() => setZoomInChart({
          show: false,
          type: '',
          data: '',
          title: ''
        })}
        open={zoomInChart?.show}
        title={zoomInChart?.title}
        className="popup-wrapper chart"
      >
        <ApexCharts style={{ minHeight: "450px !important" }} type={zoomInChart?.type}
          height='100%'
          width='100%'
          // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
          chartData={zoomInChart?.data} />
      </PopUp>
      <PopUp
        onClose={() => setOpenFollowUpEmailPopup({
          show: false,
          id: ''
        })}
        open={openFollowUpEmailPopup.show}
        title='Follow Up Email'
        className="popup-wrapper follow-up"
      >
        <SendEmail followUp clientUserId={openFollowUpEmailPopup.id} closePopup={setOpenFollowUpEmailPopup} />
      </PopUp>
    </>
  );
};

export default Dashboard;
