import { useEffect } from "react";

const CountryModifier = ({ useFormikContext, stateData, cityData, handleRegionChange }) => {
  // Grab values and submitForm from context
  const { values, submitForm, setFieldValue } = useFormikContext();
  useEffect(() => {
    stateData?.length > 0 && !stateData.find(data => data === values.address.state) && setFieldValue("address.state", stateData[0]);
    stateData && !stateData.find(data => data === values.address.state) && handleRegionChange(stateData[0])
  }, [stateData]);
  useEffect(() => {
    cityData?.length > 0 && !cityData.find(data => data === values?.address?.city) && setFieldValue("address.city", cityData[0]);
  }, [cityData]);
  return null;
};

export default CountryModifier