import { City, Country, State } from "country-state-city";

export const getFullObjects = (value) => {
    if (value.type === "country") {
        const countryIso = Country.getAllCountries().find(country => country?.name === value?.country)?.isoCode
        const countryData = countryIso ? Country.getCountryByCode(countryIso) : Country.getAllCountries().find(country => country?.name === value?.country[0]?.Name)
        // const countryData = countryIso ? Country.getCountryByCode(countryIso) : Country.getAllCountries().find(country => country?.name === value?.country)
        const states = countryIso && State.getStatesOfCountry(countryIso)
        if (states) {
            value?.setStateData && value?.setStateData(states);
        }
        return countryData
    } else if (value.type === "state") {
        // const countryIso = value?.country
        const countryIso = Country.getAllCountries().find(country => country?.name === value?.country)?.isoCode
        const state = countryIso ? State.getStatesOfCountry(countryIso).find(state => state?.isoCode === value?.state) : State.getAllStates().find(state => state?.isoCode === value?.state)
        const cities = countryIso && City.getCitiesOfState(countryIso, state?.isoCode)
        if (cities) {
            value?.setCityData(cities);
        }
        return state
    } else if (value.type === "city") {
        const countryIso = Country.getAllCountries().find(country => country?.name === value?.country)?.isoCode
        const city = countryIso ? City.getCitiesOfCountry(countryIso).find(city => city?.name === value.city) : City.getAllCities().find(city => city?.name === value.city)
        return city
    }
}

export const formattedDate = (date) => new Date(date)?.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
});