import { FETCH_CLIENTS_SUCCESS, FETCH_VIEW_CLIENTS_SUCCESS, DELETE_CLIENT_SUCCESS, CREATE_CLIENT_SUCCESS, UPDATE_CLIENT_SUCCESS, GET_CLIENT_DATA_SUCCESS } from './actionTypes';

import { getAllclients, editclient, getDataByClientId, getViewClientData } from '../../api/clientApi';


export const fetchClientsSuccess = (Clients) => ({
  type: FETCH_CLIENTS_SUCCESS,
  payload: Clients,
});

export const fetchViewClientsSuccess = (Clients) => ({
  type: FETCH_VIEW_CLIENTS_SUCCESS,
  payload: Clients,
});

export const createClientsSuccess = (data) => ({
  type: CREATE_CLIENT_SUCCESS,
  payload: data,
});
export const updateClientsSuccess = (data) => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: data,
});

export const getClientDataSuccess = (user) => ({
  type: GET_CLIENT_DATA_SUCCESS,
  payload: user,
});

export const fetchClients = () => {
  return async (dispatch) => {
    const Clients = await getAllclients();
    dispatch(fetchClientsSuccess(Clients));
  };
};

export const getViewClientDetails = (id) => {
  return async (dispatch) => {
    const Clients = await getViewClientData(id);
    dispatch(fetchViewClientsSuccess(Clients));
  };
};


export const updateClient = (id, data) => {
  return async () => {
    try {
      const updatedCLIENT = await editclient(id, data);
      return updatedCLIENT;
    } catch (error) {
      console.error("Error updating CLIENT:", error);
      return null;
    }
  };
};

export const createClients = (CLIENT) => {
  return (dispatch) => {
    dispatch(createClientsSuccess(CLIENT));
  };
};
export const deleteClient = (id) => {
  return (dispatch) => {
    dispatch(deleteClientSuccess())
  }
}

export const deleteClientSuccess = (ClientId) => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: ClientId,
});

export const getClientById = (clientId) => {
  return async (dispatch) => {
    try {
      const clientData = await getDataByClientId(clientId);
      dispatch(getClientDataSuccess(clientData.data));
      return clientData.data;
    } catch (error) {
      console.error("Error in getClientById:", error);
      return Promise.reject(error);
    }
  };
};
