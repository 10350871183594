import { getAllDashBoardData } from '../../api/dashboardApi';

export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const FETCH_DASHBOARD_DATA_LOADING = 'FETCH_DASHBOARD_DATA_LOADING';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLEAR_DATA = 'CLEAR_DATA';



export const fetchClientsSuccess = (dashboardData) => ({
    type: FETCH_DASHBOARD_DATA_SUCCESS,
    payload: dashboardData,
});
export const isLoading = () => ({
    type: FETCH_DASHBOARD_DATA_LOADING,
    payload: true,
});
export const fetchData = (data) => {
    return async (dispatch) => {
        dispatch(isLoading())
        const dashboardData = await getAllDashBoardData(data);
        dispatch(fetchClientsSuccess(dashboardData));
    };
};

export const toggleSidebar = () => ({
    type: TOGGLE_SIDEBAR
})

export const clearDashboardChartData = () => ({
    type: CLEAR_DATA,
})