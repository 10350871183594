import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormHelperText } from '@mui/material';

export default function DateNTimePicker({ label, value, onChange, helperText, labelError, className, disabled, disablePast }) {
    const isError = labelError && labelError !== "";

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                    label={label}
                    value={value}
                    onChange={onChange}
                    className={`${isError ? 'error-input' : ''} ${className || ''}`}
                    disabled={disabled}
                    disablePast={disablePast}
                    format='DD/MM/YYYY hh:mm a'
                />
                <FormHelperText
                    sx={{ color: isError ? "red" : "inherit", marginLeft: "0px", marginTop: '10px' }}
                >
                    {isError ? labelError : helperText}
                </FormHelperText>
            </DemoContainer>
        </LocalizationProvider>
    );
}